import { ThemeProvider as MuiThemeProvider } from "@material-ui/core";
import theme from "src/v3/theme/theme";
import { DrawerContent, DrawerRoot, useStyles } from "./styles";
import { ItemDetailsDrawerHeader } from "./DrawerHeader";
import { ItemDetailerDrawerFooter } from "./DrawerFooter";
import { useItemDetails } from "./useItemDetails";
import { SummaryOfWorkAccordion } from "./widgets/SummaryOfWork";
import { TopChallenges } from "./widgets/TopChallenges";
import {
  findEverythingElse,
  getAggregatedItemsByStatus,
  getInProgressItems,
  getRecentlyCompletedItems,
} from "./utils";
import { ThemeProvider } from "styled-components";
import { ItemsAccordion } from "./widgets/ItemsAccordion";
import { HistoricalAllocationChart } from "src/v3/components/Charts/HistoricalAllocation";

export const ItemDetails = () => {
  const { item, paths, handleClose, isLoading } = useItemDetails();

  if (isLoading) {
    return <p>Loading item details...</p>;
  }

  const inProgressChildren = getInProgressItems({ items: item.getChildren() });
  const recentlyCompleteChildren = getRecentlyCompletedItems({
    items: item.getChildren(),
  });

  const everythingElseChildren = findEverythingElse({
    items: item.getChildren(),
    excludedItems: [...inProgressChildren, ...recentlyCompleteChildren],
  });

  const statusAggregation = getAggregatedItemsByStatus({
    items: item.getChildren(),
  });

  const styles = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <DrawerRoot
          open={true}
          onClose={handleClose}
          anchor="right"
          PaperProps={{
            style: {
              ...styles.drawerPaper,
            },
          }}
        >
          <ItemDetailsDrawerHeader
            item={item}
            handleClose={handleClose}
            breadCrumbPaths={paths}
            childrenStatusAggregation={statusAggregation}
          />
          <DrawerContent>
            <TopChallenges item={item} title={"Top Challenges"} />
            <HistoricalAllocationChart item={item} />
            <SummaryOfWorkAccordion item={item} />
            <ItemsAccordion items={inProgressChildren} title={"In progress"} />
            <ItemsAccordion
              items={recentlyCompleteChildren}
              title={"Recently completed"}
            />
            <ItemsAccordion
              items={everythingElseChildren}
              title={"Everything else"}
            />
          </DrawerContent>
          <ItemDetailerDrawerFooter handleClose={handleClose} />
        </DrawerRoot>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};
