const typography = {
  fontFamily: "Inter, sans-serif",
  fontSize: 14,
  dialogTitle: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
  },
  assignee: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  navbar: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
  h1: {
    fontFamily: "Inter-Bold",
    fontSize: "22px",
    lineHeight: "26.63px",
    fontWeight: 700,
  },
  h2: {
    fontFamily: "Inter-Bold",
    fontSize: "16px",
    lineHeight: "19.3px",
    fontWeight: 700,
    height: "normal",
  },
  body1: {
    fontFamily: "Inter",
    fontSize: "14px",
    lineHeight: "16.9px",
    fontWeight: 400,
  },
  body2: {
    fontFamily: "Inter",
    fontSize: "12px",
    lineHeight: "14.5px",
    fontWeight: 400,
  },
  button: {
    fontFamily: "Inter-Bold",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "16.9px",
  },
  weight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  lineHeight: {
    sm: "14.5px", // for 12px font size
    md: "16.9px", // for 14px font size
    lg: "19.3px", // for 16px font size
    xl: "26.63px", // for 22px font size
  },
};

export default typography;
