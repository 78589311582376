import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { DateSideInfo } from "./Date";

export const ProjectedDateSideInfo = ({ item }: { item: BlockItem }) => {
  return (
    <DateSideInfo
      key={item.getProjectedDateField()?.getFieldKey()}
      label="Projected Date"
      value={item.getProjectedDateField()?.getProjectedDate()}
    />
  );
};
