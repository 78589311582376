import { useState, useRef, useEffect } from "react";
import { FilterPopper } from "../FilterPopper";
import IconComponent from "../../Icons/IconComponent";
import { FilterList } from "src/v3/theme/icons/general";
import { FilterMenu } from "../FilterMenu";
import theme from "src/v3/theme/theme";
import { FilterButton } from "../FilterButton";
import { FilterButtonContainer } from "./styles";

export default function FilterMenuComposition() {
  const [open, setOpen] = useState(false);
  const [advancedFilterAnchorEl, setAdvancedFilterAnchorEl] = useState(null);

  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <FilterButtonContainer>
      <FilterButton propRef={anchorRef} onClick={handleToggle}>
        <IconComponent
          Icon={FilterList}
          fill={theme.palette.grey[400]}
          stroke={theme.palette.grey[400]}
        />
      </FilterButton>
      <FilterPopper
        open={open}
        setOpen={setOpen}
        setAnchorEl={setAdvancedFilterAnchorEl}
        anchorRef={anchorRef}
      />
      <FilterMenu
        anchorEl={advancedFilterAnchorEl}
        setAnchorEl={setAdvancedFilterAnchorEl}
      />
    </FilterButtonContainer>
  );
}
