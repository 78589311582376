import theme from "src/v3/theme/theme";
import IconComponent from "./IconComponent";
import { ErrorFilled as ErrorFilledIcon } from "src/v3/theme/icons/general";

export const ErrorFilled: React.FC = () => (
  <IconComponent
    Icon={ErrorFilledIcon}
    fill={theme.palette.error.main}
    stroke={theme.palette.error.main}
  />
);
