import theme from "src/v3/theme/theme";
import IconComponent from "./IconComponent";
import { ErrorFilled } from "src/v3/theme/icons/general";

export const WarningFilled: React.FC = () => (
  <IconComponent
    Icon={ErrorFilled}
    fill={theme.palette.primary.main}
    stroke={theme.palette.primary.main}
  />
);
