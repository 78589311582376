import React from "react";
import {
  CustomAccordionDetails,
  CustomAccordionRoot,
  CustomAccordionSummary,
  Details,
  ExpandIcon,
  Header,
  NoExpandIcon,
} from "./styles";
import { AccordionProps } from "./types";

import theme from "src/v3/theme/theme";
import { ChevronDown, ChevronUp } from "src/v3/theme/icons/general";
import IconComponent from "../Icons/IconComponent";

export const CustomAccordion = React.memo(
  ({
    header,
    children,
    customStyle,
    isExpanded,
    onToggle,
    hasChildren,
  }: AccordionProps) => {
    const handleToggle = React.useCallback(() => {
      if (hasChildren) {
        onToggle(!isExpanded);
      }
    }, [hasChildren, isExpanded, onToggle]);

    return (
      <CustomAccordionRoot
        style={{
          boxShadow: "none",
          backgroundColor: theme.palette.background.default,
          borderRadius: theme.spacing(2),
          ...customStyle,
        }}
      >
        <CustomAccordionSummary
          onClick={handleToggle}
          style={{
            cursor: hasChildren ? "pointer" : "default",
          }}
        >
          <Header>{header}</Header>
          {hasChildren ? (
            <ExpandIcon>
              <IconComponent Icon={isExpanded ? ChevronUp : ChevronDown} />
            </ExpandIcon>
          ) : (
            <NoExpandIcon />
          )}
        </CustomAccordionSummary>
        {hasChildren && isExpanded && (
          <CustomAccordionDetails>
            <Details>{children}</Details>
          </CustomAccordionDetails>
        )}
      </CustomAccordionRoot>
    );
  }
);
