import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { styled, Theme } from "@material-ui/core/styles";
import { CloseButton } from "src/v3/theme/icons/general";

export const DialogRoot = styled(Dialog)(({ theme }: { theme: Theme }) => ({
  "& .MuiPaper-root": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[600]}`,
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },
}));

export const DialogHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
});

export const CustomDialogTitle = styled(DialogTitle)(
  ({ theme }: { theme: Theme }) => ({
    color: theme.palette.grey[900],
  })
);

export const CloseIcon = styled(CloseButton)(({ theme }: { theme: Theme }) => ({
  alignSelf: "center",
  cursor: "pointer",
  width: "24px",
  height: "24px",
  margin: theme.spacing(0, 4),
}));

export const CustomDialogContent = styled(DialogContent)(
  ({ theme }: { theme: Theme }) => ({
    width: "540px",
    minHeight: "312px",
    borderTop: `1px solid ${theme.palette.grey[600]}`,
    padding: theme.spacing(4),
  })
);

export const DialogFooter = styled("div")(({ theme }: { theme: Theme }) => ({
  display: "flex",
  padding: theme.spacing(4),
  justifyContent: "flex-end",
  alignItems: "flex-end",
  gap: theme.spacing(3),
  alignSelf: "stretch",
}));

export const DialogCloseButton = styled("div")(
  ({ theme }: { theme: Theme }) => ({
    display: "flex",
    height: theme.spacing(2),
    padding: theme.spacing(2.5, 4),
    justifyContent: "center",
    cursor: "pointer",
    alignItems: "center",
    gap: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[900]}`,
    ...theme.typography.button,
    color: theme.palette.grey[900],
  })
);

export const DialogSaveButton = styled("div")(
  ({
    theme,
    enableSaveButton,
  }: {
    theme: Theme;
    enableSaveButton: boolean;
  }) => ({
    display: "flex",
    height: theme.spacing(2),
    padding: theme.spacing(2.5, 4),
    justifyContent: "center",
    alignItems: "center",
    cursor: enableSaveButton ? "pointer" : "default",
    gap: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: enableSaveButton
      ? theme.palette.primary.light
      : theme.palette.grey[200],
    color: theme.palette.primary.contrastText,
    ...theme.typography.button,
    "&:hover": {
      backgroundColor: enableSaveButton
        ? theme.palette.primary.dark
        : theme.palette.grey[200],
    },
  })
);
