import { IconButton } from "@material-ui/core";
import { MeatBallsVertical } from "src/v3/theme/icons/general";
import { ActionMenuProps } from "./types";
import IconComponent from "../Icons/IconComponent";
import { CustomMenu } from "../commomStyles/Menu";

function ActionMenu({ actions, icon }: ActionMenuProps): JSX.Element {
  return (
    <CustomMenu
      actions={actions}
      TriggerComponent={
        <IconButton size="small">
          {icon || <IconComponent Icon={MeatBallsVertical} />}
        </IconButton>
      }
    />
  );
}

export default ActionMenu;
