import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { DateSideInfo } from "./Date";

export const TargetDateSideInfo = ({ item }: { item: BlockItem }) => {
  return (
    <DateSideInfo
      key={item.getTargetDateField()?.getFieldKey()}
      label="Team's target"
      value={item.getTargetDateField()?.getTargetDate()}
    />
  );
};
