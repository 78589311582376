export const buildViewURL = (viewName) => {
  const route = `/v2/roadmap/${viewName}`;
  const admin = getAdminParamFromURL();
  return admin ? `${route}?${admin}` : route;
};

export function getAdminParamFromURL() {
  const adminParam = getAllKeyValueFiltersFromURL().filter(
    ({ fieldKey }) => fieldKey === "admin"
  );
  if (adminParam.length === 0) return "";
  return `${adminParam[0].fieldKey}=${adminParam[0].values[0]}`;
}

export function getAllKeyValueFiltersFromURLWithoutAdmin(url?: string) {
  return getAllKeyValueFiltersFromURL(url).filter(
    ({ fieldKey }) => fieldKey !== "admin"
  );
}

export function getFilterLevelFromURL() {
  return getAllKeyValueFiltersFromURL().filter(
    ({ fieldKey }) => fieldKey === "filter_level"
  );
}

export function getAllKeyValueFiltersFromURL(url?: string) {
  const urlObj = new URL(url, window.location.origin);
  const params = new URLSearchParams(urlObj.search);
  const urlSearchKeys = Array.from(params.keys());
  return urlSearchKeys.map((key) => {
    const param = params.get(key);
    const urlParamValues = param.split("~");
    return {
      fieldKey: key,
      values: [...urlParamValues],
    };
  });
}
