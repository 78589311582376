import { CustomMenuItem, ActionLabel } from "../commomStyles/Menu/styles";
import { useUserMenu } from "./useUserMenu";

export const LogoutAction = () => {
  const { doLogout } = useUserMenu();

  return (
    <CustomMenuItem onClick={async () => await doLogout()}>
      <ActionLabel
        style={{
          textAlign: "center",
        }}
      >
        Logout
      </ActionLabel>
    </CustomMenuItem>
  );
};
