import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import IconComponent from "src/v3/components/Icons/IconComponent";
import { ChevronDown, ChevronUp } from "src/v3/theme/icons/general";
import theme from "src/v3/theme/theme";

const useStyles = makeStyles(() => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
    color: theme.palette.grey[900],
    gap: "8px",
  },
  header: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
    padding: "8px",
    cursor: "pointer",
  },
}));

export const FilterExpandableField = ({ field, shouldExpandAll, children }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState<boolean>(shouldExpandAll);

  useEffect(() => {
    setIsExpanded(shouldExpandAll);
  }, [shouldExpandAll]);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const ExpandIcon = isExpanded ? ChevronUp : ChevronDown;

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
        onClick={handleExpand}
        className={classes.header}
      >
        <Typography>{field.displayName}</Typography>
        <IconComponent Icon={ExpandIcon} size={24} />
      </Grid>
      {isExpanded && children}
    </Grid>
  );
};
