import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const AccordionGrid = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(5)};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(4)};
  align-self: stretch;
  width: 100%;
  color: ${({ theme }) => theme.palette.grey[900]};
  word-break: break-word;
  overflow-wrap: break-word;
  font-family: ${({ theme }) => theme.typography.body1.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body1.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body1.lineHeight} !important;
  font-weight: ${({ theme }) => theme.typography.body1.fontWeight} !important;
`;

export const ContextTitle = styled.div`
  color: ${({ theme }) => theme.palette.grey[800]};
  font-family: ${({ theme }) => theme.typography.h2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.h2.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.h2.lineHeight} !important;
  font-weight: ${({ theme }) => theme.typography.h2.fontWeight} !important;
  font-style: normal;
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
  align-self: stretch;
  color: ${({ theme }) => theme.palette.grey[900]};
  font-family: ${({ theme }) => theme.typography.body2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body2.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body2.lineHeight} !important;
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight} !important;
`;

export const InfoItem = styled.div`
  display: flex;
  align-content: center;
  gap: ${({ theme }) => theme.spacing(1)};
  align-self: stretch;
  align-items: center;
`;

export const BoldTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[900]} !important;
  font-family: ${({ theme }) => theme.typography.body2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body2.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body2.lineHeight} !important;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold} !important;
`;
