import styled from "styled-components";

export const TooltipContentRoot = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const TooltipContentRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const ContentChip = styled.div<{ color: string }>`
  display: inline-flex;
  height: ${({ theme }) => theme.spacing(5)};
  width: ${({ theme }) => theme.spacing(9)};
  padding: ${({ theme }) => theme.spacing(0, 2)};
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ color, theme }) => color ?? theme.palette.grey[200]};
  color: ${({ theme }) => theme.palette.info.main};

  font-family: ${({ theme }) => theme.typography.body2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body2.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body2.lineHeight} !important;
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight} !important;
  height: ${({ theme }) =>
    theme.typography.body2.height || "normal"} !important;
`;

export const Chip = styled.div`
  display: flex;
  width: 70px;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.grey[200]};
  color: ${({ theme }) => theme.palette.info.main};

  font-family: ${({ theme }) => theme.typography.body2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body2.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body2.lineHeight} !important;
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight} !important;
  height: ${({ theme }) =>
    theme.typography.body2.height || "normal"} !important;
`;

export const NonRoadmapLabel = styled.div<{ backgroundColor: string }>`
  display: flex;
  padding: ${({ theme }) => theme.spacing(1)};
  text-align: center;
  justify-content: center;
  width: 50%;
  border-top-right-radius: ${({ theme }) => theme.spacing(1)};
  border-bottom-right-radius: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.info.main};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const OnRoadmapLabel = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(1)};
  text-align: center;
  justify-content: center;
  width: 50%;
  border-top-left-radius: ${({ theme }) => theme.spacing(1)};
  border-bottom-left-radius: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.info.main};
`;

export const NonRoadmapHeader = styled.div`
  color: ${({ theme }) => theme.palette.grey[900]};
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: ${({ theme }) => theme.spacing(1)};
  width: 100%;

  font-family: ${({ theme }) => theme.typography.body1.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body1.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body1.lineHeight} !important;
  font-weight: 600 !important;
`;

export const ChipDivider = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
`;

export const HideShowNonRoadmapItems = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.3)};
  flex: 1 0 0;

  font-family: ${({ theme }) => theme.typography.body1.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body1.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body1.lineHeight} !important;
  font-weight: 600 !important;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const InfoNonRoadmapItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  align-content: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const NonRoadmapMessage = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body1.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body1.lineHeight} !important;
  font-weight: 600 !important;
  color: ${({ theme }) => theme.palette.grey[900]};
`;
