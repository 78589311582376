const addClassToHtml = (htmlString) => {
  // Create a temporary div to parse the HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString.trim();

  // Select all ul and li elements and add the arrowed class
  tempDiv.querySelectorAll("ul, li").forEach((element) => {
    element.classList.add("arrowed");
  });

  tempDiv.querySelectorAll("blockquote").forEach((element) => {
    element.classList.add("blockquote");
  });

  tempDiv.querySelectorAll("strong").forEach((element) => {
    element.style.fontWeight = "bold";
  });

  tempDiv.querySelectorAll("ol").forEach((element) => {
    element.classList.add("ordered-list");
  });

  // Handling text alignments (left, center, right, justify)
  tempDiv.querySelectorAll('[style*="text-align"]').forEach((element) => {
    const alignment = element.style.textAlign;
    element.classList.add(`text-align-${alignment}`);
  });

  // Handling custom fonts
  tempDiv.querySelectorAll("span[style*='font-family']").forEach((element) => {
    const fontFamily = element.style.fontFamily.replace(/['"]/g, ""); // Remove quotes
    element.classList.add(`font-${fontFamily.toLowerCase()}`);
  });

  // Handling headers (h1, h2, h3, etc.)
  tempDiv.querySelectorAll("h1, h2, h3, h4, h5, h6").forEach((element) => {
    element.classList.add(`header-${element.tagName.toLowerCase()}`);
  });

  // Handling superscript and subscript
  tempDiv.querySelectorAll("sup").forEach((element) => {
    element.classList.add("superscript");
  });

  tempDiv.querySelectorAll("sub").forEach((element) => {
    element.classList.add("subscript");
  });

  // Handling color and background color
  tempDiv.querySelectorAll("span[style*='color']").forEach((element) => {
    const color = element.style.color;
    element.classList.add(`text-color-${color.replace(/[^a-zA-Z0-9]/g, "")}`);
  });

  tempDiv
    .querySelectorAll("span[style*='background-color']")
    .forEach((element) => {
      const backgroundColor = element.style.backgroundColor;
      element.classList.add(
        `background-color-${backgroundColor.replace(/[^a-zA-Z0-9]/g, "")}`
      );
    });

  // Serialize the modified HTML back to a string
  return tempDiv.innerHTML;
};

// Process the extra context
export const processExtraContext = (editedExtraContext) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = editedExtraContext.trim();

  const cleanedContext =
    tempDiv.textContent.trim() === "" || tempDiv.innerHTML.trim() === "<br>"
      ? ""
      : addClassToHtml(editedExtraContext);

  return cleanedContext;
};
