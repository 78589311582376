import { TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useEffect, useState } from "react";
import theme from "src/v3/theme/theme";

export const SearchFieldOptions = ({ field, setStore }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const filterData = (query) => {
    const filterOptions = [...field.values];
    if (!query) {
      setStore((prev) => ({
        ...prev,
        [field.fieldKey]: {
          ...prev[field.fieldKey],
          values: filterOptions,
        },
      }));
      return;
    }
    const lowerCaseQuery = query.toLowerCase();
    const filteredOptions = filterOptions.filter((option: String) =>
      option.toLowerCase().includes(lowerCaseQuery)
    );
    setStore((prev) => ({
      ...prev,
      [field.fieldKey]: {
        ...prev[field.fieldKey],
        values: filteredOptions,
      },
    }));
  };

  useEffect(() => {
    filterData(searchQuery);
  }, [searchQuery]);

  return (
    <TextField
      size="small"
      id="outlined-basic"
      variant="outlined"
      fullWidth
      InputProps={{
        startAdornment: <Search style={{ color: theme.palette.grey[200] }} />,
      }}
      style={{ padding: "0 16px" }}
      onInput={(e) => {
        setSearchQuery(e.target.value);
      }}
    />
  );
};
