import { IssueType } from "src/v3/components/IssueTypeIcon/types";

export const defaultStrokeColor = "#08090C";

export const sizeMap = {
  small: 16,
  medium: 20,
  large: 32,
  default: 24,
};

export const backgroundColorMap = {
  [IssueType.Team]: "#167356",
  [IssueType.Idea]: "#FF9C23",
  [IssueType.Product]: "#9C27B0",
  [IssueType.Initiative]: "#9C27B0",
  [IssueType.Project]: "#3F51B5",
  [IssueType.Epic]: "#3F51B5",
  [IssueType.Task]: "#197BBD",
  [IssueType.SubTask]: "#79554B",
  [IssueType.Default]: "#197BBD",
};
