import { useLoadBlockContext } from "src/v2/providers/block/LoadBlockContext";
import { ViewFilterContainer } from "src/v3/dataContainers/ViewFilterContainer";
import { WidgetV3 } from "src/v3/components/Widget";
import {
  UnderMaintenanceBanner,
  UnderMaintenanceView,
} from "../UnderMaintenance";
import { useGenericView } from "./useGenericView";
import { useLayout } from "src/providers/LayoutContext";
import {
  FilteredByTextContainer,
  GenericViewContainer,
  GenericViewContent,
} from "./styles";

import { useLocation } from "react-router-dom";
import FilteredBy from "../Filter/FilteredBy";
import { Divider } from "@material-ui/core";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";

export const GenericViewV3 = () => {
  const { filteredItems } = useLoadBlockContext();
  const { selectedFilters } = useFilterContext();
  const { orderedWidgets, isUnderMaintenance, filters } = useGenericView();

  if (isUnderMaintenance) return <UnderMaintenanceView />;
  const { topBarHeight } = useLayout();

  const location = useLocation();
  const hash = location.hash;

  return (
    <GenericViewContainer topBarHeight={topBarHeight}>
      <UnderMaintenanceBanner />
      <GenericViewContent>
        {selectedFilters?.length > 0 && (
          <FilteredByTextContainer>
            <FilteredBy />
            <Divider />
          </FilteredByTextContainer>
        )}
        <GenericViewContent
          style={{
            marginTop: selectedFilters?.length > 0 ? "0px" : "16px",
          }}
        >
          <ViewFilterContainer filters={filters} items={filteredItems}>
            {({ items }) => {
              if (hash) {
                const widget = orderedWidgets.find(
                  (widget) => widget.name === hash.replace("#", "")
                );

                if (widget) {
                  return <WidgetV3 items={items} widget={widget} />;
                }
              }
              return orderedWidgets.map((widget, index) => (
                <WidgetV3 key={index} items={items} widget={widget} />
              ));
            }}
          </ViewFilterContainer>
        </GenericViewContent>
      </GenericViewContent>
    </GenericViewContainer>
  );
};
