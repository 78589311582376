/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useContext, useState, useEffect } from "react";
import { getRawPriorities } from "src/services/roadmapService";
import {
  getConfigByKeys,
  getCustomFieldsConfigs,
  upsertConfigByKey,
} from "src/services/clientService";
import { isLogged } from "src/services/sessionService";

export enum PullIssuesConfig {
  pullFromEpics = "pullFromEpics",
  pullFromMilestones = "pullFromMilestones",
}
export enum InitiativeProgressPercentageCalculationConfig {
  calculateByEpicStatus = "calculateByEpicStatus",
  calculateByStoryPoints = "calculateByStoryPoints",
}

const ClientConfigContext = React.createContext({
  epicRoadmapGeneralStatuses: {
    field: "",
    statuses: [],
  },
  rawPriorities: [],
  extraRoadmapSummaryColumns: [],
  roadmapUseDoneStatusAsCompleted: false,
  roadmapShowEpicStatusColumn: true,
  showProjectStatus: false,
  clientConfigs: [],
  defaultSortConfig: {},
  pullEpicIssuesConfig: {},
  defaultClientFilterConfig: "",
  showProjectedFinishDate: true,
  calculateInitiativeProgressPercentageBy:
    InitiativeProgressPercentageCalculationConfig.calculateByStoryPoints,
  updateClientConfig: ({ key, newValue }) => {},
  customFieldsConfig: {},
  defaultUrlConfig: null,
  changeFilterByLevelLabel: { from: "", to: "" },
  daysToShowRecentlyChildrenAddedFlag: 0,
  defaultViewSchema: [],
  jiraURL: "",
  underMaintenanceBanner: "",
});

function useClientConfigContext() {
  const context = useContext(ClientConfigContext);
  return context;
}

interface EpicsRoadmapGeneralStatus {
  field: string;
  statuses: string[];
}

export enum ConfigKeys {
  extraRoadmapSummaryColumns = "extra_roadmap_summary_columns",
  epicRoadmapGeneralStatus = "epic_roadmap_general_status",
  roadmapUseDoneStatusAsCompleted = "roadmap_use_done_status_as_completed",
  showProjectStatus = "show_project_status",
  defaultRoadmapFilters = "default_roadmap_filters",
  businessViewPullIssuesConfig = "business_view_pull_issues_config",
  roadmapShowEpicStatusColumn = "roadmap_show_epic_status_column",
  showProjectedFinishDate = "show_projected_finish_date",
  calculateInitiativeProgressPercentageBy = "calculate_initiative_progress_percentage_by",
  reportsStakeholderField = "reports_stakeholder_field",
  riskConfig = "roadmap_risk_config",
  calculateAbsoluteProgressByInitiativesOnly = "calculate_absolute_progress_by_initiatives_only",
  useOneBoardApproachForRoadmap = "use_one_board_approach_for_roadmap",
  replaceRoadmapColumnWithCustomFields = "replace_roadmap_column_with_custom_fields",
  roadmapProjectStatusConfigs = "roadmap_project_status_config",
  defaultSortConfig = "default_sort_config",
  defaultUrlConfig = "default_url_config",
  blockDataType = "block_data_type_config",
  velmaInfoUrl = "velma_info_url",
  changeFilterByLevelLabel = "change_filter_by_level_label",
  daysToShowRecentlyChildrenAddedFlag = "days_to_show_recently_children_added_flag",
  epicInfos = "epic_infos",
  defaultViewSchema = "default_view_schema",
  sites = "sites",
  underMaintenanceBanner = "under_maintenance_banner",
}

export function ClientConfigContextProvider({ children }: any) {
  const [clientConfigs, setClientConfigs] = useState();
  const [epicRoadmapGeneralStatuses, setEpicRoadmapGeneralStatuses] =
    useState<EpicsRoadmapGeneralStatus>({ field: "", statuses: [] });
  const [rawPriorities, setRawPriorities] = useState();
  const [defaultClientFilterConfig, setDefaultClientFilterConfig] = useState();
  const [extraRoadmapSummaryColumns, setExtraRoadmapSummaryColumns] = useState<
    any[]
  >([]);
  const [customFieldsConfig, setCustomFieldsConfig] = useState<{
    all_custom_fields_configs: string[];
    fields_not_to_show_on_filter: string[];
    fields_to_show_on_filter_only: string[];
  }>();
  const [roadmapUseDoneStatusAsCompleted, setRoadmapUseDoneStatusAsCompleted] =
    useState<boolean>(false);
  const [showProjectStatus, setShowProjectStatus] = useState<boolean>(false);
  const [roadmapShowEpicStatusColumn, setRoadmapShowEpicStatusColumn] =
    useState<boolean>(true);
  const [showProjectedFinishDate, setShowProjectedFinishDate] =
    useState<boolean>(false);
  const [pullEpicIssuesConfig, setPullIssuesConfig] =
    useState<PullIssuesConfig>(PullIssuesConfig.pullFromEpics);

  const [
    calculateInitiativeProgressPercentageBy,
    setCalculateInitiativeProgressPercentageBy,
  ] = useState<InitiativeProgressPercentageCalculationConfig>(
    InitiativeProgressPercentageCalculationConfig.calculateByStoryPoints
  );

  const [
    daysToShowRecentlyChildrenAddedFlag,
    setDaysToShowRecentlyChildrenAddedFlag,
  ] = useState<number>(7);

  const [reportsStakeholderField, setReportsStakeholderField] =
    useState<string>();

  const [riskConfig, setRiskConfig] = useState([]);
  const [
    calculateAbsoluteProgressByInitiativesOnly,
    setCalculateAbsoluteProgressByInitiativesOnly,
  ] = useState(false);
  const [useOneBoardApproachForRoadmap, setUseOneBoardApproachForRoadmap] =
    useState(false);
  const [
    replaceRoadmapColumnWithCustomFields,
    setReplaceRoadmapColumnWithCustomFields,
  ] = useState(false);
  const [roadmapProjectStatusConfigs, setRoadmapProjectStatusConfigs] =
    useState([]);
  const [defaultSortConfig, setDefaultSortConfig] = useState({});
  const [defaultUrlConfig, setDefaultUrlConfig] = useState();
  const [blockDataType, setBlockDataType] = useState();
  const [velmaInfoUrl, setVelmaInfoUrl] = useState();
  const [changeFilterByLevelLabel, setChangeFilterByLevelLabel] =
    useState(null);
  const isUserLogged = isLogged();
  const [defaultViewSchema, setDefaultViewSchema] = useState([]);
  const [jiraURL, setJiraURL] = useState();
  const [underMaintenanceBanner, setUnderMaintenanceBanner] = useState("");

  const configKeys = [
    ConfigKeys.extraRoadmapSummaryColumns,
    ConfigKeys.epicRoadmapGeneralStatus,
    ConfigKeys.roadmapUseDoneStatusAsCompleted,
    ConfigKeys.showProjectStatus,
    ConfigKeys.roadmapShowEpicStatusColumn,
    ConfigKeys.defaultRoadmapFilters,
    ConfigKeys.businessViewPullIssuesConfig,
    ConfigKeys.showProjectedFinishDate,
    ConfigKeys.calculateInitiativeProgressPercentageBy,
    ConfigKeys.riskConfig,
    ConfigKeys.reportsStakeholderField,
    ConfigKeys.calculateAbsoluteProgressByInitiativesOnly,
    ConfigKeys.useOneBoardApproachForRoadmap,
    ConfigKeys.replaceRoadmapColumnWithCustomFields,
    ConfigKeys.roadmapProjectStatusConfigs,
    ConfigKeys.defaultSortConfig,
    ConfigKeys.defaultUrlConfig,
    ConfigKeys.blockDataType,
    ConfigKeys.velmaInfoUrl,
    ConfigKeys.changeFilterByLevelLabel,
    ConfigKeys.daysToShowRecentlyChildrenAddedFlag,
    ConfigKeys.defaultViewSchema,
    ConfigKeys.sites,
    ConfigKeys.underMaintenanceBanner,
  ];

  useEffect(() => {
    if (isUserLogged) {
      setupClientConfigs();
    }
  }, []);

  const setupClientConfigs = async () => {
    const priorities = await getRawPriorities();
    setRawPriorities(priorities);

    const clientConfigs = await getConfigByKeys(configKeys);
    setClientConfigs(clientConfigs);

    const customFieldsConfig = await getCustomFieldsConfigs();
    setCustomFieldsConfig(customFieldsConfig);
  };

  const getConfigValueByName = ({ configs, name }) =>
    configs.find((config) => config.name === name)?.value;

  function getBooleanConfigWithDefaultTrue(value: boolean) {
    if ([null, undefined].includes(value)) {
      return true;
    }
    return value;
  }

  const setConfigByKey = {
    [ConfigKeys.extraRoadmapSummaryColumns]: (value) =>
      setExtraRoadmapSummaryColumns(value),
    [ConfigKeys.epicRoadmapGeneralStatus]: (value) =>
      setEpicRoadmapGeneralStatuses(value),
    [ConfigKeys.roadmapUseDoneStatusAsCompleted]: (value) =>
      setRoadmapUseDoneStatusAsCompleted(value),
    [ConfigKeys.showProjectStatus]: (value) => setShowProjectStatus(value),
    [ConfigKeys.roadmapShowEpicStatusColumn]: (value) =>
      setRoadmapShowEpicStatusColumn(getBooleanConfigWithDefaultTrue(value)),
    [ConfigKeys.defaultRoadmapFilters]: (value) =>
      setDefaultClientFilterConfig(value),
    [ConfigKeys.businessViewPullIssuesConfig]: (value) =>
      setPullIssuesConfig(value),
    [ConfigKeys.showProjectedFinishDate]: (value) =>
      setShowProjectedFinishDate(value),
    [ConfigKeys.calculateInitiativeProgressPercentageBy]: (value) =>
      setCalculateInitiativeProgressPercentageBy(
        value ??
          InitiativeProgressPercentageCalculationConfig.calculateByStoryPoints
      ),
    [ConfigKeys.riskConfig]: (value) => setRiskConfig(value),
    [ConfigKeys.reportsStakeholderField]: (value) =>
      setReportsStakeholderField(value),
    [ConfigKeys.calculateAbsoluteProgressByInitiativesOnly]: (value) =>
      setCalculateAbsoluteProgressByInitiativesOnly(value),
    [ConfigKeys.useOneBoardApproachForRoadmap]: (value) =>
      setUseOneBoardApproachForRoadmap(value),
    [ConfigKeys.replaceRoadmapColumnWithCustomFields]: (value) =>
      setReplaceRoadmapColumnWithCustomFields(value),
    [ConfigKeys.roadmapProjectStatusConfigs]: (value) =>
      setRoadmapProjectStatusConfigs(value),
    [ConfigKeys.defaultUrlConfig]: (value) => setDefaultUrlConfig(value),
    [ConfigKeys.blockDataType]: (value) => setBlockDataType(value),
    [ConfigKeys.velmaInfoUrl]: (value) => setVelmaInfoUrl(value),
    [ConfigKeys.changeFilterByLevelLabel]: (value) =>
      setChangeFilterByLevelLabel(value),
    [ConfigKeys.daysToShowRecentlyChildrenAddedFlag]: (value) =>
      setDaysToShowRecentlyChildrenAddedFlag(value ?? 7),
    [ConfigKeys.defaultViewSchema]: (value) => setDefaultViewSchema(value),
    [ConfigKeys.sites]: (value) => setJiraURL(value?.jira),
    [ConfigKeys.defaultSortConfig]: (value) => setDefaultSortConfig(value),
    [ConfigKeys.underMaintenanceBanner]: (value) => {
      setUnderMaintenanceBanner(value);
    },
  };

  useEffect(() => {
    if (clientConfigs) {
      configKeys.forEach((key) => {
        const value = getConfigValueByName({
          configs: clientConfigs,
          name: key,
        });
        setConfigByKey[key](value);
      });
    }
  }, [clientConfigs]);

  useEffect(() => {
    if (changeFilterByLevelLabel) {
      const changeFrom = changeFilterByLevelLabel.from;
      const changeTo = changeFilterByLevelLabel.to;
      const urlParams = new URLSearchParams(window.location.search);
      const levels = urlParams
        .get("filter_level")
        ?.replace(`${changeFrom}`, `${changeTo}`);
      if (levels) {
        urlParams.delete("filter_level");
        urlParams.set("filter_level", levels);
        window.history.replaceState(
          null,
          document.title,
          `${window.location.pathname}?${urlParams.toString()}`
        );
      }
    }
  }, [changeFilterByLevelLabel]);

  const updateClientConfig = async ({ key, newValue }) => {
    const newConfig = await upsertConfigByKey({
      configKey: key,
      configValue: newValue,
    });
    setConfigByKey[key](newConfig.value);
  };

  const value = React.useMemo(
    () => ({
      epicRoadmapGeneralStatuses,
      rawPriorities,
      extraRoadmapSummaryColumns,
      roadmapUseDoneStatusAsCompleted,
      showProjectStatus,
      roadmapShowEpicStatusColumn,
      defaultClientFilterConfig,
      clientConfigs,
      pullEpicIssuesConfig,
      updateClientConfig,
      customFieldsConfig,
      showProjectedFinishDate,
      calculateInitiativeProgressPercentageBy,
      riskConfig,
      reportsStakeholderField,
      calculateAbsoluteProgressByInitiativesOnly,
      useOneBoardApproachForRoadmap,
      replaceRoadmapColumnWithCustomFields,
      roadmapProjectStatusConfigs,
      defaultSortConfig,
      defaultUrlConfig,
      blockDataType,
      velmaInfoUrl,
      changeInitiativeToProjectLabel: changeFilterByLevelLabel,
      daysToShowRecentlyChildrenAddedFlag,
      defaultViewSchema,
      jiraURL,
      underMaintenanceBanner,
    }),
    [
      epicRoadmapGeneralStatuses,
      extraRoadmapSummaryColumns,
      rawPriorities,
      roadmapUseDoneStatusAsCompleted,
      showProjectStatus,
      roadmapShowEpicStatusColumn,
      defaultClientFilterConfig,
      clientConfigs,
      pullEpicIssuesConfig,
      showProjectedFinishDate,
      customFieldsConfig,
      calculateInitiativeProgressPercentageBy,
      reportsStakeholderField,
      calculateAbsoluteProgressByInitiativesOnly,
      useOneBoardApproachForRoadmap,
      riskConfig,
      replaceRoadmapColumnWithCustomFields,
      roadmapProjectStatusConfigs,
      defaultSortConfig,
      defaultUrlConfig,
      blockDataType,
      velmaInfoUrl,
      changeFilterByLevelLabel,
      daysToShowRecentlyChildrenAddedFlag,
      defaultViewSchema,
      jiraURL,
      underMaintenanceBanner,
    ]
  );

  return (
    <ClientConfigContext.Provider value={value}>
      {children}
    </ClientConfigContext.Provider>
  );
}

export { useClientConfigContext };
