export const truncateStringInTheMiddle = (str: string, maxLength: number) => {
  if (str?.length <= maxLength) {
    return str;
  }

  const pieceLength = Math.floor((maxLength - 3) / 2); // -3 for the ellipsis
  const startPiece = str.slice(0, pieceLength);
  const endPiece = str.slice(-pieceLength);

  return `${startPiece}...${endPiece}`;
};

export function pluralize({ word, count }) {
  return count === 1 ? word : `${word}s`;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
