import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { WidgetNames } from "src/v3/entities/Widget";
import { useWidgetContext } from "src/v3/providers/WidgetContext";
import { TeamsList } from "src/v3/widgets/TeamsList";
import { CentralizeBox } from "../commomStyles/CentralizeDiv";
import { ProductList } from "src/v3/widgets/ProductList";

export const WidgetBuilder = ({ items }: { items: BlockItem[] }) => {
  const { widget } = useWidgetContext();
  if (!widget) {
    return null;
  }
  const { name } = widget;
  switch (name) {
    case WidgetNames.TeamsGroups:
      return (
        <CentralizeBox>
          <TeamsList items={items} />
        </CentralizeBox>
      );
    case WidgetNames.Teams:
      return (
        <CentralizeBox>
          <TeamsList items={items} />
        </CentralizeBox>
      );
    case WidgetNames.Products:
      return (
        <CentralizeBox>
          <ProductList items={items} />
        </CentralizeBox>
      );
    default:
      throw new Error(`WidgetBuilder: widget with name ${name} not found`);
  }
};
