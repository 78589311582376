import { Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useSummaryOfWorkStyles = makeStyles((theme: Theme) => ({
  actionLabel: {
    textAlign: "right",
    color: theme.palette.text.primary,
    ...theme.typography.body1,
  },
}));
