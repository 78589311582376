import { Snackbar, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useLayout } from "src/providers/LayoutContext";
import LoadingViewWidget from "./LoadingWidget";
import { UnderMaintenanceView } from "./UnderMainatenceView";
import { useClientConfigContext } from "src/providers/ClientConfigContext";
import { Alert } from "@material-ui/lab";
import { ViewFilterContainer } from "src/v3/dataContainers/ViewFilterContainer";
import { WidgetV2 } from "src/v2/components/Widget/Widget";
import { useLoadBlockContext } from "src/v2/providers/block/LoadBlockContext";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";

const useStyles = makeStyles({
  root: { margin: 8 },
});

export const RoadmapGenericViewV2 = () => {
  const { currentViewData: viewData } = useGenericViewContext();
  const classes = useStyles();
  const { normalizedHeaderHeight, mainContentWidth } = useLayout();
  const { widgets, options, key } = viewData;
  const [orderedWidgets, setOrderedWidgets] = useState([]);
  const [showMainatenceBanner, setShowMainatenceBanner] = useState(false);
  const customStyles = options?.find((option) => option.name === "styles");
  const isUnderMaintenance = options?.find(
    (option) => option.name === "is_under_maintenance"
  )?.value;
  const mainStyles = customStyles?.value?.main || {};
  const containerStyles = customStyles?.value?.container || {};
  const titleStyles = customStyles?.value?.title || {};

  const { filteredItems, isLoadingBlocks } = useLoadBlockContext();

  const { underMaintenanceBanner } = useClientConfigContext();
  useEffect(() => {
    if (underMaintenanceBanner && underMaintenanceBanner.length > 0) {
      setShowMainatenceBanner(true);
    }
  }, [underMaintenanceBanner]);

  useEffect(() => {
    // sort widgets by position
    setOrderedWidgets(
      widgets
        ?.filter(({ enabled }) => enabled)
        ?.sort((a, b) => a.position - b.position)
    );
  }, [JSON.stringify(widgets)]);
  const titleMarginTop =
    normalizedHeaderHeight +
    (titleStyles?.marginTop ? parseInt(titleStyles?.marginTop, 10) : 16);

  if (isUnderMaintenance) {
    const endDate = options?.find(
      (option) => option.name === "date_maintenance_end"
    )?.value;
    const isForcedMaintenance = options?.find(
      (option) => option.name === "is_forced_maintenance"
    )?.value;
    // Only return if date is in the future
    if (new Date(endDate) > new Date() || isForcedMaintenance) {
      return <UnderMaintenanceView viewData={viewData} />;
    }
  }
  const handleClose = () => {
    setShowMainatenceBanner(false);
  };

  const genericViewFilters =
    options?.find((option) => option.name === "generic_view_filters")?.value ||
    [];

  return (
    <div
      data-test-id={`generic-view-root-${key}`}
      className={classes.root}
      style={{
        ...containerStyles,
        width: mainContentWidth,
        padding: "0",
      }}
    >
      <Snackbar
        ClickAwayListenerProps={{ onClickAway: () => null }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={showMainatenceBanner}
        onClose={() => setShowMainatenceBanner(false)}
      >
        <Alert
          style={{ padding: 16, fontSize: 18, display: "flex" }}
          onClose={() => setShowMainatenceBanner(false)}
          severity="warning"
        >
          {underMaintenanceBanner || ""}
        </Alert>
      </Snackbar>

      {isLoadingBlocks ? (
        <LoadingViewWidget />
      ) : (
        <>
          <header style={{ marginTop: titleMarginTop }}></header>
          <main
            data-testid={`generic-view-main-${key}`}
            style={{ ...mainStyles }}
          >
            <ViewFilterContainer
              filters={genericViewFilters}
              items={filteredItems}
            >
              {({ items }) =>
                orderedWidgets.map((widget, index) => (
                  <WidgetV2 key={index} items={items} widget={widget} />
                ))
              }
            </ViewFilterContainer>
          </main>
        </>
      )}
    </div>
  );
};
