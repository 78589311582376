import { Typography, ButtonGroup, Button } from "@material-ui/core";

export const ExpandAllButton = ({ onClick, expanded }) => (
  <ButtonGroup
    disableElevation
    style={{
      height: 36,
      display: "flex",
      justifyContent: "center",
      margin: 16,
    }}
    variant="contained"
    color="primary"
  >
    <Button
      variant={expanded ? "outlined" : "contained"}
      color="primary"
      style={{ width: 136, textTransform: "none" }}
      onClick={onClick}
    >
      <Typography>Collapse All</Typography>
    </Button>
    <Button
      variant={expanded ? "contained" : "outlined"}
      style={{ width: 124, textTransform: "none" }}
      onClick={onClick}
    >
      <Typography>Expand All</Typography>
    </Button>
  </ButtonGroup>
);
