export const colors = {
  white: {
    100: "#FFFFFF",
    200: "#F5F5F5",
  },
  gray: {
    100: "#DEDEE2",
    200: "#D8D8D8",
    300: "#C9CACD",
    400: "#636467",
    500: "#363639",
    600: "#D3D5D7",
    800: "#000A11",
    900: "#08090C",
  },
  blue: {
    50: "#e8f2f8",
    100: "#197BBD",
    200: "#125684",
  },
  green: {
    100: "#B7DFB9",
    200: "#5C9D89",
    300: "#4CAF50",
    400: "#009688",
    500: "#092E22",
  },
  red: {
    50: "#FEECEB",
    100: "#FBB4AF",
    200: "#F44336",
    300: "#E91E63",
    400: "#621B16",
  },
  yellow: {
    100: "#FFD699",
    200: "#FF9800",
    300: "#663D00",
    400: "#FF9C23",
  },
  purple: {
    100: "#D7A9DF",
    200: "#BA68C8",
  },
  indigo: {
    100: "#B2B9E1",
    200: "#7985CB",
  },
};
