import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { TextSideInfo } from "./Text";

export const SprintSideInfo = ({ item }: { item: BlockItem }) => {
  if (!item.getSprintField()?.getSprintDisplayName()) {
    return null;
  }
  return (
    <TextSideInfo
      key={item.getSprintField()?.getFieldKey()}
      label="Sprint"
      value={item.getSprintField()?.getSprintDisplayName()}
    />
  );
};
