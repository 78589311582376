import { findValueFromMultipleArrayLevels } from "src/v2/utils/array";
import { BlockItem } from "../../block/BlockItem";

class SectionFilter {
  public readonly type: string;

  public readonly values: string[];

  public readonly fieldKey: string;
  public readonly config: any;

  constructor({ type, values, fieldKey, config }) {
    this.type = type;
    this.values = values;
    this.fieldKey = fieldKey;
    this.config = config ?? {};
  }

  protected shouldReturnFalse(field): boolean {
    return (
      !field ||
      !field.value ||
      !Array.isArray(field.value) ||
      !field.value.length ||
      !field.value[0] ||
      field.value[0] === "" ||
      field.value[0] === null
    );
  }

  protected hasAcceptedDefaultValue(field, valueProperty = null): boolean {
    if (!this.config.accepted_default_values) {
      return false;
    }

    const value = findValueFromMultipleArrayLevels(field.value);
    const fieldValue = valueProperty ? value[valueProperty] : value;
    return this.config.accepted_default_values.includes(fieldValue);
  }

  public filterItem(item: BlockItem): boolean {
    const field = item.getFieldByFieldKey(this.fieldKey);

    if (this.fieldKey === "key") {
      const normalizedValues = this.values.map((value) =>
        value.replace(/\s/g, "").toLowerCase()
      );

      const matchFound = field.value.some((_value) =>
        normalizedValues.some((value) =>
          _value.replace(/\s/g, "").toLowerCase().includes(value)
        )
      );

      if (matchFound) {
        return true;
      }
    }

    if (!field) {
      return false;
    }
    return field.value.some((_value) =>
      this.values
        .map((value) => value.replace(/\s/g, "").toLowerCase())
        .includes(_value.replace(/\s/g, "").toLowerCase())
    );
  }

  filterChildren = (item: BlockItem, filterItemCallBack): boolean => {
    const children = item.children;
    if (children && children.length) {
      for (const child of children) {
        if (filterItemCallBack(child)) {
          return true;
        }
      }
    }
    return false;
  };
}

export default SectionFilter;
