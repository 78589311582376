import { BlockItem } from "../block/BlockItem";
import { FilterFieldBase } from "./FilterFieldBase";
import { DateFilterType } from "../../enum/DateFilterType";
import { FieldConfigType } from "../../enum/FieldConfigType";
import { RiskComparisonDateField } from "../customField/RiskComparisonDateField";
import { isValidStringDate } from "src/v2/utils/date";
import { formatDate } from "src/utils/stringUtils";

export class RiskComparisonDateFilterField extends FilterFieldBase {
  matchItem(item: BlockItem): boolean {
    const dateField = item.getFieldByType(
      FieldConfigType.risk_comparison_date
    ) as RiskComparisonDateField;

    if (!dateField) {
      return false;
    }

    const isValidDate = isValidStringDate(dateField.getRiskComparisonDate());
    if (!isValidDate || dateField.isDefaultDate) {
      return false;
    }

    const riskComparisonDate = new Date(
      dateField.getRiskComparisonDate()
    ).getTime();
    const { from, to } = this.values[0];
    if (!from && !to) {
      return false;
    }
    const fromDate = new Date(from).getTime();
    const toDate = new Date(to).getTime();
    return riskComparisonDate >= fromDate && riskComparisonDate <= toDate;
  }

  getUrlSearchParams() {
    if (
      this.values &&
      this.values.length > 0 &&
      typeof this.values[0] === "object"
    ) {
      const { from, to } = this.getValuesIfString();
      if (from && to) {
        return `${this.fieldKey}=${this.values[0].from}to${this.values[0].to}`;
      }
    }
    return "";
  }

  setValues(values: any): void {
    if (typeof values[0] === "string" && values[0].includes("to")) {
      const [from, to] = values[0]?.split("to") || [undefined, undefined];
      this.values = [{ from, to, dateFilterType: DateFilterType.DAYS_BETWEEN }];
    } else if (values[0] && typeof values[0] === "object") {
      const { from, to } = values[0];
      this.values = [{ from, to, dateFilterType: DateFilterType.DAYS_BETWEEN }];
    }
  }

  getFilteredByString() {
    const { from, to } = this.getValuesIfString();
    if (from && to) {
      return `${this.displayName}: from ${formatDate(from)} to ${formatDate(
        to
      )}`;
    }
    return "";
  }

  getFilterDisplayValues() {
    const { from, to } = this.getValuesIfString();
    if (from && to) {
      const displayString = `${this.displayName}: from ${formatDate(
        from
      )} to ${formatDate(to)}`;
      return [{ displayString, value: this.values[0] }];
    }
    return [];
  }

  valuesAreEqual(value1, value2) {
    return value1.from === value2.from && value1.to === value2.to;
  }

  getFilterComponent() {
    return <div></div>;
  }
}
