import { Grid, Typography } from "@material-ui/core";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";
import { FilteredByButton } from "./styles";
import IconComponent from "../../Icons/IconComponent";
import { Close } from "src/v3/theme/icons/general";

const FilteredBy = () => {
  const { selectedFilters, removeFilterValue } = useFilterContext();

  if (!selectedFilters?.length) return null;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      style={{
        gap: "8px",
      }}
    >
      {selectedFilters.map((filterField) => {
        const displayValues = filterField.getFilterDisplayValues();

        return displayValues.map(({ displayString, value }, index) => (
          <FilteredByButton
            key={`${filterField.id}-${index}`}
            onClick={() => removeFilterValue(filterField.id, value)}
          >
            <Typography>{displayString}</Typography>
            <IconComponent Icon={Close} size={16} />
          </FilteredByButton>
        ));
      })}
    </Grid>
  );
};

export default FilteredBy;
