import { Typography } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useEventListener, EventTypes } from "src/providers/EventContext";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import ActionMenu from "src/v3/components/ActionMenu";
import { ResolveChallengeAction } from "src/v3/components/ActionMenu/Actions/ResolveChallengeAction";
import { ItemDetailAccordion } from "../../commonStyles/ItemDetailAccordion";
import {
  RiskGrid,
  RiskTitle,
  RiskStatus,
  ExpandedRiskContent,
  ChallengeContent,
  RiskHeader,
  Root,
} from "./styles";
import theme from "src/v3/theme/theme";
import {
  AIConfidenceHigh,
  AIConfidenceLow,
  AIConfidenceMedium,
} from "src/v3/theme/icons/general";
import IconComponent from "src/v3/components/Icons/IconComponent";
import useClickDragToggle from "src/v3/hooks/useClickDragToggle";

const getAIConfidenceIcon = (confidence: string) => {
  const icon = {
    high: AIConfidenceHigh,
    medium: AIConfidenceMedium,
    low: AIConfidenceLow,
  };

  return icon[confidence] || icon.medium;
};

const ShowDetailsButton = ({
  toggleAllDetails,
  areAllRisksCollapsed,
  showAllDetails,
}: {
  toggleAllDetails: () => void;
  areAllRisksCollapsed: boolean;
  showAllDetails: boolean;
}) => {
  const _label =
    !showAllDetails || areAllRisksCollapsed ? "Show details" : "Hide details";

  return (
    <Typography
      onClick={(e) => {
        e.stopPropagation();
        toggleAllDetails();
      }}
      style={{
        cursor: "pointer",
        textAlign: "center",
        display: "flex",
        justifyContent: "flex-end",
        color: theme.palette.primary.main,
        ...theme.typography.body1,
      }}
    >
      {_label}
    </Typography>
  );
};

export const TopChallenges = ({
  item,
  title,
}: {
  item: BlockItem;
  title: string;
}) => {
  const [expandedRisks, setExpandedRisks] = useState<{
    [key: string]: boolean;
  }>({});
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const [showAllDetails, setShowAllDetails] = useState(false);

  const risksField = item.getRiskField();
  const risks = risksField?.getValue();
  const hasRisks = risks?.length > 0;

  const handleToggleAccordion = () => {
    setIsAccordionExpanded((prev) => !prev);
    if (showAllDetails) {
      toggleAllDetails();
    }
  };

  const toggleRiskDetails = (riskId: string) => {
    setExpandedRisks((prev) => ({
      ...prev,
      [riskId]: !prev[riskId],
    }));
  };

  const toggleAllDetails = () => {
    setShowAllDetails((prev) => !prev);
    setExpandedRisks(
      risks.reduce((acc: { [key: string]: boolean }, risk) => {
        acc[risk.title] = !showAllDetails;
        return acc;
      }, {})
    );
    if (!showAllDetails && !isAccordionExpanded) {
      setIsAccordionExpanded(true);
    }
  };

  const riskDeletedOverride = useEventListener(
    EventTypes.RISK_DELETED,
    "TopChallenges"
  );

  useEffect(() => {
    console.log("Risk deleted override", riskDeletedOverride);
  }, [riskDeletedOverride]);

  const areAllRisksCollapsed = risks?.every(
    (risk) => !expandedRisks[risk.title]
  );

  const actionComponents = [(props) => <ResolveChallengeAction {...props} />];

  const { handleMouseDown, handleMouseMove, handleMouseUp } =
    useClickDragToggle();

  return (
    <Root>
      <ItemDetailAccordion
        title={title}
        expanded={isAccordionExpanded}
        setExpanded={handleToggleAccordion}
        actions={[
          hasRisks && (
            <ShowDetailsButton
              toggleAllDetails={toggleAllDetails}
              areAllRisksCollapsed={areAllRisksCollapsed}
              showAllDetails={showAllDetails}
            />
          ),
        ]}
      >
        {hasRisks && (
          <>
            {risks.map((risk, index) => {
              const isLast = index === risks.length - 1;
              const isExpanded = expandedRisks[risk.title] || false;

              const actions = actionComponents.map((ActionComponent, idx) => (
                <ActionComponent key={idx} challenge={risk} item={item} />
              ));

              return (
                <RiskGrid
                  key={risk.title}
                  onClick={() => toggleRiskDetails(risk.title)}
                  isLast={isLast}
                >
                  <RiskHeader>
                    <IconComponent
                      Icon={getAIConfidenceIcon(risk.confidence || "medium")}
                    />
                    <RiskTitle>{risk.title}</RiskTitle>
                    <RiskStatus>{risk.status}</RiskStatus>
                    {hasRisks && <ActionMenu actions={actions} />}
                  </RiskHeader>
                  {isExpanded && risk.options && (
                    <ExpandedRiskContent>
                      {risk.options.map(({ value }) => (
                        <ChallengeContent
                          onMouseDown={handleMouseDown}
                          onMouseMove={handleMouseMove}
                          key={value}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          style={{ cursor: "text" }}
                          onMouseUp={handleMouseUp}
                          dangerouslySetInnerHTML={{ __html: value }}
                        />
                      ))}
                    </ExpandedRiskContent>
                  )}
                </RiskGrid>
              );
            })}
          </>
        )}
      </ItemDetailAccordion>
    </Root>
  );
};
