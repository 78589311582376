import {
  Avatar,
  Divider,
  UserInitials,
  Line,
  MenuItemName,
  MenuPaperProps,
  MenuRow1,
  MenuRow11,
  MenuRow6,
  UserInitialsContainer,
  UserName,
} from "./styles";
import { getUserInitials } from "./utils";
import { useUserMenu } from "./useUserMenu";
import { useState, MouseEvent } from "react";
import { Menu } from "@material-ui/core";
import { LogoutAction } from "./LogoutAction";

export const UserMenu = () => {
  const { user } = useUserMenu();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  return (
    <>
      <UserInitialsContainer onClick={handleClick}>
        {getUserInitials(user)}
      </UserInitialsContainer>
      <Menu
        id="action-menu-item-row"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: MenuPaperProps,
        }}
      >
        <MenuRow1>
          <Avatar>
            <UserInitials>{getUserInitials(user)}</UserInitials>
          </Avatar>
          <UserName>{user.name}</UserName>
        </MenuRow1>
        <Line />
        <LogoutAction />
      </Menu>
    </>
  );
};
