import styled from "styled-components";

export const RenderAccordionItemRoot = styled.div`
  min-height: 50px;
`;

export const EmptyChildDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const ItemNameRoot = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(1.3)};
  flex: 1 0 0;

  font-family: ${({ theme }) => theme.typography.body1.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body1.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body1.lineHeight} !important;
  font-weight: 600 !important;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const ProductListRoot = styled.div`
  border-radius: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2, 0)};
`;

export const TeamListFakeAccordion = styled.div`
  width: 100%;
`;

export const ItemNameHeader = styled.div`
  width: fit-content;
  cursor: pointer;
  &:hover {
    text-decoration: underline !important;
  }
`;
