import { Grid, FormControl, Checkbox } from "@material-ui/core";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import CustomDialog from "../../Dialogs/Base";
import { useChallengeAction } from "./useChallengeAction";
import { ResolveChallengeWarningIcon } from "../../Icons/ResolveChallendWarning";
import { RetainOverride } from "../../commomStyles/RetainOverride";
import {
  WarningGrid,
  TitleTypography,
  JustificationTypography,
  JustificationTextField,
  RetainFormControlLabel,
  RetainLabelTypography,
} from "./styles";

export const ResolveChallengeModal = ({
  challenge,
  item,
  open,
  handleOpen,
}: {
  challenge: any;
  item: BlockItem;
  open: boolean;
  handleOpen: () => void;
}) => {
  const { state, isSaving, handleStateChange, onSave, resetState } =
    useChallengeAction({
      item,
      challenge,
    });

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    resetState();
    handleOpen();
  };

  const handleSave = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    await onSave();
    handleOpen();
  };

  return (
    <CustomDialog
      open={open}
      onSave={handleSave}
      onClose={handleClose}
      title="Resolve Challenge"
      enableSaveButton={true}
      isSaving={isSaving}
    >
      <Grid container direction="column">
        <WarningGrid container>
          <ResolveChallengeWarningIcon />
          <TitleTypography>
            Are you sure you want to resolve this challenge?
          </TitleTypography>
        </WarningGrid>
        <Grid container direction="column">
          <JustificationTypography>
            Justification (optional)
          </JustificationTypography>
          <FormControl variant="outlined" required>
            <JustificationTextField
              id="outlined-multiline-static"
              multiline
              placeholder="Let Velma know why you are resolving the challenge."
              variant="outlined"
              value={state.justification}
              onChange={(e) => {
                handleStateChange("justification", e.target.value);
              }}
              inputProps={{ maxLength: 250 }}
            />
          </FormControl>
        </Grid>

        <Grid container direction="column" style={{ marginTop: 16 }}>
          <RetainFormControlLabel
            control={
              <Checkbox
                key={`retainStatus-${Math.random()}`}
                checked={state.retainOverride}
                onChange={(e) =>
                  handleStateChange("retainOverride", e.target.checked)
                }
                color="primary"
              />
            }
            label={
              <RetainLabelTypography>
                Resolve this challenge for a certain duration
              </RetainLabelTypography>
            }
          />
          {state.retainOverride && (
            <RetainOverride
              stateChanged={true}
              expirationDate={state.expirationDate}
              handleExpirationDateChange={(e) =>
                handleStateChange("expirationDate", e.target.value)
              }
            />
          )}
        </Grid>
      </Grid>
    </CustomDialog>
  );
};
