import { EmptyState as EmptyStateIcon } from "src/v3/theme/icons/general";
import {
  IconContainer,
  MessageContainer,
  EmptyStateRoot,
  TitleContainer,
} from "./style";
import IconComponent from "../Icons/IconComponent";
import { EmptyStateProps } from "./types";

const EmptyState = ({
  message = "No items available",
  icon = EmptyStateIcon,
}: EmptyStateProps) => {
  return (
    <EmptyStateRoot>
      <IconContainer>
        <IconComponent Icon={icon} size={100} />
      </IconContainer>
      <TitleContainer>Nothing to see here yet</TitleContainer>
      <MessageContainer>{message}</MessageContainer>
    </EmptyStateRoot>
  );
};

export default EmptyState;
