import { backgroundColorMap } from "src/v3/theme/icons/mappers";
import { IssueType } from "../types";
import {
  Idea,
  Investigation,
  Product,
  Project,
  QuickWins,
  Story,
  SubTask,
  Suggestion,
  SupportRequest,
  Task,
  VelmaTask,
  Team,
  Bug,
} from "src/v3/theme/icons/issueType";
import { IssueTypeIconBase } from "./base";
import theme from "src/v3/theme/theme";

export const IssueTypeIconFactory = ({
  issueType,
  size,
}: {
  issueType: IssueType;
  size: "small" | "medium" | "large";
}) => {
  switch (issueType) {
    case IssueType.Team:
      return (
        <IssueTypeIconBase
          backgroundColor={backgroundColorMap.Team}
          size={size}
          icon={Team}
        />
      );
    case IssueType.Idea:
      return (
        <IssueTypeIconBase
          backgroundColor={backgroundColorMap.Idea}
          size={size}
          icon={Idea}
        />
      );
    case IssueType.Product:
      return (
        <IssueTypeIconBase
          backgroundColor={backgroundColorMap.Product}
          size={size}
          icon={Product}
        />
      );
    case IssueType.Project:
      return (
        <IssueTypeIconBase
          backgroundColor={backgroundColorMap.Project}
          size={size}
          icon={Project}
        />
      );

    case IssueType.Initiative:
      return (
        <IssueTypeIconBase
          backgroundColor={backgroundColorMap.Product}
          size={size}
          icon={Product}
        />
      );
    case IssueType.Epic:
      return (
        <IssueTypeIconBase
          backgroundColor={backgroundColorMap.Project}
          size={size}
          icon={Project}
        />
      );
    case IssueType.Bug:
      return (
        <IssueTypeIconBase
          backgroundColor={backgroundColorMap.Task}
          size={size}
          fill={theme.palette.primary.contrastText}
          icon={Bug}
        />
      );
    case IssueType.Task:
      return (
        <IssueTypeIconBase
          backgroundColor={backgroundColorMap.Task}
          size={size}
          icon={Task}
        />
      );
    case IssueType.Story:
      return (
        <IssueTypeIconBase
          backgroundColor={backgroundColorMap.Task}
          size={size}
          icon={Story}
        />
      );
    case IssueType.Suggestion:
      return (
        <IssueTypeIconBase
          backgroundColor={backgroundColorMap.Idea}
          size={size}
          icon={Suggestion}
        />
      );
    case IssueType.Investigation:
      return (
        <IssueTypeIconBase
          backgroundColor={backgroundColorMap.Task}
          size={size}
          icon={Investigation}
        />
      );
    case IssueType.QuickWins:
      return (
        <IssueTypeIconBase
          backgroundColor={backgroundColorMap.Task}
          size={size}
          icon={QuickWins}
        />
      );
    case IssueType.SupportRequest:
      return (
        <IssueTypeIconBase
          backgroundColor={backgroundColorMap.Task}
          size={size}
          icon={SupportRequest}
        />
      );
    case IssueType.SubTask || IssueType.Subtask:
      return (
        <IssueTypeIconBase
          backgroundColor={backgroundColorMap.Task}
          size={size}
          icon={SubTask}
        />
      );
    default:
      return (
        <IssueTypeIconBase
          backgroundColor={backgroundColorMap.Task}
          size={size}
          icon={VelmaTask}
        />
      );
  }
};
