export interface IconComponentProps {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  stroke?: string;
  fill?: string;
  backgroundColor?: string;
  size?: "small" | "medium" | "large";
}

export enum IssueType {
  Team = "Team",
  Idea = "Idea",
  Product = "Product",
  Project = "Project",
  Initiative = "Initiative",
  Epic = "Epic",
  Bug = "Bug",
  Task = "Task",
  Story = "Story",
  Suggestion = "Suggestion",
  Investigation = "Investigation",
  QuickWins = "QuickWins",
  SupportRequest = "Support Request",
  Subtask = "Subtask",
  SubTask = "SubTask",
  Default = "Default",
  TechnicalStory = "Technical Story",
}
