import { useAuth0 } from "@auth0/auth0-react";
import setErrorSnackbar from "src/services/setErrorSnackbar";
import * as sessionService from "src/services/sessionService";

export const useUserMenu = () => {
  const { logout } = useAuth0();
  const user = sessionService.getLoggedUser();

  async function doLogout() {
    try {
      await sessionService.logout();
      logout({ returnTo: window.location.origin });
    } catch (error) {
      setErrorSnackbar(
        "An error has happened in logout. If you continue to see this error, please contact support@heyvelma.com."
      );
    }
  }

  return { doLogout, user };
};
