import { Menu } from "@material-ui/core";
import {
  getLegendColor,
  getTextColor,
} from "src/v3/utils/ProjectStatus/colors";
import { useState } from "react";
import {
  ProjectStatusSelectorMenuItem,
  ProjectStatusSelectorRoot,
  StatusName,
  useStyles,
} from "./styles";
import { ChevronUp, ChevronDown } from "src/v3/theme/icons/general";
import IconComponent from "src/v3/components/Icons/IconComponent";

export const ProjectStatusSelectorV2 = ({
  status,
  handleStatusChange,
}: {
  status;
  handleStatusChange;
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const _options = ["Off Track", "At Risk", "On Track", "Done", "Paused"];
  return (
    <>
      <ProjectStatusSelectorRoot
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        backgroundColor={getLegendColor(status)}
      >
        <div
          style={{
            height: "20px",
            padding: "0 8px",
            backgroundColor: getLegendColor(status),
          }}
        >
          <StatusName color={getTextColor(status)}>{status}</StatusName>
        </div>
        <IconComponent
          Icon={anchorEl ? ChevronUp : ChevronDown}
          stroke={getTextColor(status)}
        />
      </ProjectStatusSelectorRoot>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.menuOpen }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {_options.map((option, index) => (
          <ProjectStatusSelectorMenuItem
            key={index}
            value={option}
            onClick={() => {
              handleStatusChange(option);
              handleClose();
            }}
          >
            <div
              style={{
                height: "20px",
                padding: "0 8px",
                borderRadius: "4px",
                backgroundColor: getLegendColor(option),
              }}
            >
              <StatusName color={getTextColor(option)}>{option}</StatusName>
            </div>
          </ProjectStatusSelectorMenuItem>
        ))}
      </Menu>
    </>
  );
};
