import React from "react";
import { Box, makeStyles, Select } from "@material-ui/core";
import { ChevronDown } from "src/v3/theme/icons/general";
import { StyledFormControl, StyledMenuItem } from "./styles";

export const useStyles = makeStyles((theme) => ({
  selectorMenu: {
    borderColor: theme.palette.grey[900],
    borderRadius: "8px",
    boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.16)",
    backgroundColor: theme.palette.background.paper,
    marginTop: "4px",
    color: theme.palette.grey[900],
  },
  selector: {
    borderRadius: theme.spacing(1),
    color: theme.palette.grey[900],
    width: "110px",
  },
}));

export const RetainOverride = ({
  stateChanged,
  expirationDate,
  handleExpirationDateChange,
}: {
  stateChanged: boolean;
  expirationDate: string;
  handleExpirationDateChange: (
    e: React.ChangeEvent<{ value: unknown }>
  ) => void;
}) => {
  const expirationWeekOptions = ["1 week", "2 weeks", "3 weeks", "4 weeks"];

  const styles = useStyles();

  return (
    <Box
      style={{
        height: "48px",
        backgroundColor: "transparent",
      }}
    >
      <StyledFormControl variant="outlined" disabled={!stateChanged}>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={expirationDate}
          onChange={handleExpirationDateChange}
          required
          className={styles.selector}
          MenuProps={{
            PaperProps: {
              className: styles.selectorMenu,
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          IconComponent={ChevronDown}
        >
          {expirationWeekOptions.map((option, index) => (
            <StyledMenuItem key={index} value={option}>
              {option}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </Box>
  );
};
