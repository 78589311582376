import styled from "styled-components";
import { Theme, Typography } from "@material-ui/core";

export const ItemSummary = styled(Typography)<{
  onMouseUp?: (e: React.MouseEvent) => void;
  theme: Theme;
}>`
  font-family: ${({ theme }) => theme.typography.body1.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body1.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body1.lineHeight} !important;
  font-weight: ${({ theme }) => theme.typography.body1.fontWeight} !important;
  height: ${({ theme }) =>
    theme.typography.body1.height || "normal"} !important;

  color: ${({ theme }) => theme.palette.text.secondary};
  cursor: ${({ onMouseUp }) => (onMouseUp ? "pointer" : "default")};
  align-self: stretch;
  max-width: 90%;
  word-break: break-word;
  overflow-wrap: break-word;
`;
