import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { TextSideInfo } from "./Text";

export const DaysInProgressSideInfo = ({ item }: { item: BlockItem }) => {
  if (!item.getDaysInProgressField()?.getValue()?.[0]) {
    return null;
  }
  return (
    <TextSideInfo
      key={item.getDaysInProgressField()?.getFieldKey()}
      label="Days In Progress"
      value={item.getDaysInProgressField()?.getValue()?.[0]}
    />
  );
};
