import { FakeAccordionProps } from "./types";
import { ChevronDown, ChevronUp } from "src/v3/theme/icons/general";
import {
  CustomAccordionRoot,
  CustomAccordionSummary,
  ExpandIcon,
  Header,
} from "../Accordion/styles";
import IconComponent from "../Icons/IconComponent";
import theme from "src/v3/theme/theme";

export const FakeAccordion = ({
  header,
  callback,
  customStyle,
  isExpanded,
}: FakeAccordionProps) => {
  return (
    <CustomAccordionRoot
      onChange={callback}
      style={{
        boxShadow: "none",
        borderRadius: theme.spacing(2),
        ...customStyle,
      }}
    >
      <CustomAccordionSummary>
        <Header>{header}</Header>

        <ExpandIcon>
          <IconComponent Icon={isExpanded ? ChevronUp : ChevronDown} />
        </ExpandIcon>
      </CustomAccordionSummary>
    </CustomAccordionRoot>
  );
};
