import { CustomDialogProps } from "src/v3/components/Dialogs/Base/types";
import {
  CloseIcon,
  CustomDialogContent,
  CustomDialogTitle,
  DialogCloseButton,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogSaveButton,
} from "./style";
import { CircularProgress } from "@material-ui/core";
import IconComponent from "src/v3/components/Icons/IconComponent";

export default function CustomDialogV2({
  open,
  title,
  children,
  isSaving,
  enableSaveButton,
  onClose,
  onSave,
}: CustomDialogProps) {
  return (
    <DialogRoot
      onClose={onClose}
      aria-labelledby="custom-dialog-root"
      open={open}
      maxWidth="sm"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogHeader>
        <CustomDialogTitle id="custom-dialog-title">{title}</CustomDialogTitle>
        <IconComponent Icon={CloseIcon} onClick={onClose} />
      </DialogHeader>

      <CustomDialogContent>{children}</CustomDialogContent>

      <DialogFooter>
        <DialogCloseButton onClick={onClose}>Close</DialogCloseButton>
        <DialogSaveButton
          enableSaveButton={enableSaveButton}
          onClick={enableSaveButton ? onSave : () => {}}
        >
          {isSaving ? <CircularProgress size={24} color={"primary"} /> : "Save"}
        </DialogSaveButton>
      </DialogFooter>
    </DialogRoot>
  );
}
