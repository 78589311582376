import styled from "styled-components";
import { CentralizeBox } from "../commomStyles/CentralizeDiv";
import { Box } from "@material-ui/core";

export const GenericViewContainer = styled.div<{
  topBarHeight: number;
}>`
  width: 100%;
  height: 100%;
  padding-top: ${({ topBarHeight }) => `${topBarHeight}px`};
  transition: margin-left 0.3s ease;
  display: flex;
`;

export const GenericViewContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 10%;
  overflow-y: auto;
`;

export const FilteredByTextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;
