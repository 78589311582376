import { useContext, createContext, useMemo, useState, useEffect } from "react";
import WidgetConfig, { WidgetConfigType } from "../entities/WidgetConfig";
import Widget from "../entities/Widget";

const WidgetContext = createContext({
  widget: new Widget("", [], ""),
  widgetConfigs: [] as WidgetConfig[],
  name: "",
  getConfigsByType: (type: WidgetConfigType): WidgetConfig[] => [],
});

function useWidgetContext() {
  const context = useContext(WidgetContext);
  if (!context) {
    throw new Error("useWidgetContext must be used within a WidgetProvider");
  }
  return context;
}

function WidgetProvider({ children, _widget }) {
  const [widget, setWidget] = useState<Widget>();
  const [configs, setConfigs] = useState<WidgetConfig[]>([]);

  useEffect(() => {
    const { id, configs, name } = _widget;
    const _configs = configs?.map((config): WidgetConfig => {
      return new WidgetConfig(
        config.id,
        config.widget,
        config.name,
        config.type,
        config.groupBy,
        config.filters,
        config.value
      );
    }) as WidgetConfig[];

    const widget = new Widget(id, _configs, name);
    setWidget(widget);
    setConfigs(_configs);
  }, [_widget]);

  const getConfigsByType = (type: string): any => {
    return configs?.filter((config) => config.type === type) || [];
  };

  const value = useMemo(
    () => ({
      widget,
      configs,
      name: widget?.name || "",
      getConfigsByType,
    }),
    [widget]
  );

  return (
    <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>
  );
}

export { WidgetContext, WidgetProvider, useWidgetContext };
