import React from "react";
import { defaultStrokeColor, sizeMap } from "src/v3/theme/icons/mappers";
import { IconComponentProps } from "./types";

const IconComponent: React.FC<IconComponentProps> = ({
  stroke = defaultStrokeColor,
  fill = "transparent",
  size = sizeMap.default,
  Icon,
  onClick,
}) => (
  <Icon
    width={size}
    height={size}
    stroke={stroke}
    fill={fill}
    onClick={onClick}
  />
);

export default IconComponent;
