import styled from "styled-components";

export const AccordionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
`;

export const AccordionHeader = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(3)};
  gap: ${({ theme }) => theme.spacing(3)};
  align-items: center;
  align-self: stretch;
  cursor: pointer;
`;

export const AccordionTitle = styled.div`
  overflow: hidden;
  color: ${({ theme }) => theme.palette.grey[900]};
  text-overflow: ellipsis;
  font-family: ${({ theme }) => theme.typography.h2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.h2.fontSize} !important;
  font-weight: ${({ theme }) => theme.typography.h2.fontWeight} !important;
  line-height: ${({ theme }) => theme.typography.h2.lineHeight} !important;
`;

export const AccordionActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
  flex: 1 0 0;
`;

export const ExpandIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  cursor: pointer;
`;

export const AccordionContent = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
  align-self: stretch;
`;
