import styled from "styled-components";
import { Drawer } from "@material-ui/core";
import theme from "src/v3/theme/theme";

export const DrawerRoot = styled(Drawer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DrawerContent = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  padding: ${({ theme }) => theme.spacing(5)};
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(5)};
  margin-bottom: 94px;
`;

export const useStyles = () => {
  return {
    drawerPaper: {
      width: "80%",
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",
      height: "100%",
      position: "fixed",
      borderRadius: 0,
    },
  };
};
