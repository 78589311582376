import styled from "styled-components";

// Root status chip container with background color as a prop
export const StatusChipRoot = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  height: 100%;
  width: max-content;
  justify-content: center;
  border-radius: ${({ theme }) => theme.spacing(1)};
  white-space: nowrap;
`;

// Small version of Status Chip
export const SmallStatusChip = styled(StatusChipRoot)`
  height: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => theme.spacing(0, 1.3)};
`;

// Medium version of Status Chip
export const MediumStatusChip = styled(StatusChipRoot)`
  height: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;

// Status name styling, with dynamic color
export const StatusName = styled.div<{ color: string }>`
  font-family: ${({ theme }) => theme.typography.body2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body2.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body2.lineHeight} !important;
  font-weight: 700 !important;
  color: ${({ color }) => color};
  text-transform: uppercase;
  text-align: center;
`;

// Empty status chip styling
export const EmptyStatusChip = styled.div`
  height: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;
