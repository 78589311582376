import styled from "styled-components";

export const EmptyStateRoot = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(9)};
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: ${({ theme }) => theme.spacing(4)};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding: ${({ theme }) => theme.spacing(6)};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: ${({ theme }) => theme.spacing(4)};
  }
`;

export const MessageContainer = styled.p`
  color: ${({ theme }) => theme.palette.grey[900]};
  ${({ theme }) => theme.typography.body1};
  text-align: center;
`;

export const TitleContainer = styled.h1`
  color: ${({ theme }) => theme.palette.grey[900]};
  ${({ theme }) => theme.typography.h1};
  font-weight: ${({ theme }) => theme.typography.weight.semiBold};
`;

export const IconContainer = styled.div`
  width: 120px;
  height: 120px;
  padding: ${({ theme }) => theme.spacing(2)};
`;
