import { formatDate } from "src/utils/stringUtils";

type DateSideInfoProps = {
  value: any;
  label: string;
};

export const DateSideInfo = ({ value, label }: DateSideInfoProps) => {
  const formattedDate = value ? formatDate(value) : " -- ";
  return (
    <div>
      {label}: {formattedDate}
    </div>
  );
};
