import { Typography, MenuItem, Paper } from "@material-ui/core";
import BaseButton from "src/components/Buttons/BaseButton";
import { todoStatusProgressBarColor } from "src/v2/helpers/colors";
import styled from "styled-components";

export const StyledPaper = styled(Paper)`
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const FilterContainer = styled.div`
  margin-top: 8px;
  gap: 8px;
`;

export const FilterHeader = styled(Typography)`
  color: lightgrey;
  margin-right: 16px;
`;

export const ArchiveFilterButton = styled(BaseButton)`
  margin-left: 8px;
  height: 32px;
  border-radius: 4px 0 0 4px;
  min-width: 36px;
`;

export const FilterActionButton = styled(BaseButton)`
  border: 1px solid ${todoStatusProgressBarColor};
  background-color: transparent;
  z-index: 998;
  box-shadow: none;
`;

export const AdvancedFilterButton = styled(FilterActionButton)`
  margin-right: 4px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  height: 34px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const ClearFilterButton = styled(FilterActionButton)`
  margin-left: 4px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  height: 34px;
  background-color: ${({ theme }) => theme.palette.primary.light};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const EmptyFilterMessage = styled(MenuItem)`
  margin-top: 16px;
  color: ${({ theme }) => theme.palette.grey[400]};
`;
