import styled from "styled-components";
import { Typography } from "@material-ui/core";

export const ItemName = styled(Typography)<{
  onClick?: () => void;
  customStyle?: {};
}>`
  overflow: hidden;
  color: ${({ theme }) => theme.palette.primary.main};
  text-overflow: ellipsis;
  max-width: 90%;
  font-family: ${({ theme }) => theme.typography.h2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.h2.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.h2.lineHeight} !important;
  font-weight: ${({ theme }) => theme.typography.h2.fontWeight} !important;
  height: ${({ theme }) => theme.typography.h2.height || "normal"} !important;
  width: 100%;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  &:hover {
    text-decoration: ${({ onClick }) => (onClick ? "underline" : "none")};
  }
  display: flex;
  word-break: break-word;
  overflow-wrap: break-word;
  flex: 1 0 0;
  ${({ customStyle }) => customStyle};
`;

export const ItemKey = styled(Typography)`
  color: black;
  width: 15%;
  padding: ${({ theme }) => theme.spacing(1)};
  ${({ theme }) => theme.typography.h2};
`;
