import {
  BreadCrumbItem,
  CurrentNavigationBreadcrumbItem,
  GroupBreadcrumbRoot,
  NavigationBreadcrumbItem,
  NavigationBreadcrumbRoot,
} from "./styles";
import { ChevronRight } from "../../theme/icons/general";
import { BreadcrumbProps } from "./types";
import { truncateStringInTheEnd } from "src/utils/stringUtils";
import { sizeMap } from "src/v3/theme/icons/mappers";
import IconComponent from "../Icons/IconComponent";

export const GroupBreadcrumb = ({ groups }: BreadcrumbProps) => {
  return (
    <GroupBreadcrumbRoot separator={<ChevronRight />} aria-label="breadcrumb">
      {groups?.map((group, index) => (
        <BreadCrumbItem key={index}>{group}</BreadCrumbItem>
      ))}
    </GroupBreadcrumbRoot>
  );
};

export const NavigationBreadcrumb: React.FC<BreadcrumbProps> = ({ paths }) => {
  return (
    <NavigationBreadcrumbRoot
      separator={<IconComponent Icon={ChevronRight} size={sizeMap.small} />}
      aria-label="navigation-breadcrumb"
    >
      {paths?.map((path, index) => {
        const isLast = index === paths.length - 1;
        const BreadcrumbComponent = isLast
          ? CurrentNavigationBreadcrumbItem
          : NavigationBreadcrumbItem;

        return (
          <BreadcrumbComponent
            key={path.label}
            onClick={path.navigate}
            aria-current={isLast ? "page" : undefined}
          >
            {truncateStringInTheEnd(path.label, 40)}
          </BreadcrumbComponent>
        );
      })}
    </NavigationBreadcrumbRoot>
  );
};
