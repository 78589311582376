import { useState } from "react";
import IconComponent from "../Icons/IconComponent";
import { Search } from "src/v3/theme/icons/general";
import { useSearch } from "./useSearch";
import {
  SearchField,
  SearchIcon,
  SearchInputContainer,
  SearchItem,
  StyledAutocomplete,
  GroupHeader,
  XMoreResults,
  ItemIcon,
  OptionContainer,
  useStyles,
} from "./styles";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { useItemDetailNavigation } from "src/v3/providers/useItemDetailNavigation";
import { StyledPaper } from "./styles";
import { SearchMenuOption } from "./types";
import { IssueTypeIcon } from "../IssueTypeIcon";
import { IssueType } from "../IssueTypeIcon/types";
import { blockItemTypeMap } from "./utils";
import { capitalizeFirstLetter } from "src/v3/utils/string";
import { TextField } from "@material-ui/core";

export const SearchBar = () => {
  const { handleOpenItemDetails } = useItemDetailNavigation();
  const { handleSearch, options, setExpandedGroups, expandedGroups } =
    useSearch();

  const [inputValue, setInputValue] = useState("");

  const handleOptionSelect = (_: any, option: SearchMenuOption | null) => {
    if (option && option.display) {
      handleOpenItemDetails(option.item as BlockItem);
      setInputValue("");
      setExpandedGroups({});
    }
  };

  const handleInputChange = (event: any, newInputValue: string) => {
    setInputValue(newInputValue);
    handleSearch({ target: { value: newInputValue } });
    if (newInputValue === "") {
      setExpandedGroups({});
    }
  };

  const handleClose = () => {
    setInputValue("");
    setExpandedGroups({});
  };

  const handleExpandGroup = (e, option: SearchMenuOption) => {
    e.stopPropagation();
    e.preventDefault();
    setExpandedGroups({
      ...expandedGroups,
      [option.type]: true,
    });
  };
  const classes = useStyles();

  return (
    <SearchField>
      <SearchInputContainer>
        <StyledAutocomplete
          classes={{
            listbox: classes.listbox,
          }}
          freeSolo
          options={options}
          groupBy={(option: SearchMenuOption) => option.type}
          getOptionLabel={(option: SearchMenuOption) =>
            option.item?.getDisplayName() || ""
          }
          filterOptions={(options: SearchMenuOption[]) => options}
          inputValue={inputValue}
          open={inputValue.length > 0}
          onInputChange={handleInputChange}
          onClose={handleClose}
          onChange={handleOptionSelect}
          renderOption={(option: SearchMenuOption) => {
            if (!option.display) {
              return (
                <XMoreResults onClick={(e) => handleExpandGroup(e, option)}>
                  {`${option.total - 5} more results`}
                </XMoreResults>
              );
            } else {
              return (
                <OptionContainer>
                  <ItemIcon>
                    <IssueTypeIcon
                      type={option.item?.getBlockTypeName() as IssueType}
                      size="small"
                    />
                  </ItemIcon>
                  <SearchItem
                    onClick={() =>
                      handleOpenItemDetails(option.item as BlockItem)
                    }
                  >
                    {option.item?.getDisplayName()}
                  </SearchItem>
                </OptionContainer>
              );
            }
          }}
          renderGroup={(params) => (
            <>
              <GroupHeader key={params.key}>
                {blockItemTypeMap[params.group] ||
                  capitalizeFirstLetter(params.group)}
              </GroupHeader>
              {params.children}
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search"
              variant="outlined"
              size="small"
              InputProps={{
                ...params.InputProps,
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
                startAdornment: (
                  <SearchIcon>
                    <IconComponent Icon={Search} size={18} />
                  </SearchIcon>
                ),
              }}
            />
          )}
          PaperComponent={StyledPaper}
        />
      </SearchInputContainer>
    </SearchField>
  );
};
