import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";

export const useItemDetailNavigation = () => {
  const history = useHistory();
  const location = useLocation();

  const handleOpenItemDetails = useCallback(
    (item: BlockItem) => {
      // TODO: Access the parent keys of the item and add them to the breadcrumbLabels if there are any
      const searchParams = new URLSearchParams(window.location.search);
      const pathName = location.pathname.includes("details/item")
        ? location.pathname
        : `${location.pathname}/details/item`;
      history.push({
        pathname: `${pathName}/${item.getKey()}`,
        search: `?${searchParams}`,
        state: {
          background: location,
          breadcrumbLabels: [
            ...(location.state?.breadcrumbLabels || []),
            { itemKey: item.getKey(), label: item.getDisplayName() },
          ],
        },
      });
    },
    [history, location]
  );

  return {
    handleOpenItemDetails,
  };
};
