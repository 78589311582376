import { Divider, Menu } from "@material-ui/core";
import { Fragment, useState, ReactElement, MouseEvent } from "react";
import { MenuPaperProps } from "./styles";
import React from "react";

interface CustomMenuProps {
  actions: ReactElement[];
  TriggerComponent: ReactElement<{
    onClick: (event: MouseEvent<HTMLElement>) => void;
  }>;
  direction?: "column" | "row";
  divider?: boolean;
}

export const CustomMenu = ({
  actions,
  TriggerComponent,
  direction = "column",
  divider = false,
}: CustomMenuProps): JSX.Element => {
  if (!actions || actions.length === 0) {
    throw new Error("CustomMenu: 'actions' is required");
  }

  if (!TriggerComponent) {
    throw new Error("CustomMenu: 'TriggerComponent' is required");
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  return (
    <Fragment>
      {React.cloneElement(TriggerComponent, {
        onClick: handleClick,
      })}
      <Menu
        id="action-menu-item-row"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: MenuPaperProps,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: direction,
            padding: direction === "row" ? "8px" : "0",
            gap: direction === "row" ? "8px" : "0",
          }}
        >
          {actions.map((action, index) => (
            <div key={index} onClick={handleClose}>
              {action}
              {divider && index !== actions.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      </Menu>
    </Fragment>
  );
};
