import theme from "src/v3/theme/theme";
import IconComponent from "./IconComponent";
import { WarningTriangle } from "src/v3/theme/icons/general";

export const ResolveChallengeWarningIcon: React.FC = () => (
  <IconComponent
    Icon={WarningTriangle}
    size={42}
    stroke={theme.palette.warning.main}
  />
);
