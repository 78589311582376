import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { TextSideInfo } from "./Text";

export const PredictedCompletionSideInfo = ({ item }: { item: BlockItem }) => {
  return (
    <TextSideInfo
      style={{
        textAlign: "right",
      }}
      key={item.getProjectedDateField()?.getFieldKey()}
      label="Predicted Completion"
      value={item.getProjectedDateField()?.getPredictedText()}
    />
  );
};
