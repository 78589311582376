import { FilterFieldsFactory } from "src/v2/domain/entities/filter/factory";
import { WidgetConfigs } from "../components/Widget/WidgetConfigs";
import { BlockItem } from "../domain/entities/block/BlockItem";

export function getAllKeyValueFiltersFromURL() {
  const params = new URLSearchParams(location.search.split("?")[1]);
  const urlSearchKeys = Array.from(params.keys()).map((key) => key);
  return urlSearchKeys.map((key) => {
    const param = params.get(key);
    const urlParamValues = param.split("~");
    return {
      fieldKey: key,
      values: [...urlParamValues],
    };
  });
}

export function getAllKeyValueFiltersFromURLWithoutAdmin(url?: string) {
  return getAllKeyValueFiltersFromURL().filter(
    ({ fieldKey }) => fieldKey !== "admin"
  );
}

export function getAdminParamFromURLAsString() {
  const adminParam = getAllKeyValueFiltersFromURL().filter(
    ({ fieldKey }) => fieldKey === "admin"
  );
  if (adminParam.length === 0) return "";
  return `${adminParam[0].fieldKey}=${adminParam[0].values[0]}`;
}

export function buildURLWithFilterValues(baseUrl, filterValues) {
  const filterFieldsURLParams = Object.keys(filterValues)
    .map(
      (key) =>
        `${key}=${filterValues[key]?.reduce((pre, next) => `${pre}~${next}`)}`
    )
    .reduce((acc, curr) => `${curr}&${acc}`, "")
    .toString();

  const searchParams = new URLSearchParams(filterFieldsURLParams);
  const customUrlWithParams = `${baseUrl}?${searchParams.toString()}`;
  const adminParam = getAdminParamFromURLAsString();
  return adminParam
    ? `${customUrlWithParams}&${adminParam}`
    : customUrlWithParams;
}

export function buildURLForItemWithoutKey(
  getConfigValueByName,
  item: BlockItem
) {
  /**
   * Usage: It's used in the Budget Forecasting view. When some user clicks on a Big Rock,
   * it'll redirect the user to the baseURL set.
   * Hot to set: In widget_config table, add the
   * custom_url_for_items_without_url config with the following value format:
   * {
   *  to: "velma" || "external"
   *  baseUrl: "https://velma.work/v2/roadmap/executive" <- some URL to Velma view
   *  filters: ["key"] <- Array with all field keys that will be used as filters
   * }
   *  NOTES:
   *  1 - if "to" is "external", it can be any url we want to redirect the user to
   *  2 - make sure to add in the filters only field keys that are available in the item
   */
  const customUrlFromWidgetConfig = getConfigValueByName(
    WidgetConfigs.customUrlForItemsWithoutURL
  );
  if (!customUrlFromWidgetConfig) return "";
  const { to, baseUrl, filters } = customUrlFromWidgetConfig;
  if (to === "velma" && filters?.length > 0) {
    const filterValues = {};
    filters.forEach((filterKey) => {
      const values = item.getJiraItemCustomFieldValues(filterKey);
      /* In case of filterKey is project_status_insight, the structure is different
       * so we need to map the values by name. */
      if (values) {
        const valuesWithName = values.some((value) => value.name);
        const mappedValues = valuesWithName
          ? values.map((value) => value.name)
          : values;
        filterValues[filterKey] = mappedValues;
      }
    });
    return buildURLWithFilterValues(baseUrl, filterValues);
  }
  return baseUrl;
}

export function getAllKeyValueFiltersFromURLWithoutExtraParams() {
  const extraParams = ["filter_level", "admin"];
  return getAllKeyValueFiltersFromURL()
    .filter(({ fieldKey }) => !extraParams.includes(fieldKey))
    .map((filter) => FilterFieldsFactory.buildBaseFilterField(filter));
}

export function getFilterLevelFromURL() {
  return getAllKeyValueFiltersFromURL().filter(
    ({ fieldKey }) => fieldKey === "filter_level"
  );
}

export function getFilterFieldFromURLByKey(_fieldKey) {
  const ulrFilterFields = getAllKeyValueFiltersFromURL();
  return ulrFilterFields?.find(({ fieldKey }) => _fieldKey === fieldKey);
}

export function buildRedirectURLWithListOfFilters({
  filters,
  redirectView = "",
}) {
  const _redirectView =
    !redirectView || redirectView === "" ? "executive" : redirectView;
  const adminParam = getAdminParamFromURLAsString();
  const urlSearchParams = new URLSearchParams(
    filters
      .filter(({ values }) => values.filter((value) => value).length > 0)
      .map((filterField) => filterField.getUrlSearchParams())
      .reduce((acc, curr) => `${curr}&${acc}`, "")
      .toString()
  );
  const url = `/v2/roadmap/${_redirectView}?${urlSearchParams.toString()}`;
  if (adminParam) {
    return `${url}&${adminParam}`;
  }
  return url;
}

export function extractItemKeysFromUrl(url): string[] {
  const regex = /(?:key=|\/)([A-Z]+-\d+)(?:~([A-Z]+-\d+))?|\(([^)]+)\)/g;
  const keys = [];
  let match;

  while ((match = regex.exec(url)) !== null) {
    const [, key1, key2, parenthesizedKey] = match;

    if (key1) {
      keys.push(key1);
    }

    if (key2) {
      keys.push(key2);
    }

    if (parenthesizedKey) {
      keys.push(parenthesizedKey);
    }
  }

  return keys;
}

export const isEmailVersion = () => {
  const _isEmail = getAllKeyValueFiltersFromURL()?.find(
    ({ fieldKey }) => fieldKey === "email"
  )?.values;
  const isEmail = _isEmail && _isEmail[0] === "true" ? true : false;
  return isEmail;
};
