import { useEffect, useState } from "react";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";

export const useGenericView = () => {
  const { currentViewData: view } = useGenericViewContext();
  const { options, widgets } = view;

  const [orderedWidgets, setWidgets] = useState([]);

  const viewFilter = options?.find(
    ({ name }) => name === "generic_view_filters"
  )?.value;

  const isUnderMaintenance =
    options?.find(({ name }) => name === "is_under_maintenance")?.value ===
    "true";

  useEffect(() => {
    setWidgets(
      widgets
        .filter(({ enabled }) => enabled)
        .sort((a, b) => a.position - b.position)
    );
  }, [widgets]);

  return {
    orderedWidgets,
    isUnderMaintenance,
    filters: viewFilter,
  };
};
