import {
  Grid,
  Typography,
  Checkbox,
  IconButton,
  Drawer,
  Divider,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { useState, FC } from "react";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";
import {
  BottomButtonsContainer,
  FilterFieldOptions,
  FilterMenuHeaderContainer,
  HeaderTypography,
  StickyHeader,
  StyledButton,
  StyledGrid,
  useStyles,
} from "./styles";
import theme from "src/v3/theme/theme";
import IconComponent from "../../Icons/IconComponent";

export const FilterMenu = ({ anchorEl, setAnchorEl }) => {
  const {
    handleUpdateFilters,
    filterFields,
    selectedFiltersBeforeApply,
    handleClearFilters,
  } = useFilterContext();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearFilters = () => {
    handleClearFilters();
  };

  const applyFilters = ({ closeFilter = true }) => {
    handleUpdateFilters(selectedFiltersBeforeApply);
    if (closeFilter) handleClose();
  };

  const [expandAll, setExpandAll] = useState(false);

  const fieldsToShowOnMenu = filterFields.filter((field) =>
    field.showOnFilter()
  );
  const [store, setStore] = useState({});

  return (
    <Drawer
      data-testid="menu"
      id="filter-menu"
      anchor="right"
      open={Boolean(anchorEl)}
      onClose={handleClose}
      variant="persistent"
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      <StyledGrid>
        <StickyHeader>
          <FilterMenuHeader handleClose={handleClose} />
          <Divider />
          <FilterByLevel />
        </StickyHeader>

        <FilterFieldOptions>
          {fieldsToShowOnMenu.map((field) =>
            field.getFilterComponent({ expandAll, store, setStore })
          )}
        </FilterFieldOptions>

        <BottomButtonsContainer>
          <FilterMenuBottomButtons
            applyFilters={applyFilters}
            clearFilters={clearFilters}
            saveFilters={applyFilters}
          />
        </BottomButtonsContainer>
      </StyledGrid>
    </Drawer>
  );
};

export const FilterByLevel: FC = () => {
  const { selectedFilterLevels, handleSetFilterLevel, filterLevelOptions } =
    useFilterContext();
  const classes = useStyles();
  return (
    <Grid style={{ minWidth: 336 }} container direction="column">
      <Typography className={classes.title}>Apply To</Typography>
      <Grid container direction="row">
        {filterLevelOptions.map((value) => (
          <Grid
            container
            xs={4}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Checkbox
              checked={selectedFilterLevels.includes(value)}
              onChange={() => handleSetFilterLevel(value)}
              color="primary"
            />
            <Typography className={classes.text}>{value}</Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export const FilterMenuHeader = ({ handleClose }) => (
  <FilterMenuHeaderContainer>
    <Typography variant="h6" style={{ color: theme.palette.grey[900] }}>
      Filters
    </Typography>
    <IconComponent Icon={Clear} size={16} onClick={handleClose} />
  </FilterMenuHeaderContainer>
);

export const FilterMenuBottomButtons = ({
  applyFilters,
  clearFilters,
  saveFilters,
}) => (
  <Grid container justifyContent="space-between" direction="column">
    <StyledButton
      backgroundColor={theme.palette.primary.light}
      onHoverBackgroundColor={theme.palette.primary.main}
      onClick={applyFilters}
    >
      <Typography>Apply</Typography>
    </StyledButton>
    <StyledButton
      backgroundColor={theme.palette.primary.main}
      onHoverBackgroundColor={theme.palette.primary.light}
      onClick={saveFilters}
    >
      <Typography>Save filter</Typography>
    </StyledButton>
    <StyledButton
      backgroundColor={theme.palette.grey[300]}
      onHoverBackgroundColor={theme.palette.grey[400]}
      onClick={clearFilters}
    >
      <Typography>Clear</Typography>
    </StyledButton>
  </Grid>
);
