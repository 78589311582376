import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { DaysInProgressSideInfo } from "src/v3/components/commomStyles/ItemRowSideInfos/DaysInProgress";
import { PredictedCompletionSideInfo } from "src/v3/components/commomStyles/ItemRowSideInfos/PredictedCompletion";
import { ProjectedDateSideInfo } from "src/v3/components/commomStyles/ItemRowSideInfos/ProjectedDate";
import { SprintSideInfo } from "src/v3/components/commomStyles/ItemRowSideInfos/Sprint";
import { TargetDateSideInfo } from "src/v3/components/commomStyles/ItemRowSideInfos/TeamsTargetDate";

export const getSideInfos = (item: BlockItem) => {
  if (item.isTask()) {
    return [
      <PredictedCompletionSideInfo item={item} />,
      <SprintSideInfo item={item} />,
      <DaysInProgressSideInfo item={item} />,
    ];
  }
  return [
    <TargetDateSideInfo item={item} />,
    <ProjectedDateSideInfo item={item} />,
  ];
};
