import { useState, useEffect, useMemo } from "react";
import { useBlockContext } from "src/v2/providers/block/BlockContext";
import searchItems from "./utils";
import { SearchMenuOption } from "./types";
import { groupItemsByType } from "src/v3/utils/block_item";

export const useSearch = () => {
  const { flatOriginalItems } = useBlockContext();

  const [searchTerm, setSearchTerm] = useState("");
  const [foundItems, setFoundItems] = useState([]);

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>(
    {}
  );

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (!debouncedSearchTerm || debouncedSearchTerm === "") {
      setFoundItems([]);
      return;
    }
    const results = searchItems(flatOriginalItems, debouncedSearchTerm);
    setFoundItems(results);
  }, [debouncedSearchTerm, flatOriginalItems]);

  const groupedItemsByType = useMemo(
    () => groupItemsByType(foundItems),
    [foundItems]
  );

  const preparedOptions = useMemo((): SearchMenuOption[] => {
    let options: SearchMenuOption[] = [];

    const blockItemTypePosition = {
      idea: 1,
      project: 2,
      product: 2,
      deliverable: 3,
      release: 4,
      bug: 5,
      task: 6,
    };

    Object.keys(groupedItemsByType)
      .sort((a, b) => blockItemTypePosition[a] - blockItemTypePosition[b])
      .forEach((type) => {
        const isExpanded = expandedGroups[type];
        const items = groupedItemsByType[type];
        const displayedItems = isExpanded ? items : items.slice(0, 5);

        displayedItems.forEach((item) => {
          options.push({ item, type, display: true, total: items.length });
        });

        if (!isExpanded && items.length > 5) {
          options.push({
            type,
            display: false,
            total: items.length,
          } as SearchMenuOption);
        }
      });

    return options;
  }, [groupedItemsByType, expandedGroups]);

  return {
    handleSearch,
    options: preparedOptions,
    expandedGroups,
    setExpandedGroups,
  };
};
