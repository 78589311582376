import styled from "styled-components";

export const ProgressBarRoot = styled.div`
  border-radius: ${({ theme }) => theme.spacing(3)};
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
`;

export const Percentage = styled.div<{ value: number; color: string }>`
  width: ${({ value }) => `${value}%`};
  float: left;
  background-color: ${({ color }) => color};
  height: 100%;
`;

export const EmptyPercentage = styled.div`
  width: 100%;
  float: left;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  height: 100%;
`;

export const Divider = styled.div`
  width: ${({ theme }) => theme.spacing(0.2)};
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
`;

export const ProgressBarLegendColor = styled.div<{ color: string }>`
  width: 14px;
  height: 14px;
  background-color: ${({ color }) => color};
  border-radius: ${({ theme }) => theme.spacing(1)};
`;

export const ProgressBarLegendText = styled.div`
  font-family: ${({ theme }) => theme.typography.body2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body2.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body2.lineHeight} !important;
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight} !important;
  height: ${({ theme }) =>
    theme.typography.body2.height || "normal"} !important;
  line-height: 200%;
`;

export const TooltipContentRoot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const ItemHeaderProgressBar = styled.div`
  display: flex;
  width: 23%;
  height: 10px;
  justify-content: flex-end;
  align-items: center;
`;
