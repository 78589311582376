import { Grid } from "@material-ui/core";
import { WarningGrid, TitleTypography } from "./styles";
import CustomDialog from "src/v3/components/Dialogs/Base";
import { Trash, WarningTriangle } from "src/v3/theme/icons/general";
import theme from "src/v3/theme/theme";
import IconComponent from "src/v3/components/Icons/IconComponent";
import { useUserPreferenceContext } from "src/v2/providers/userPreference/UserPreferenceContext";
import { useState } from "react";

export const DeleteFilterModal = ({ filter }: any) => {
  const { deleteUserFilter } = useUserPreferenceContext();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen((prev) => !prev);
  };

  const handleSave = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    await deleteUserFilter(filter.id);
    setOpen((prev) => !prev);
  };

  return (
    <>
      <IconComponent
        Icon={Trash}
        onClick={handleOpen}
        stroke={theme.palette.grey[400]}
        size={18}
      />
      <CustomDialog
        open={open}
        onSave={handleSave}
        onClose={handleClose}
        title="Delete saved filter"
        enableSaveButton={true}
        isSaving={false}
        saveButtonText="Delete"
        customStyle={{
          minHeight: "70px",
          height: "70px",
        }}
      >
        <Grid container direction="column">
          <WarningGrid container>
            <IconComponent
              Icon={WarningTriangle}
              size={42}
              stroke={theme.palette.warning.main}
            />
            <TitleTypography>
              Are you sure you want to delete this filter?
            </TitleTypography>
          </WarningGrid>
        </Grid>
      </CustomDialog>
    </>
  );
};
