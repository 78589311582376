import styled from "styled-components";

export const AccordionItemsPadding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(3, 0, 0, 8)};
  align-self: stretch;
  width: 100%;
`;
