import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { CustomFieldBase } from "src/v2/domain/entities/customField/CustomFieldBase";
import { ActionFactory } from "src/v3/components/ActionMenu/Actions/Factory";
import { ActionType } from "src/v3/components/ActionMenu/Actions/Factory/types";

export class SummaryOfWorkField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.aiSummary = field.value || "";
  }

  private aiSummary: string;

  getAiSummary() {
    return this.aiSummary;
  }

  hasOverride(): boolean {
    const override = this.getLastAddedOverride();
    return override?.value?.context && override?.value?.context !== "";
  }

  getEditSummaryOfWorkAction = (item: BlockItem) =>
    ActionFactory(ActionType.EditSummaryOfWork, { item });

  getDetailViewEditSummaryOfWorkAction = (item: BlockItem) =>
    ActionFactory(ActionType.EditSummaryOfWorkDetailsView, { item });

  getComponentForGrid() {
    return <div dangerouslySetInnerHTML={{ __html: this.aiSummary }} />;
  }
}
