import { BlockItem } from "src/v2/domain/entities/block/BlockItem";

export function findItemByKey(
  items: BlockItem[],
  key: string
): BlockItem | null {
  for (const item of items) {
    if (item.getKey() === key) return item;
    if (item.hasChildren()) {
      const foundItem = findItemByKey(item.getChildren(), key);
      if (foundItem) return foundItem;
    }
  }

  return null;
}

export function groupItemsByType(
  items: BlockItem[]
): Record<string, BlockItem[]> {
  const group: Record<string, BlockItem[]> = {};

  items.forEach((item: BlockItem) => {
    let type = item.getBlockType();
    if (!type) return;
    if (item.isBug()) {
      type = "bug";
    }
    if (!group[type]) {
      group[type] = [];
    }
    group[type].push(item);
  });

  return group;
}
