import { CustomFieldBase } from "src/v2/domain/entities/customField/CustomFieldBase";
import { AllocationDetails, AllocationDetailsValue } from "./AllocationDetails";

interface AllocationFieldValue {
  allocation: number;
  unique_assignees: string[];
  allocation_details: AllocationDetailsValue;
}

export class AllocationField extends CustomFieldBase {
  allocation: number;
  uniqueAssignees: number;
  uniqueAssigneesList: string[];
  hasNoAllocation: boolean;
  bandwidth: number;
  allocationDetails: AllocationDetails;

  constructor(field) {
    super(field);
    const {
      allocation = 0,
      unique_assignees = [],
      allocation_details,
    }: AllocationFieldValue = field.value ?? {};

    this.value = field.value;
    this.allocation = this.calculateAllocation(allocation);
    this.uniqueAssignees = unique_assignees.length;
    this.uniqueAssigneesList = unique_assignees;
    this.hasNoAllocation = this.hasNoAllocationValue();
    this.allocationDetails = new AllocationDetails(allocation_details);
  }

  getAllocationDetails() {
    return this.allocationDetails;
  }

  private hasNoAllocationValue() {
    return this.uniqueAssignees === 0 && this.allocation === 0;
  }

  private calculateAllocation(allocation: number): number {
    return Math.round(allocation * 40 * 10) / 10;
  }

  getAllocation(): number {
    return this.allocation;
  }

  getValue() {
    return this.value;
  }

  setBandwidth(bandwidth: number): void {
    this.bandwidth = bandwidth;
    this.value["bandwidth"] = bandwidth;
  }

  getBandwidth(): number {
    return this.bandwidth;
  }
}
