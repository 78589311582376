import { Paper } from "@material-ui/core";
import styled from "styled-components";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import theme from "src/v3/theme/theme";

export const useStyles = makeStyles({
  listbox: {
    maxHeight: "500px",
    overflowY: "auto",
  },
  notchedOutline: {
    borderColor: `${theme.palette.grey[400]} !important`,
  },
});

export const StyledPaper = styled(Paper)`
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.16) !important;
  border-radius: 8px !important;
  background-color: #fff !important;
  box-sizing: border-box !important;
`;

export const GroupHeader = styled.div`
  position: relative;
  font-size: 12px;
  font-weight: 600;
  font-family: Inter;
  color: #636467;
  text-align: left;
  display: inline-block;
  padding: 8px 16px;
`;

export const SearchField = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 200px;
  }
`;

export const SearchInputContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const SearchContainer = styled.div`
  align-self: stretch;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #929497;
  box-sizing: border-box;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  gap: 8px;
  width: 100%;
`;

export const SearchIcon = styled.div`
  margin-left: 8px;
  display: flex;
`;

export const SearchInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
`;

export const SearchItem = styled.div`
  width: 100%;
  position: relative;
  font-size: 12px;
  display: inline-block;
  font-family: Inter;
  color: #125684;
  text-align: left;
`;

export const ItemIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const XMoreResults = styled.div`
  width: 100%;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  font-family: Inter;
  color: ${({ theme }) => theme.palette.primary.main};
  text-align: left;
  display: inline-block;
  padding: 8px 0;
`;

export const OptionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
