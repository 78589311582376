import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { HierarchyStructure } from "./types";
import { useGenericView } from "../GenericView/useGenericView";
import { WidgetConfigType } from "src/v3/entities/WidgetConfig";

export const useHierarchy = () => {
  const history = useHistory();
  const location = useLocation();
  const { orderedWidgets } = useGenericView();

  const [currentHierarchy, setCurrentHierarchy] =
    useState<HierarchyStructure>(null);
  const [hierarchs, setHierarchs] = useState<HierarchyStructure[]>([]);

  useEffect(() => {
    if (!orderedWidgets.length) return;

    // Get all hierarchy structure widget configs from the view widgets
    const hierarchs = orderedWidgets
      .map(
        ({ configs }): HierarchyStructure =>
          configs
            .filter(({ enabled }) => enabled)
            .filter(({ type }) => type === WidgetConfigType.hierarchyStructure)
            .map(({ value }) => value)
      )
      .flat();

    // If no hierarchy structure widget is found, it means the view is not configured correctly
    if (!hierarchs?.length) {
      throw new Error("No hierarchy structure widget found");
    }

    setHierarchs(hierarchs);
    setCurrentHierarchy(hierarchs[0]);
  }, [orderedWidgets]);

  useEffect(() => {
    // If there is no hash in the URL, set the default hash
    if (!hierarchs.length || !currentHierarchy) return;
    if (!location.hash) {
      const search = location.search;
      history.replace(
        `${location.pathname}${search}#${encodeURIComponent(currentHierarchy.hash)}`
      );
    } else {
      // If there's a hash, decode and use it as the initial state if it's valid
      const decodedHash = decodeURIComponent(location.hash.substring(1));
      const newHierarch = hierarchs.find(({ hash }) => hash === decodedHash);
      if (!newHierarch) return;
      setCurrentHierarchy(newHierarch);
    }
  }, [
    location.hash,
    hierarchs,
    history,
    location.pathname,
    location.search,
    currentHierarchy,
  ]);

  const handleHierarchyChange = (hierarchy: HierarchyStructure) => {
    setCurrentHierarchy(hierarchy);

    const search = location.search;
    history.replace(
      `${location.pathname}${search}#${encodeURIComponent(hierarchy.hash)}`
    );
  };

  return {
    hierarchs,
    currentHierarchy,
    handleHierarchyChange,
  };
};
