import React, { useEffect, useState } from "react";
import { PageSubtitle } from "src/components/GlobalComponents";
import { authenticate, createSession } from "src/services/sessionService";
import setErrorSnackbar from "src/services/setErrorSnackbar";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import BaseButton from "src/components/Buttons/BaseButton";
import { loginErrorBackground } from "src/v2/helpers/colors";
import { ReactComponent as VelmaIcon } from "src/assets/icons/velma.svg";
import styled from "styled-components";
import theme from "src/v3/theme/theme";

const useStyles = makeStyles({
  errorBackground: {
    backgroundColor: loginErrorBackground,
    fontSize: "1.1rem",
    margin: "auto",
    marginTop: "2rem",
    width: "60%",
    padding: "0.6rem",
    borderRadius: "8px",
    textAlign: "center",
  },
});

export const CompanyTitle = styled.p`
  font-family: "PTSerif-Bold";
  font-size: 64px;
  display: flex;
  align-self: flex-end;
  margin-bottom: -14px;
`;

const loginErrorMessageKey = "login_error_message";

interface LoginProps {
  redirectURL;
}

export const Login: React.FC<LoginProps> = ({ redirectURL }) => {
  const classes = useStyles();
  const [redirectURLToUse, setRedirectURLToUse] = useState<string>("");
  useEffect(() => {
    //Here we check if the redirectURL is already in local storage as long as it isn't the root or login. If it is, we use that. If not, we set it.
    //This is to prevent the redirectURL from being overwritten when the user hasn't verified their email yet.
    let storageCheck = localStorage.getItem("redirectUrl");
    // Remove admin query params from redirect URL
    try {
      const url = new URL(storageCheck);
      url.searchParams.delete("admin");
      storageCheck = url.toString();
    } catch (error) {
      console.warn("Error removing admin query param from redirect URL", error);
    }
    if (
      !storageCheck ||
      storageCheck === window.location.origin + "/" ||
      storageCheck === window.location.href + "/login"
    ) {
      const cleanURL = new URL(redirectURL);
      cleanURL.searchParams.delete("admin");
      const redirectURLClean = cleanURL.toString();
      localStorage.setItem("redirectUrl", redirectURLClean);
      setRedirectURLToUse(redirectURLClean);
    } else {
      setRedirectURLToUse(storageCheck);
    }
  }, []);
  const {
    getIdTokenClaims,
    loginWithPopup,
    logout,
    getAccessTokenSilently,
    error,
  } = useAuth0();

  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (error && error.message.toLowerCase().includes("email_verification")) {
      const formattedMessage = error.message.split("]")[1].trim();
      localStorage.setItem(loginErrorMessageKey, formattedMessage);
    }
  }, [error]);

  useEffect(() => {
    const storedErrorMessage = localStorage.getItem(
      loginErrorMessageKey
    ) as string;
    if (storedErrorMessage) {
      setErrorMessage(storedErrorMessage);
      localStorage.removeItem(loginErrorMessageKey);
    }
  }, []);

  function alertError() {
    setErrorSnackbar(
      "There was a problem signing in. Check that you're not using an incognito browser. If you continue to see this error, please contact support@heyvelma.com."
    );
  }

  async function doLogin(redirectUrl) {
    await loginWithPopup();
    try {
      const claims = await getIdTokenClaims();
      if (claims) {
        await authenticate(claims);
        const accessToken = await getAccessTokenSilently();
        await createSession(accessToken);
        localStorage.setItem("last_session_date", JSON.stringify(Date.now()));
        const currentURL = window.location.href;
        const userCameFromOrigin = redirectURLToUse === currentURL;
        window.location.replace(
          userCameFromOrigin ? window.location.origin : redirectURLToUse
        );
        //deletes the redirectURL from local storage so new ones can work in the future
        localStorage.removeItem("redirectUrl");
      } else {
        logout({ returnTo: window.location.origin });
      }
    } catch {
      logout({ returnTo: window.location.origin });
      alertError();
    }
  }

  return (
    <Grid
      container
      direction="column"
      style={{
        width: "100%",
        height: "100%",
        padding: 16,
      }}
      justifyContent="center"
      alignContent="center"
      alignItems="center"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        style={{
          marginBottom: 16,
          padding: 16,
          gap: 16,
        }}
      >
        <VelmaIcon
          style={{
            animation: "$pulse 2s infinite",
          }}
        />
        <CompanyTitle
          style={{
            color: theme.palette.primary.main,
          }}
        >
          Velma
        </CompanyTitle>
      </Grid>
      <PageSubtitle>Get the delivery updates you care about</PageSubtitle>
      <p style={{ marginBottom: "2em", fontSize: "1.2em" }}>
        This website requires cookies. Make sure you are not using incognito
        mode and you have cookies enabled.
      </p>
      <BaseButton
        style={{ width: 200, height: 42 }}
        data-test-id="login_button"
        onClick={() => doLogin(redirectURL)}
      >
        Log in
      </BaseButton>
      {errorMessage && (
        <Box className={classes.errorBackground}>{errorMessage}</Box>
      )}
    </Grid>
  );
};
