import { styled } from "@material-ui/core/styles";
import { FormControl, MenuItem, Select } from "@material-ui/core";

// Styled components using Material-UI's styled
export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: "120px",
}));

export const ExpirationSelector = styled(Select)(({ theme }) => ({
  height: "36px",
  color: theme.palette.grey[900],
}));

export const JustificationFormControl = styled(FormControl)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  color: theme.palette.grey[900],
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: `8px 12px`,
}));

export const StyledMenuPaper = styled("div")(({ theme }) => ({
  borderRadius: "8px",
  boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.16)",
  backgroundColor: theme.palette.background.paper,
  marginTop: theme.spacing(1),
  color: theme.palette.grey[900],
}));

// Replace the useStyles function with styled components
export const SelectorMenu = styled("div")(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  marginTop: theme.spacing(1),
  color: theme.palette.grey[900],
  borderColor: theme.palette.grey[900],
}));

export const Selector = styled("div")(({ theme }) => ({
  borderRadius: theme.spacing(1),
  color: theme.palette.grey[900],
  width: "110px",
}));
