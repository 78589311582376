import styled from "styled-components";

export const CentralizeBox = styled.div`
  width: 80%;
  margin: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 90%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;
