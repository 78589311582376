export interface AllocationDetailsValue {
  allocations?: Record<string, { allocation: number; moving_average: number }>;
  significant_work?: {
    start_date: string;
    end_date: string;
    allocations: Record<string, number>;
  };
}

export class AllocationDetails {
  allocations: Record<string, { allocation: number; movingAverage: number }>;
  significantWork: {
    startDate: string;
    endDate: string;
    allocations: Record<string, number>;
  };

  constructor(allocationDetailsValue: AllocationDetailsValue | null = null) {
    const safeAllocationDetailsValue: AllocationDetailsValue =
      allocationDetailsValue ?? {
        significant_work: {
          start_date: "",
          end_date: "",
          allocations: {},
        },
        allocations: {},
      };

    const { allocations, significant_work } = safeAllocationDetailsValue;

    this.allocations = Object.fromEntries(
      Object.entries(allocations ?? {}).map(([date, details]) => [
        date,
        {
          allocation: details.allocation,
          movingAverage: details.moving_average,
        },
      ])
    );

    this.significantWork = {
      startDate: significant_work?.start_date ?? "",
      endDate: significant_work?.end_date ?? "",
      allocations: significant_work?.allocations ?? {},
    };
  }
}
