import { FC, useEffect, useState } from "react";
import { Tr, Td } from "react-super-responsive-table";
import { FieldConfigType } from "src/v2/domain/enum/FieldConfigType";
import { insightsBackground } from "src/v2/helpers/colors";
import { useSummaryContext } from "src/v2/providers/summary/SummaryContext";
import { useFieldsContext } from "src/v2/providers/fields/FieldsContext";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";
import { StatusField } from "src/v2/domain/entities/customField/StatusField";
import { useWidgetContext } from "src/v2/components/Widget/WidgetContext";
import { useClientConfigContext } from "src/providers/ClientConfigContext";
import useVisibility from "src/v2/hooks/useVisibility";

import "./TableRow.css";
import useClientStatusConfigContext from "src/providers/v2/Business/ClientStatusConfigContext";
import { CustomFieldBase } from "src/v2/domain/entities/customField/CustomFieldBase";
import { SummaryTable } from "./SummaryTable";
import {
  getPaddingLeftForLevel,
  getCurrentRowBackgroundColor,
  eachLevelTablePaddingLeft,
} from "./tableLayout.utils";
import { SecondLevelTable } from "./SecondLevelTable";
import { DisplayConfigSection } from "src/v2/domain/enum/DisplayConfigSection";
import { Grid, Typography } from "@material-ui/core";
import BaseButton from "src/components/Buttons/BaseButton";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { EventTypes, useEventListener } from "src/providers/EventContext";

interface TableRowProps {
  item;
}

export const TableRow: FC<TableRowProps> = (props) => {
  const { item } = props;
  const { statuses } = useClientStatusConfigContext();
  const { expandAllItems, getFirstColumnWidth } = useSummaryContext();
  const { getConfigValueByName, _section } = useWidgetContext();
  const {
    viewExpandAllExclusions,
    showInsights,
    redirectViewSlug,
    epicInfos,
    viewSchema,
  } = useGenericViewContext();

  const { daysToShowRecentlyChildrenAddedFlag } = useClientConfigContext();

  const [openTeamSummaryModal, setOpenTeamSummaryModal] = useState(false);
  const [expandAISummary, setExpandAISummary] = useState(false);

  const [fieldsState, _setFieldsState] = useState({
    expandChildren: false,
    expandDetails: false,
    statusCategory: null,
    currentLevel: item.type.value,
    getConfigValueByName,
    statuses: statuses,
    //  sectionRedirectViewSlug and genericViewRedirectViewSlug is basic the same thing,
    //  but sectionRedirectViewSlug is used for redirecting to a view that is defined in the section options
    //  and genericViewRedirectViewSlug is used for redirecting to a view that is defined in the generic view options
    sectionRedirectViewSlug: "",
    genericViewRedirectViewSlug: "",
    clientConfigs: {},
    epicInfos,
    viewSchema,
  });
  const setFieldsState = (newState) => {
    if (typeof newState === "function") {
      _setFieldsState(newState);
      return;
    }
    _setFieldsState((oldState) => {
      return { ...oldState, ...newState };
    });
  };
  const [isVisible, currentElement] = useVisibility(2 * window.innerHeight);
  const [tdHeight, setTdHeight] = useState();
  const { isFieldInVelmaInsights, getFieldStyles, getGridFieldsByItemTarget } =
    useFieldsContext();
  const isProjectLevel = item.isProject();

  const [expandDetails, setExpandDetails] = useState<boolean>(expandAllItems);
  const [typesToHideChildrenTable, setTypesToHideChildrenTable] = useState<
    string[]
  >([]);

  const itemType = item.type.value;
  const currentPaddingLeft = getPaddingLeftForLevel(1);
  const firstColumnWidth = getFirstColumnWidth(itemType);
  const rowBackgroundColor = getCurrentRowBackgroundColor(itemType);

  const [showChildren, setShowChildren] = useState(false);

  const shouldShowChildrenTable =
    item.hasChildren() &&
    !viewExpandAllExclusions?.includes(itemType.toLocaleLowerCase()) &&
    !typesToHideChildrenTable.includes(itemType.toLocaleLowerCase());

  const itemStatus = item.getFieldByType(FieldConfigType.status) as StatusField;
  const itemStatusCategory = itemStatus?.getStatusCategory();

  const gridFields = getGridFieldsByItemTarget({
    target: item.type.value,
  });

  function filterByLevel(level: number) {
    return gridFields
      .filter((field: CustomFieldBase) =>
        field.displayConfig
          .filter(({ section }) => section === DisplayConfigSection.grid)
          .find(({ config }) => config.level === level)
      )
      .map(({ fieldKey }) =>
        item.customFields?.find(
          (field) => field.fieldKey.toLowerCase() === fieldKey.toLowerCase()
        )
      )
      .filter((field) => field);
  }
  const firstLevelFields = filterByLevel(1);
  const secondLevelFields = filterByLevel(2);

  const showSecondLevelTable = secondLevelFields.length > 0;

  useEffect(() => {
    const typesToHideChildrenTable = _section?.options?.find(
      (option) => option.name === "types_to_hide_children_table"
    );

    if (typesToHideChildrenTable) {
      setTypesToHideChildrenTable(typesToHideChildrenTable.value);
    }

    const sectionRedirectViewSlug = _section?.options?.find(
      (option) => option.name === "redirect_view"
    )?.value;

    if (sectionRedirectViewSlug) {
      setFieldsState((oldState) => {
        return { ...oldState, sectionRedirectViewSlug };
      });
    }
  }, [_section]);

  useEffect(() => {
    if (showInsights) {
      setFieldsState((state) => {
        return { ...state, epicInfos };
      });
    }
  }, [epicInfos]);

  useEffect(() => {
    if (!redirectViewSlug) {
      return;
    }
    setFieldsState((oldState) => {
      return { ...oldState, genericViewRedirectViewSlug: redirectViewSlug };
    });
  }, [redirectViewSlug]);

  useEffect(() => {
    setExpandDetails(fieldsState.expandDetails);
  }, [fieldsState]);

  useEffect(() => {
    if (
      currentElement?.current?.clientHeight &&
      tdHeight &&
      currentElement?.current?.parentNode > tdHeight
    ) {
      setTdHeight(currentElement?.current?.parentNode?.offsetHeight);
    }
  }, [
    currentElement.current,
    currentElement?.current?.parentNode?.offsetHeight,
    showInsights,
  ]);

  useEffect(() => {
    if (
      !viewExpandAllExclusions?.includes(item.type.value.toLocaleLowerCase())
    ) {
      setExpandDetails(expandAllItems);
    }
  }, [expandAllItems]);

  const getIsExpandable = () => {
    if (
      viewExpandAllExclusions?.includes(item.type.value.toLocaleLowerCase())
    ) {
      return false;
    }

    if (showSecondLevelTable) {
      return true;
    }
    if (shouldShowChildrenTable) {
      return true;
    }
    return false;
  };

  const isExpandable = getIsExpandable();

  const handleExpandDetails = () => {
    const newExpand = !expandDetails;
    setExpandDetails(newExpand);
    setFieldsState({ expandDetails: newExpand });
  };

  useEffect(() => {
    if (viewSchema) {
      setFieldsState((oldState) => {
        return { ...oldState, viewSchema };
      });
    }
  }, [viewSchema]);

  useEffect(() => {
    if (daysToShowRecentlyChildrenAddedFlag) {
      setFieldsState({
        clientConfigs: {
          daysToShowRecentlyChildrenAddedFlag,
        },
      });
    }
  }, [daysToShowRecentlyChildrenAddedFlag]);

  const ExpandChildrenIcon = showChildren ? (
    <ExpandLess style={{ width: 16, height: 16, color: "white" }} />
  ) : (
    <ExpandMore style={{ width: 16, height: 16, color: "white" }} />
  );

  const getChildrenTypeBasedOnCurrentItemLevel = () => {
    const mapChildrenType = {
      project: "Epics",
      deliverable: "issues",
      task: "Sub-tasks",
    };
    return mapChildrenType[item.type.value] || "issues";
  };
  const updateColumn = useEventListener(
    EventTypes.PROJECT_STATUS_OVERRIDE,
    "TableRow"
  );

  const aiSummaryOverride = useEventListener(
    EventTypes.AI_SUMMARY_OVERRIDE,
    "TableRow"
  );

  const riskDeletedOverride = useEventListener(
    EventTypes.RISK_DELETED,
    "TableRow"
  );

  const riskAddedOverride = useEventListener(EventTypes.RISK_ADDED, "TableRow");

  const gridProps = {
    currentPaddingLeft,
    setFieldsState,
    item,
    updateColumn,
    aiSummaryOverride,
    riskDeletedOverride,
    riskAddedOverride,
    setShowChildren,
    showChildren,
    aiSummaryExpands: {
      openTeamSummaryModal,
      setOpenTeamSummaryModal,
      setExpandAISummary,
      expandAISummary,
    },
    fieldsState: {
      ...fieldsState,
      statusCategory: itemStatusCategory,
    },
  };

  return (
    <>
      <Tr
        data-test-id={isProjectLevel && "project_row"}
        id={item.getKey()}
        onClick={() => {
          handleExpandDetails();
        }}
        className="fade-in"
        style={{
          background: `${rowBackgroundColor}`,
          boxShadow: "0px 0px 30px 4px #0000000D",
          cursor: isExpandable ? "pointer" : "default",
        }}
      >
        {firstLevelFields?.map((field: CustomFieldBase, index) => (
          <Td
            style={{
              width: field?.isNameField() ? firstColumnWidth : "auto",
              backgroundColor:
                isFieldInVelmaInsights(field) && insightsBackground,
              display: field?.isNameField() ? "flex" : "",
              ...getFieldStyles({ field, fieldsState }),
            }}
            key={`${field?.type}+${index}`}
            data-test-id={
              field?.type === FieldConfigType.expandable_text &&
              isProjectLevel &&
              "project_expandable_column"
            }
          >
            {field?.isNameField() ? (
              <div ref={currentElement}>
                {field.getFirstColumnComponent({
                  props: {
                    item,
                    expanded: expandDetails,
                    handleExpand: handleExpandDetails,
                    isExpandable,
                    firstColumnWidth,
                    ...fieldsState,
                  },
                })}
              </div>
            ) : (
              <div
                style={{
                  ...getFieldStyles({ field, fieldsState }),
                  height:
                    showInsights && field?.type !== "changelog"
                      ? tdHeight
                      : "auto",
                  padding: "15px",
                  display: isVisible ? "block" : "none",
                }}
                ref={currentElement}
                data-test-id={
                  field?.type === FieldConfigType.expandable_text &&
                  isProjectLevel &&
                  "project_expandable_content"
                }
              >
                {field?.getComponentForGrid({
                  ...gridProps,
                })}
              </div>
            )}
          </Td>
        ))}
      </Tr>
      {expandDetails && isExpandable && (
        <Td colspan={firstLevelFields.length}>
          {showSecondLevelTable ? (
            <Grid
              container
              justifyContent="flex-start"
              alignContent="center"
              alignItems="center"
            >
              <SecondLevelTable
                item={item}
                columns={secondLevelFields}
                gridProps={{
                  ...gridProps,
                }}
              />
              {item.hasChildren() && (
                <BaseButton
                  onClick={() => setShowChildren(!showChildren)}
                  style={{
                    marginBottom: 16,
                    marginTop: -8,
                  }}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ width: "100px" }}
                  >
                    {ExpandChildrenIcon}
                    <Typography
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {showChildren ? "Hide" : "Show"}{" "}
                      {getChildrenTypeBasedOnCurrentItemLevel()}
                    </Typography>
                  </Grid>
                </BaseButton>
              )}
              {showChildren && (
                <SummaryTable
                  items={item.getChildren()}
                  customStyles={{
                    paddingLeft: eachLevelTablePaddingLeft,
                  }}
                />
              )}
            </Grid>
          ) : shouldShowChildrenTable ? (
            <SummaryTable
              items={item.getChildren()}
              customStyles={{
                paddingLeft: eachLevelTablePaddingLeft,
              }}
            />
          ) : (
            <div></div>
          )}
        </Td>
      )}
    </>
  );
};
