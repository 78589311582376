import styled from "styled-components";

export const Details = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)};
`;

// CustomAccordionRoot to match the Material UI Accordion
export const CustomAccordionRoot = styled.div`
  margin: 0;
  border-radius: ${({ theme }) => theme.spacing(2)}px;
  &.Mui-expanded {
    margin: 0;
  }

  &:before {
    content: none;
  }
`;

// CustomAccordionSummary to match the Material UI AccordionSummary
export const CustomAccordionSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2.5)};
  border-radius: ${({ theme }) => theme.spacing(2.5)};
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
`;

export const ExpandIcon = styled.div`
  display: flex;
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

export const NoExpandIcon = styled.div`
  padding: ${({ theme }) => theme.spacing(3)};
`;

// CustomAccordionDetails to match the Material UI AccordionDetails
export const CustomAccordionDetails = styled.div`
  padding: 0px 7px 8px 8px;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  width: 100%;
`;
