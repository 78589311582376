import { CustomMenu } from "src/v3/components/commomStyles/Menu";
import IconComponent from "src/v3/components/Icons/IconComponent";
import {
  ChevronDown,
  HierarchyStructureDivider,
} from "src/v3/theme/icons/general";
import { useHierarchy } from "./useHierarchy";
import {
  Dropdown,
  Field,
  Hierarchy,
  HierarchyOptionContainer,
  StructureName,
  OptionName,
  Select,
  Top,
  LevelContainer,
  DividerLine,
  LevelBox,
  HierarchyContainer,
} from "./styles";
import { Grid } from "@material-ui/core";
import { HierarchyStructure } from "./types";

export const HierarchyMenu = () => {
  const { hierarchs, currentHierarchy, handleHierarchyChange } = useHierarchy();

  if (!currentHierarchy || !hierarchs?.length || hierarchs?.length <= 1) {
    return null;
  }

  return (
    <HierarchyContainer>
      <Hierarchy>Hierarchy</Hierarchy>
      <CustomMenu
        direction="row"
        actions={hierarchs.map((hierarch: HierarchyStructure) => (
          <HierarchyMenuOption
            onClick={() => handleHierarchyChange(hierarch)}
            selected={currentHierarchy.hash === hierarch.hash}
            hierarchy={hierarch}
          />
        ))}
        TriggerComponent={
          <Dropdown>
            <Top>
              <Field>
                <Select>{currentHierarchy.name}</Select>
                <IconComponent Icon={ChevronDown} />
              </Field>
            </Top>
          </Dropdown>
        }
      />
    </HierarchyContainer>
  );
};

const HierarchyMenuOption = ({ hierarchy, onClick, selected }) => {
  const { levels, name } = hierarchy;
  return (
    <HierarchyOptionContainer onClick={onClick} selected={selected}>
      <OptionName>{name}</OptionName>
      <Grid container direction="column">
        {levels.map((level, index) => (
          <LevelContainer key={index} levelIndex={index}>
            {index > 0 && (
              <DividerLine levelIndex={index}>
                <IconComponent Icon={HierarchyStructureDivider} />
              </DividerLine>
            )}
            <LevelBox>
              <StructureName>{level}</StructureName>
            </LevelBox>
          </LevelContainer>
        ))}
      </Grid>
    </HierarchyOptionContainer>
  );
};
