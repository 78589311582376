import { Typography } from "@material-ui/core";
import { EditSummaryOfWorkActionV2Props } from "./types";
import { useState } from "react";
import ReactQuill from "react-quill";
import { SummaryOfWorkEditProps } from "src/v3/components/Dialogs/SummaryOfWorkEdit/types";
import { useSummaryOfWorkEdit } from "src/v3/components/Dialogs/SummaryOfWorkEdit/useSummaryOfWorkEdit";
import CustomDialogV2 from "../Dialog";
import { LastUpdatedAuthor } from "src/v3/components/commomStyles/LastUpdatedAuthor";

export const EditSummaryOfWorkActionV2 = ({
  item,
  label = "additional details",
}: EditSummaryOfWorkActionV2Props) => {
  const [open, setOpen] = useState(false);
  const field = item.getAISummaryField();
  const editedExtraContext = field?.hasOverride();
  const _label = editedExtraContext ? `Edit ${label}` : `Add ${label}`;
  const handleOpen = () => setOpen((prev) => !prev);

  return (
    <>
      <Typography
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleOpen();
        }}
        style={{
          color: `#1A7BBD`,
          textAlign: "center",
          marginTop: 8,
          marginBottom: 8,
          textUnderlineOffset: 4,
          textDecoration: "underline",
          fontWeight: 600,
          fontSize: "15px",
          cursor: "pointer",
          lineHeight: "16px",
          font: "inherit",
          display: "flex",
        }}
      >
        {_label}
      </Typography>
      <SummaryOfWorkEdit item={item} open={open} handleOpen={handleOpen} />
    </>
  );
};

const SummaryOfWorkEdit = ({
  item,
  open,
  handleOpen,
}: SummaryOfWorkEditProps) => {
  const {
    extraContext,
    enableSaveButton,
    isSaving,
    handleUpdateExtraContext,
    resetState,
    onSave,
  } = useSummaryOfWorkEdit(item);

  // TODO: after deprecate the v2, remove getAISummaryField
  const summaryOfWorkField =
    item.getSummaryOfWorkField() || item.getAISummaryField();
  const override = summaryOfWorkField.getLastAddedOverride()?.value;

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    resetState();
    handleOpen();
  };

  const handleSave = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    await onSave();
    handleOpen();
  };

  return (
    <CustomDialogV2
      open={open}
      isSaving={isSaving}
      onClose={handleClose}
      onSave={handleSave}
      title="Summary of Work - Additional Details"
      enableSaveButton={enableSaveButton}
    >
      {summaryOfWorkField.hasOverride() && (
        <LastUpdatedAuthor
          author={override?.author?.name}
          lastUpdatedDate={override.created_at}
        />
      )}
      <ReactQuill
        style={{ height: "228px" }}
        theme="snow"
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            ["blockquote"],
            ["link"],
            ["clean"],
          ],
          clipboard: {
            matchVisual: false,
          },
        }}
        value={extraContext || ""}
        onChange={handleUpdateExtraContext}
        placeholder={"Enter additional details here..."}
      />
    </CustomDialogV2>
  );
};
