import styled from "styled-components";

export const TopBarContainer = styled.div<{ topBarHeight: string }>`
  display: flex;
  height: ${({ topBarHeight }) => `${topBarHeight}px`};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  z-index: 1000;
  position: fixed;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 0 ${({ theme }) => theme.spacing(2)};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding: 0 ${({ theme }) => theme.spacing(1)};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(10)};
    gap: ${({ theme }) => theme.spacing(2)};
  }
`;

export const LeftAreaContainer = styled.div`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 16px;
  gap: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    justify-content: center;
    width: 100%;
  }
`;

export const NavBarButton = styled.div`
  width: 42px;
  border-radius: 4px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const MenuIcon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
`;

export const LogoContainer = styled.div`
  width: 46px;
  position: relative;
  height: 46px;
`;

export const LogoImage = styled.img`
  position: absolute;
  height: 95.65%;
  width: 78.7%;
  top: 2.17%;
  right: 10.43%;
  bottom: 2.17%;
  left: 10.87%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
`;

export const MiddleAreaContainer = styled.div`
  align-self: stretch;
  flex: 1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 100%;
    justify-content: center;
  }
`;

export const RightAreaContainer = styled.div`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 16px 0px 40px;
  gap: 16px;
  text-align: right;
  font-size: 14px;
  color: #000a11;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    flex-direction: column;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(2)};
    gap: ${({ theme }) => theme.spacing(2)};
    width: 100%;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

export const LastUpdatedText = styled.div`
  position: relative;
  font-size: 12px;
  color: #000a11;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    text-align: center;
  }
`;
