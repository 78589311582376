import { EditProjectStatusActionV2Props } from "./types";
import React from "react";
import { EditProjectStatusDialogV2 } from "./ProjectStatusEdit";
import { Typography } from "@material-ui/core";

export const EditProjectStatusActionV2 = ({
  item,
  label = "Edit status",
}: EditProjectStatusActionV2Props) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen((prev) => !prev);

  return (
    <>
      <div
        style={{
          color: "08090C",
          cursor: "pointer",
          width: "100%",
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(true);
        }}
      >
        <Typography
          style={{
            color: `#1A7BBD`,
            textAlign: "center",
            marginTop: 8,
            marginBottom: 8,
            textUnderlineOffset: 4,
            textDecoration: "underline",
            fontWeight: 600,
            fontSize: "15px",
            cursor: "pointer",
            lineHeight: "16px",
            font: "inherit",
          }}
        >
          {label}
        </Typography>
      </div>
      <EditProjectStatusDialogV2
        item={item}
        open={open}
        handleOpen={handleOpen}
      />
    </>
  );
};
