import { Snackbar, Typography } from "@material-ui/core";
import {
  EndMessage,
  LoadingWidget,
  MainText,
  TipText,
  UnderMaintenanceRoot,
} from "./styles";
import { Alert } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useClientConfigContext } from "src/providers/ClientConfigContext";

export const UnderMaintenanceView = () => {
  return (
    <UnderMaintenanceRoot data-test-id={`generic-view-root-under-maintenance`}>
      <LoadingWidget>
        <Typography variant="h1">🚧</Typography>
        <MainText variant="h4">Page Under Maintenance</MainText>
        <TipText variant="body1">
          We are currently performing scheduled maintenance to improve your
          experience. <br />
          We apologize for any inconvenience this may cause.
        </TipText>
        <EndMessage variant="body1">
          Thank you for your patience and understanding. We look forward to
          welcoming you back soon!
        </EndMessage>
      </LoadingWidget>
    </UnderMaintenanceRoot>
  );
};

export const UnderMaintenanceBanner = () => {
  const { underMaintenanceBanner } = useClientConfigContext();

  const [showMaintenanceBanner, setShowMaintenanceBanner] = useState(false);
  useEffect(() => {
    if (underMaintenanceBanner?.length > 0) {
      setShowMaintenanceBanner(true);
    }
  }, [underMaintenanceBanner]);

  const handleClose = () => {
    setShowMaintenanceBanner(false);
  };

  return (
    <Snackbar
      ClickAwayListenerProps={{ onClickAway: () => null }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={showMaintenanceBanner}
      onClose={handleClose}
    >
      <Alert
        style={{ padding: 16, fontSize: 18, display: "flex" }}
        onClose={handleClose}
        severity="warning"
      >
        {underMaintenanceBanner || ""}
      </Alert>
    </Snackbar>
  );
};
