import { Grid, Typography, Button, makeStyles } from "@material-ui/core";
import theme from "src/v3/theme/theme";
import styled from "styled-components";

export const StyledGrid = styled.div`
  padding: 16px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

export const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

export const FilterMenuHeaderContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.default};
`;

export const HeaderTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[400]};
`;

export const BottomButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.default};
  position: sticky;
  bottom: 0;
  z-index: 999;
`;

export const FilterFieldOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
  overflow-y: auto;
`;

export const StyledButton = styled(Button)<{
  backgroundColor: string;
  onHoverBackgroundColor: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: white;
  text-transform: capitalize;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${({ onHoverBackgroundColor }) => onHoverBackgroundColor};
  }
`;

export const useStyles = makeStyles(() => ({
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.grey[900],
  },

  title: {
    color: theme.palette.grey[900],
    display: "flex",
  },
  drawerPaper: {
    backgroundColor: theme.palette.background.default,
  },
}));
