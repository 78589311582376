import { useState } from "react";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";
import { ChevronDown, ChevronUp } from "src/v3/theme/icons/general";
import IconComponent from "../../Icons/IconComponent";
import { ViewSelectionItem, ViewSelectorText, ViewText } from "./styles";
import { ActionContainer } from "../styles";
import { CustomMenu } from "../../commomStyles/Menu";
import { buildViewURL } from "src/v3/utils/url";

export const ViewSelector = () => {
  const { genericViews, currentViewData } = useGenericViewContext();

  if (!currentViewData || !genericViews) return null;

  const viewOptions = genericViews.filter(
    ({ key }) => key !== currentViewData.key
  );

  if (!viewOptions.length) return null;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [currentView, setCurrentView] = useState(currentViewData.displayName);

  const handleViewChange = (view) => {
    if (view) {
      setCurrentView(view.displayName);
      window.location.assign(buildViewURL(view.key));
    }
    handleClose();
  };

  return (
    <>
      <CustomMenu
        actions={viewOptions.map(({ displayName, key }, index) => {
          return (
            <ViewSelectionItem
              key={key}
              value={displayName}
              onClick={() => handleViewChange({ displayName, key })}
            >
              {displayName}
            </ViewSelectionItem>
          );
        })}
        TriggerComponent={
          <ActionContainer
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <ViewText>View: </ViewText>
            <ViewSelectorText>{currentView}</ViewSelectorText>
            <IconComponent Icon={anchorEl ? ChevronUp : ChevronDown} />
          </ActionContainer>
        }
      ></CustomMenu>
    </>
  );
};
