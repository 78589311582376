import { CustomFieldBase } from "src/v2/domain/entities/customField/CustomFieldBase";
import {
  StatusCategory,
  workflowStatuses,
} from "src/v3/utils/aggregateByWorkflowStatus";

export class StatusField extends CustomFieldBase {
  private category: StatusCategory;
  private statusName: string;
  private chipTextColor: string;
  private chipBackgroundColor: string;
  private legendColor: string;
  private sortPosition: number;
  private position: number;
  private tooltip: string;
  private categoryChangeDate: Date;

  constructor(field) {
    super(field);
    this.category = field.value?.category ?? "";
    this.statusName = field.value?.display_name ?? "";
    this.categoryChangeDate = field.value?.status_category_change_date
      ? new Date(field.value.status_category_change_date)
      : null;
    const foundStatus = workflowStatuses.find(
      ({ category }) => category === this.category
    );
    this.chipTextColor = foundStatus?.textColor ?? "";
    this.chipBackgroundColor = foundStatus?.color ?? "";
    this.legendColor = foundStatus?.color ?? "";
    this.sortPosition = foundStatus?.sort_position ?? 0;
    this.position = foundStatus?.position ?? 0;
    this.tooltip = foundStatus?.tooltip ?? "";
    this.value = {
      ...field.value,
      category: this.category,
      name: this.statusName,
      categoryChangeDate: this.categoryChangeDate,
      sortPosition: this.sortPosition,
      position: this.position,
    };
  }

  getValue() {
    return this.value;
  }

  getStatusCategory() {
    return this.category;
  }

  getStatusName() {
    return this.statusName;
  }

  getTextColor() {
    return this.chipTextColor;
  }

  getBackgroundColor() {
    return this.chipBackgroundColor;
  }

  getLegendColor() {
    return this.legendColor;
  }

  getSortPosition() {
    return this.sortPosition;
  }

  getPosition() {
    return this.position;
  }

  getTooltip() {
    return this.tooltip;
  }

  getValueForSort(valueProperty: string) {
    return this.value[valueProperty];
  }

  getValueForProperty(valueProperty: "category" | "name") {
    return this.value[valueProperty];
  }

  getCategoryChangeDate() {
    return this.categoryChangeDate;
  }
}
