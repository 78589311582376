import { useEffect, useState } from "react";
import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";
import { isEmpty } from "src/v2/utils/object";

export const useUIV3 = () => {
  const { currentViewData } = useGenericViewContext();
  const [isV3, setIsV3] = useState(false);
  useEffect(() => {
    if (isEmpty(currentViewData)) return;
    const { options } = currentViewData;
    const isV3 = options?.find(({ name }) => name === "new_ui")?.value || false;
    setIsV3(isV3);
  }, [currentViewData]);

  return { isV3 };
};
