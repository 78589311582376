import { WidgetBuilder } from "./builder";
import { WidgetProvider } from "src/v3/providers/WidgetContext";
import _Widget from "src/v3/entities/Widget";
import { WidgetProps } from "./types";

export const WidgetV3 = ({ widget, items }: WidgetProps) => {
  return (
    <WidgetProvider _widget={widget}>
      <WidgetBuilder items={items} />
    </WidgetProvider>
  );
};
