import { BlockItem } from "src/v2/domain/entities/block/BlockItem";

export const findProjectTeamMemberNames = (items: BlockItem[]): string[] => {
  const assignees: string[] = [];
  items.forEach((item) => {
    const assigneeName = item.getAssigneeField()?.getAssignee()?.displayName;
    if (assigneeName) {
      assignees.push(assigneeName);
    }
    if (item.hasChildren()) {
      assignees.push(...findProjectTeamMemberNames(item.getChildren()));
    }
  });
  return assignees;
};
