import {
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  Typography,
  Divider,
  Grid,
} from "@material-ui/core";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";
import { useUserPreferenceContext } from "src/v2/providers/userPreference/UserPreferenceContext";
import { StyledPaper } from "../../SearchBar/styles";
import {
  AdvancedFilterButton,
  ClearFilterButton,
  EmptyFilterMessage,
} from "./styles";
import { UserFilters, VelmaFilters } from "../QuickFilters";

export const FilterPopper = ({ open, setOpen, anchorRef, setAnchorEl }) => {
  const { handleClearFilters } = useFilterContext();
  const { userSavedFilters, velmaSavedFilters } = useUserPreferenceContext();

  const handleListKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  const clearFilters = () => {
    handleClearFilters();
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const hasUserFilters = userSavedFilters?.length > 0;
  const hasQuickFilters = velmaSavedFilters?.length > 0;
  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="bottom-end"
      transition
      disablePortal
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <StyledPaper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                {hasQuickFilters && (
                  <VelmaFilters
                    label={"Quick Filters"}
                    filters={velmaSavedFilters}
                  />
                )}
                {hasUserFilters && (
                  <UserFilters
                    label={"My Filters"}
                    filters={userSavedFilters}
                  />
                )}
                {!hasQuickFilters && !hasUserFilters && (
                  <EmptyFilterMessage disabled>
                    <Typography>No saved filters</Typography>
                  </EmptyFilterMessage>
                )}
              </MenuList>
            </ClickAwayListener>
            <Divider style={{ margin: 8 }} />
            <Grid
              container
              alignItems="center"
              justifyContent="space-around"
              style={{ padding: 8 }}
            >
              <AdvancedFilterButton
                onClick={() => setAnchorEl(anchorRef.current)}
                id="filter-menu"
                data-test-id="filter_menu"
              >
                <Typography>Advanced Filter</Typography>
              </AdvancedFilterButton>
              <ClearFilterButton
                onClick={() => clearFilters()}
                id="filter-menu"
                data-test-id="filter_menu"
              >
                <Typography>Clear</Typography>
              </ClearFilterButton>
            </Grid>
          </StyledPaper>
        </Grow>
      )}
    </Popper>
  );
};
