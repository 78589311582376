import { calculateTimeDifference } from "src/v3/utils/date";
import { pluralize } from "src/v3/utils/string";

export function getLastUpdatedString(lastUpdated) {
  const { days, hours, minutes } = calculateTimeDifference(lastUpdated);

  const dayPlural = pluralize({
    word: "day",
    count: days,
  });

  const hourPlural = pluralize({
    word: "hour",
    count: hours,
  });

  const minutePlural = pluralize({
    word: "minute",
    count: minutes,
  });

  let timeComponents = [];

  if (days > 0) {
    timeComponents.push(`${days} ${dayPlural}`);
  }

  if (hours > 0) {
    timeComponents.push(`${hours} ${hourPlural}`);
  }

  if (minutes > 0) {
    timeComponents.push(`${minutes} ${minutePlural}`);
  }

  if (timeComponents.length === 1) {
    return timeComponents[0];
  }

  if (timeComponents.length > 1) {
    const lastComponent = timeComponents.pop();
    return `${timeComponents.join(", ")} and ${lastComponent}`;
  }

  return "";
}
