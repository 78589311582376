import {
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import { useEffect, useState } from "react";
import { DateFilterType } from "src/v2/domain/enum/DateFilterType";
import { useSelectFilterOptionHook } from "src/v2/hooks/filter/useSelectFilterOptionHook";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";
import theme from "src/v3/theme/theme";

export enum LastUpdatedInDaysOption {
  GREATER_THAN = "Greater than",
  LESS_THAN = "Less than",
}

export const useStyles = makeStyles((theme) => ({
  selector: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.grey[900],
  },
}));

export const LastUpdateInDaysFilterOption = ({ field }) => {
  const { lastUpdatedInDays, setSelectedFiltersBeforeApply } =
    useFilterContext();
  const { handleSelectDateOption } = useSelectFilterOptionHook();

  const [selectedOption, setSelectedOption] = useState("");
  const [days, setDays] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setSelectedOption(lastUpdatedInDays.type);
    setDays(lastUpdatedInDays.daysAgo ?? 0);
  }, [lastUpdatedInDays]);

  function handleSelect({ selectedDays, selectedOption }) {
    handleSelectDateOption({
      field,
      selectedDates: {
        daysAgo: Number(selectedDays),
        type: selectedOption,
        dateFilterType: DateFilterType.LAST_UPDATE_IN_DAYS,
      },
      setSelectedFilterCallback: (newValue) => {
        setSelectedFiltersBeforeApply([...newValue]);
      },
    });

    setDays(selectedDays);
    setSelectedOption(selectedOption);
  }

  return (
    <Grid
      container
      direction="column"
      style={{
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: "4px",
        padding: theme.spacing(2),
        color: theme.palette.grey[900],
      }}
    >
      <Typography
        style={{
          color: theme.palette.grey[900],
          alignContent: "flex-start",
          width: "max-content",
          marginBottom: theme.spacing(3),
        }}
      >
        Last updated in days
      </Typography>
      <Grid
        alignContent="center"
        alignItems="center"
        container
        direction="row"
        justifyContent="space-between"
        style={{ gap: theme.spacing(2) }}
      >
        <FormControl size="small" variant="outlined">
          <Select
            value={selectedOption}
            onChange={(e) =>
              handleSelect({
                selectedOption: e.target.value,
                selectedDays: days,
              })
            }
            className={classes.selector}
          >
            {[
              LastUpdatedInDaysOption.GREATER_THAN,
              LastUpdatedInDaysOption.LESS_THAN,
            ].map((option) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <TextField
            size="small"
            style={{
              width: "64px",
            }}
            id="outlined-basic"
            variant="outlined"
            value={days}
            onInput={(e) =>
              handleSelect({ selectedDays: e.target.value, selectedOption })
            }
          />
        </FormControl>
        <Grid>
          <Typography style={{ color: theme.palette.grey[900] }}>
            days
          </Typography>
          <Typography style={{ color: theme.palette.grey[900] }}>
            ago
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
