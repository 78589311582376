type TextSideInfoProps = {
  value: any;
  label: string;
  style?: React.CSSProperties;
};

export const TextSideInfo = ({ value, label, style }: TextSideInfoProps) => {
  return (
    <div style={style}>
      {label}: {value && value !== "" ? value : " -- "}
    </div>
  );
};
