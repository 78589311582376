import { IconComponentProps } from "../types";
import { sizeMap } from "src/v3/theme/icons/mappers";
import { IssueTypeIconRoot } from "../styles";
import theme from "src/v3/theme/theme";
import IconComponent from "../../Icons/IconComponent";

export const IssueTypeIconBase = ({
  backgroundColor,
  size = "medium",
  stroke = theme.palette.primary.contrastText,
  fill = "transparent",
  icon,
}: IconComponentProps) => {
  return (
    <IssueTypeIconRoot backgroundColor={backgroundColor} size={size}>
      <IconComponent
        Icon={icon}
        size={sizeMap[size]}
        stroke={stroke}
        fill={fill}
      />
    </IssueTypeIconRoot>
  );
};
