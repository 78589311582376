import { CustomFieldBase } from "src/v2/domain/entities/customField/CustomFieldBase";

export class SprintField extends CustomFieldBase {
  constructor(field) {
    super(field);
    this.value = field.value || [];
  }

  getSprintDisplayName() {
    return this.value[0]?.display_name;
  }
}
