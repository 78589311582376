import { BlockItem } from "src/v2/domain/entities/block/BlockItem";

/**
 * Filters a list of `BlockItem` objects based on a search term.
 *
 * @param items - The array of `BlockItem` objects to search through.
 * @param searchTerm - The term to search for within the `BlockItem` objects.
 * @returns An array of `BlockItem` objects that match the search term.
 */
export default function searchItems(
  items: BlockItem[],
  searchTerm: string
): BlockItem[] {
  const lowerSearchTerm = searchTerm.toLowerCase();

  return items.filter((item) => {
    return (
      item.getKey().toLowerCase().includes(lowerSearchTerm) ||
      item.getDisplayName().toLowerCase().includes(lowerSearchTerm)
    );
  });
}
export const blockItemTypeMap = {
  idea: "Idea",
  project: "Product",
  product: "Product",
  deliverable: "Project",
  release: "Release",
  bug: "Bug",
  task: "Task",
};
