import styled from "styled-components";
import { Button, makeStyles, MenuItem } from "@material-ui/core";

// Keeping makeStyles for menu-specific styles
export const useStyles = makeStyles((theme) => ({
  menuOpen: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    borderRadius: "8px",
    border: "1px solid #D3D5D7",
    background: "#FFF",
    width: "max-content",
    boxShadow: "0px 16px 32px 0px rgba(0, 0, 0, 0.16)",
  },
}));

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
`;

// StatusSelector using div instead of Material UI Box
export const StatusSelector = styled.div`
  height: 32px;
  display: flex;
`;

// Keeping Button from Material UI and applying custom styles
export const ProjectStatusSelectorRoot = styled.div<{
  backgroundColor: string;
}>`
  display: flex;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  height: 100%;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1)};
  flex-shrink: 0;
  border-radius: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ backgroundColor }) => backgroundColor};
  min-width: 167px;
  &:hover {
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
`;

// Keeping MenuItem from Material UI with custom hover behavior
export const ProjectStatusSelectorMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: flex-end;
  &:hover {
    background-color: transparent;
  }
`;

// SelectorTitles using div and applying agreed typography pattern
export const SelectorTitles = styled.div`
  font-family: ${({ theme }) => theme.typography.body1.fontFamily} !important;
  font-size: 16px !important;
  width: 100%;
  font-weight: 500 !important;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

// SelectorGrid using div and applying dynamic width and spacing
export const SelectorGrid = styled.div<{ width?: string; customStyle? }>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  flex-direction: column;
  width: ${({ width }) => width ?? "30%"};
  min-width: 167px;
  color: ${({ theme }) => theme.palette.grey[900]};
  ${({ customStyle }) => customStyle}
`;
