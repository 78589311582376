import React, { useContext, useEffect, useState } from "react";
import theme from "src/v3/theme/theme";

const LayoutContext = React.createContext(undefined);

function useLayout() {
  const context = useContext(LayoutContext);
  return context;
}

function LayoutContextProvider({ children }: any) {
  const [isBreakdownCollapsed, setIsBreakdownCollapsed] = useState(true);

  const [isUncategorizedCollapsed, setIsUncategorizedCollapsed] =
    useState(true);
  const [isNavBarCollapsed, setIsNavBarCollapsed] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(64);

  const handleIsUncategorizeCollapsed = () => {
    setIsUncategorizedCollapsed((prevState) => !prevState);
  };

  const topBarBase = 66;
  const [topBarHeight, setTopBarHeight] = useState(topBarBase);

  useEffect(() => {
    const handleResize = () => {
      const { sm, md, lg, xl } = theme.breakpoints.values;
      const width = window.innerWidth;

      const breakpointsMap = [
        { max: sm, height: topBarBase * 2.2 },
        { max: md, height: topBarBase * 1.5 },
        { max: lg, height: topBarBase },
        { max: xl, height: topBarBase },
      ];

      const newTopBarHeight =
        breakpointsMap.find((bp) => width < bp.max)?.height || topBarBase;

      setTopBarHeight(newTopBarHeight);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [theme, setTopBarHeight]);

  const [navBarSpace, setNavBarSpace] = useState(196);

  useEffect(() => {
    setNavBarSpace(isNavBarCollapsed ? 64 : 196);
  }, [isNavBarCollapsed]);

  const value = React.useMemo(() => {
    const uncategorizedHeight = isUncategorizedCollapsed ? "64px" : "48vh";
    const breakdownSpace = isBreakdownCollapsed ? "8px" : "316px";
    const normalizedHeaderHeight = Math.max(headerHeight, 64);
    return {
      isBreakdownCollapsed,
      setIsBreakdownCollapsed,
      isUncategorizedCollapsed,
      setIsUncategorizedCollapsed: handleIsUncategorizeCollapsed,
      isNavBarCollapsed,
      setIsNavBarCollapsed,
      breakdownSpace,
      navBarSpace,
      uncategorizedHeight,
      headerHeight,
      setHeaderHeight,
      normalizedHeaderHeight,
      topBarHeight,
    };
  }, [
    isBreakdownCollapsed,
    isUncategorizedCollapsed,
    isNavBarCollapsed,
    headerHeight,
    topBarHeight,
    navBarSpace,
  ]);

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
}

export { useLayout, LayoutContextProvider };
