import { Divider } from "@material-ui/core";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { Fragment, useEffect, useState } from "react";
import { pluralize } from "src/utils/stringUtils";
import { useEventListener, EventTypes } from "src/providers/EventContext";
import { LastUpdatedAuthor } from "src/v3/components/commomStyles/LastUpdatedAuthor";
import { ItemDetailAccordion } from "../../commonStyles/ItemDetailAccordion";
import {
  AccordionGrid,
  BoldTypography,
  ContextTitle,
  InfoContainer,
  InfoItem,
  InfoRow,
} from "./styles";
import { findProjectTeamMemberNames } from "./utils";

export const SummaryOfWorkAccordion = ({
  title = "Summary of Work",
  item,
}: {
  title?: string;
  item: BlockItem;
}) => {
  const aiSummaryOverride = useEventListener(
    EventTypes.AI_SUMMARY_OVERRIDE,
    "SummaryOfWorkAccordion"
  );

  const summaryOfWorkField = item.getSummaryOfWorkField();
  const commentsField = item.getCommentsField();
  const assigneeField = item.getAssigneeField();
  const dependencyField = item.getDependencyField();

  const override = summaryOfWorkField?.getLastAddedOverride();
  const aiSummary = summaryOfWorkField?.getAiSummary();

  const commentSummary = commentsField?.getSummary();
  const assignee = assigneeField?.getAssignee();

  const slackMessages = [];
  const blockedBy = dependencyField?.getBlockedBy();
  const blocks = dependencyField?.getAllItemsThatCurrentIssueBlocks();

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const handleToggleAccordion = () => {
    setIsAccordionExpanded((prev) => !prev);
  };

  const hasOverride = summaryOfWorkField?.hasOverride();
  const hasAdditionalContextValue =
    hasOverride || slackMessages?.length > 0 || !!commentSummary;
  const [hasAdditionalContext, setHasAdditionalContext] = useState(
    hasAdditionalContextValue
  );

  const hasDataValue =
    hasAdditionalContext ||
    !!aiSummary ||
    !!assignee ||
    !!blockedBy ||
    !!blocks;
  const [hasData, setHasData] = useState(hasDataValue);

  useEffect(() => {
    setHasAdditionalContext(hasAdditionalContextValue);
    setHasData(hasDataValue);
  }, [aiSummaryOverride]);

  const projectTeam = findProjectTeamMemberNames([item] || [])
    .filter((name, index, self) => name && self.indexOf(name) === index)
    .sort((a, b) => a.localeCompare(b));

  return (
    <ItemDetailAccordion
      title={title}
      expanded={hasData && isAccordionExpanded}
      setExpanded={handleToggleAccordion}
      actions={[summaryOfWorkField.getDetailViewEditSummaryOfWorkAction(item)]}
    >
      <AccordionGrid>
        <>
          {hasAdditionalContext && (
            <Fragment>
              <ContextTitle>
                Additional context
                {summaryOfWorkField.hasOverride() && (
                  <LastUpdatedAuthor
                    author={override?.value?.author?.name}
                    lastUpdatedDate={override?.value?.created_at}
                  />
                )}
              </ContextTitle>

              {override?.value && (
                <div
                  dangerouslySetInnerHTML={{ __html: override.value.context }}
                />
              )}

              {commentSummary && (
                <div dangerouslySetInnerHTML={{ __html: commentSummary }} />
              )}

              {slackMessages?.length > 0 && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: slackMessages
                      .map(
                        (message) =>
                          `<ul class='arrowed'><li>${message.summary}</li></ul>`
                      )
                      .join("<br>"),
                  }}
                />
              )}

              <Divider style={{ width: "100%" }} />
            </Fragment>
          )}
          {aiSummary && (
            <Fragment>
              {hasAdditionalContext && (
                <ContextTitle>Velma summary</ContextTitle>
              )}
              <div dangerouslySetInnerHTML={{ __html: aiSummary }} />
              <Divider style={{ width: "100%" }} />
            </Fragment>
          )}
        </>
        <InfoContainer>
          <InfoRow>
            <InfoItem>
              <>Owner: </>
              <BoldTypography>
                {assignee?.displayName || "Unassigned"}
              </BoldTypography>
            </InfoItem>
            <Divider orientation="vertical" flexItem />
            <div>
              <>Project team: </>
              <b>
                {projectTeam.slice(0, 3).join(", ") || "--"}
                {projectTeam.length > 3 && (
                  <> + {projectTeam.length - 3} more</>
                )}
              </b>
            </div>
            <Divider orientation="vertical" flexItem />
            <div>
              <>Blocked by: </>
              <b>
                {blockedBy?.length || 0}{" "}
                {pluralize({ word: "issue", count: blockedBy?.length || 0 })}
              </b>
            </div>
            <Divider orientation="vertical" flexItem />
            <div>
              <>Is blocking: </>
              <b>
                {blocks?.length || 0}{" "}
                {pluralize({ word: "issue", count: blockedBy?.length || 0 })}
              </b>
            </div>
          </InfoRow>
        </InfoContainer>
      </AccordionGrid>
    </ItemDetailAccordion>
  );
};
