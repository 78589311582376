import { Checkbox, Grid, makeStyles, Typography } from "@material-ui/core";
import { primaryTextColor } from "src/v2/helpers/colors";
import { useSelectFilterOptionHook } from "src/v2/hooks/filter/useSelectFilterOptionHook";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";
import theme from "src/v3/theme/theme";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    wordBreak: "break-word",
    overflowWrap: "break-word",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "flex-start",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  text: {
    width: "max-content",
    color: theme.palette.grey[900],
  },
}));

export const InsightsCheckboxFilterOption = ({ value, field }) => {
  const classes = useStyles();
  const { setSelectedFiltersBeforeApply } = useFilterContext();
  const { isSelectedOption, handleSelectCheckboxOption } =
    useSelectFilterOptionHook();
  return (
    <div
      className={classes.root}
      onClick={() =>
        handleSelectCheckboxOption({
          checked: value,
          field,
          setSelectedFilterCallback: (newData) =>
            setSelectedFiltersBeforeApply([...newData]),
        })
      }
    >
      <Checkbox
        color="primary"
        checked={isSelectedOption({
          checked: value,
          fieldKey: field.fieldKey,
        })}
      />
      <Typography className={classes.text} noWrap>
        {value}
      </Typography>
    </div>
  );
};
