/* eslint-disable no-use-before-define */
import { useContext, createContext, useMemo, useState, useEffect } from "react";
import { deepCloneClassInstance } from "src/utils/objectUtils";
import { LastUpdatedInDaysOption } from "src/v2/components/filter/components/LastUpdatedInDaysOption";
import { FieldConfig } from "src/v2/domain/entities/fieldConfig/FieldConfig";
import { FilterFieldsFactory } from "src/v2/domain/entities/filter/factory";
import { FilterFieldBase } from "src/v2/domain/entities/filter/FilterFieldBase";
import { FieldConfigType } from "src/v2/domain/enum/FieldConfigType";
import { useURLFilterHook } from "src/v2/hooks/filter/useURLFilterHook";
import { filterDuplicateItemsByKey } from "src/v2/utils/array";
import { filterDuplicatedInList } from "src/utils/listUtils";
import { BlockItemLevel } from "src/v2/domain/enum/BlockItemLevel";
import { useFieldsContext } from "../fields/FieldsContext";
import { useFilterByLevel } from "./useFilterByLevel";
import { useFieldsLogic } from "../fields/useFieldsLogic";
import { FilterLogic } from "src/v2/domain/enum/FilterLogic";
import { DateFilterType } from "src/v2/domain/enum/DateFilterType";
import { useGenericViewContext } from "../genericView/GenericViewContext";
import {
  getAllKeyValueFiltersFromURLWithoutAdmin,
  getFilterLevelFromURL,
} from "src/v3/utils/url";

const FilterContext = createContext<{
  filterFields: FilterFieldBase[];
  selectedFilters;
  selectedFiltersBeforeApply: FilterFieldBase[];
  handleUpdateFilters;
  removeFilterValue;
  setSelectedFiltersBeforeApply;
  filteredByString;
  selectedFilterLevels: [];
  filterLevelOptions;
  handleSetFilterLevel;
  handleSetFilterLevelOptions;
  setFilterLevelOptions;
  handleClearFilters;
  getNormalizedDisplayName;
  allPossibleFilterLevels;
  setSelectedFilterLevels;
  lastUpdatedInDays: {
    daysAgo: number;
    type: string;
  };
  handleApplyFiltersFromURL;
}>({
  filterFields: [],
  selectedFilters: [],
  filterLevelOptions: [],
  selectedFiltersBeforeApply: [],
  filteredByString: "",
  selectedFilterLevels: [],
  allPossibleFilterLevels: [],
  lastUpdatedInDays: {
    daysAgo: null,
    type: LastUpdatedInDaysOption.GREATER_THAN,
  },
  handleSetFilterLevel: (selected) => {},
  setFilterLevelOptions: (options) => {},
  handleUpdateFilters: (selected) => {},
  removeFilterValue: (filterField, valueToRemove) => {},
  setSelectedFiltersBeforeApply: (fields) => {},
  getNormalizedDisplayName: (displayName) => {},
  handleSetFilterLevelOptions: (items) => [],
  handleClearFilters: (items) => [],
  setSelectedFilterLevels: (items) => [],
  handleApplyFiltersFromURL: (url) => {},
});

function useFilterContext() {
  const context = useContext(FilterContext);
  return context;
}

function FilterContextProvider({ children }: any) {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterFields, setFilterFields] = useState([]);
  const [selectedFiltersBeforeApply, setSelectedFiltersBeforeApply] = useState([
    ...selectedFilters,
  ]);
  const [filteredByString, setFilteredByString] = useState("");
  const [allPossibleFilterLevels, setAllPossibleFilterLevels] = useState([]);
  const [filterLevelOptions, setFilterLevelOptions] = useState([]);
  const [selectedFilterLevels, setSelectedFilterLevels] = useState([]);

  const { setSelectedFiltersInURL } = useURLFilterHook();
  const {
    fieldsConfig,
    setFieldsToShowOrUseInFilter,
    fieldsToShowOrUseInFilter,
  } = useFieldsContext();
  const { viewSchema } = useGenericViewContext();

  const [lastUpdatedInDays, setLastUpdatedInDays] = useState({
    daysAgo: null,
    type: LastUpdatedInDaysOption.GREATER_THAN,
  });

  const { getFieldsWithDefaultValues } = useFieldsLogic();

  const {
    getFilterLevelField,
    getPossibleLevelsByItems,
    buildFilterLevelFilterField,
  } = useFilterByLevel();

  useEffect(() => {
    if (!viewSchema) return;
    const allFilterLevels = viewSchema.map(({ display_name }) => display_name);
    setAllPossibleFilterLevels(allFilterLevels);
  }, [viewSchema]);

  useEffect(() => {
    if (fieldsConfig.length === 0 && allPossibleFilterLevels.length === 0)
      return;
    const fieldsToShowOrUseOnFilter = filterDuplicateItemsByKey({
      items: fieldsConfig.filter(
        (field) => field.showOnFilter() || field.isReadOnFilter()
      ),
      key: "fieldKey",
    });

    const filterInstances = fieldsToShowOrUseOnFilter.map(
      (field: FieldConfig) => FilterFieldsFactory.getFilterFieldInstance(field)
    ) as FilterFieldBase[];
    setFilterLevelValuesBasedOnURL();
    const urlFilters = getAllKeyValueFiltersFromURLWithoutAdmin();
    if (selectedFilters.length === 0 && urlFilters.length === 0) {
      const fieldsWithDefaultValues = getFieldsWithDefaultValues();
      setFilterLevelOptions(allPossibleFilterLevels);
      handleUpdateFilters(fieldsWithDefaultValues);
    }
    handleUpdateFilterFields(filterInstances);
    setFieldsToShowOrUseInFilter(fieldsToShowOrUseOnFilter);
  }, [fieldsConfig, allPossibleFilterLevels]);

  useEffect(() => {
    const filterFieldValue = selectedFiltersBeforeApply.find(
      ({ type, fieldKey }) =>
        FieldConfigType.date === type && fieldKey === "Updated"
    )?.values[0];
    const selectedDaysAgo = filterFieldValue ?? {
      daysAgo: null,
      type: LastUpdatedInDaysOption.GREATER_THAN,
    };

    setLastUpdatedInDays(selectedDaysAgo);
  }, [selectedFiltersBeforeApply]);

  function setFilterLevelValuesBasedOnURL() {
    const filterLevelInURL = getFilterLevelFromURL();
    const urlHasFilterLevelSet =
      filterLevelInURL.length !== 0 && filterLevelInURL[0].values.length !== 0;

    if (urlHasFilterLevelSet) {
      const _filterLevels = filterLevelInURL[0].values.filter((level) => {
        return viewSchema.find((schema) => schema.display_name === level);
      });

      setSelectedFilterLevels(_filterLevels);
    } else {
      setSelectedFilterLevels(allPossibleFilterLevels);
    }
  }

  function handleUpdateFilterFields(fields: FilterFieldBase[]) {
    const sortedByPositionFields = fields.sort(
      (a, b) => a.displayConfig.position - b.displayConfig.position
    );
    setFilterFields(sortedByPositionFields);
  }

  function handleUpdateFilteredByString(_filterFields: FilterFieldBase[]) {
    const selectedFiltersAsString = _filterFields
      .map((filterField) => `${filterField.getFilteredByString()} `)
      .reduce((acc, curr) => ` ${curr}| ${acc}`, "");

    const _filteredByString = `${
      selectedFiltersAsString.length > 0
        ? `Filtered by:${selectedFiltersAsString}`
        : ""
    }`;
    setFilteredByString(_filteredByString);
  }

  function updateSelectedFilters(_filterFields: FilterFieldBase[]) {
    setSelectedFiltersBeforeApply(_filterFields.map(deepCloneClassInstance));
    setSelectedFilters(_filterFields.map(deepCloneClassInstance));
  }

  function instantiateFilterFieldWithValues({ fieldKey, values }) {
    const fieldConfig = fieldsConfig.find(
      (field) => fieldKey === field.fieldKey
    );
    if (!fieldConfig) return null;

    const instance = FilterFieldsFactory.getFilterFieldInstance(fieldConfig);
    const _values = Array.isArray(values) ? values : [values];
    instance.setValues(_values);
    instance.setValuesCopyForSearch(instance.values);
    return instance;
  }

  function getFiltersThatShouldNotClear() {
    const filterTypesThatNeverClear = [FieldConfigType.changelog];
    return fieldsToShowOrUseInFilter
      .filter(({ type }) => filterTypesThatNeverClear.includes(type))
      .map((field) => FilterFieldsFactory.getFilterFieldInstance(field));
  }

  function handleClearFilters() {
    const _filterLevelFilterField =
      buildFilterLevelFilterField(filterLevelOptions);
    handleUpdateFilters([
      ...getFiltersThatShouldNotClear(),
      _filterLevelFilterField,
    ]);
  }

  function handleUpdateFilters(newFilters) {
    const hasNewFilters = newFilters.length !== 0;
    if (!hasNewFilters) {
      newFilters.push(getFieldsWithDefaultValues());
    }

    const _andLogicFilters = getANDLogicFilters(newFilters);
    const _orLogicFilters = getORLogicFilters(newFilters);
    const _filterFields = [..._andLogicFilters, ..._orLogicFilters];

    const _filterLevelFilterField = getFilterLevelField(
      newFilters,
      selectedFilterLevels,
      filterLevelOptions,
      setSelectedFilterLevels
    );
    setSelectedFiltersInURL([..._filterFields, _filterLevelFilterField]);
    updateSelectedFilters(_filterFields);
    handleUpdateFilteredByString(_filterFields);
  }

  const removeFilterValue = (filterFieldId, valueToRemove) => {
    const updatedFilters = selectedFilters
      ?.map((field) => {
        if (field.id !== filterFieldId) return field;

        const newField = field.clone();
        newField.removeValue(valueToRemove);
        return newField;
      })
      .filter((field) => field.values.length > 0);

    handleUpdateFilters(updatedFilters);
  };

  function getORLogicFilters(filters) {
    return filters
      .filter(
        ({ fieldKey, filterLogic }) =>
          fieldKey === FilterLogic.OR || filterLogic === FilterLogic.OR
      )
      .flatMap(({ values, fieldKey }) => {
        /*
          Values will be a string in the first load.
          Example: "Priority=High"
          Example: "TargetDate=01-01-2023to01-01-2024"
          First we need to convert it to an object and than we can instantiate the filter field.
        */

        const fieldWithValues = values.map((it) => {
          if (typeof it === "string") {
            const fieldKey = it.split("=")[0];
            const values = it.split("=")[1];
            return {
              fieldKey,
              values,
            };
          }

          /* 
            After the first load, when we already had set the filter fields,
            in case of the field being a Date Filter Field the the values will be an object with from and to.
            Example: {from: "01-01-2023", to: "01-01-2024"}
           */
          if (typeof it === "object" && (it.from || it.to)) {
            return {
              fieldKey,
              values: { from: it.from, to: it.to },
              dateFilterType: DateFilterType.DAYS_BETWEEN,
            };
          }
        });

        return fieldWithValues.map(({ fieldKey, values }) => {
          const instance = instantiateFilterFieldWithValues({
            fieldKey,
            values,
          });

          if (instance) {
            instance.setFilterLogic(FilterLogic.OR);
          }
          return instance;
        });
      })
      .filter((field) => field) as FilterFieldBase[];
  }

  function getANDLogicFilters(filters) {
    return filters
      .filter(
        ({ fieldKey }) =>
          fieldKey !== "admin" &&
          fieldKey !== "filter_level" &&
          fieldKey !== FilterLogic.OR
      )
      .filter(({ filterLogic }) => filterLogic !== FilterLogic.OR)
      .map(({ fieldKey, values }) =>
        instantiateFilterFieldWithValues({ fieldKey, values })
      )
      .filter((field) => field) as FilterFieldBase[];
  }

  function handleSetFilterLevelOptions(items) {
    const possibleLevelsToFilter = getPossibleLevelsByItems(items);
    const filteredLevels = filterDuplicatedInList({
      items: [...possibleLevelsToFilter],
    });
    setFilterLevelOptions(filteredLevels);
  }

  function handleSetFilterLevel(level: BlockItemLevel) {
    if (selectedFilterLevels.includes(level)) {
      const filteredLevels = selectedFilterLevels.filter((it) => it !== level);
      setSelectedFilterLevels(filteredLevels);
    } else {
      setSelectedFilterLevels((prev) => [...prev, level]);
    }
  }

  function handleApplyFiltersFromURL(url) {
    if (!url) return;
    const urlFilters = getAllKeyValueFiltersFromURLWithoutAdmin(url);
    const filterInstances = urlFilters
      .map(({ fieldKey, values }) =>
        instantiateFilterFieldWithValues({ fieldKey, values })
      )
      .filter((field) => field);
    handleUpdateFilters(filterInstances);
  }

  const value = useMemo(
    () => ({
      filterFields,
      selectedFilters,
      selectedFiltersBeforeApply,
      setSelectedFiltersBeforeApply,
      handleUpdateFilters,
      removeFilterValue,
      lastUpdatedInDays,
      filteredByString,
      selectedFilterLevels,
      handleSetFilterLevel,
      filterLevelOptions,
      setFilterLevelOptions,
      handleSetFilterLevelOptions,
      handleClearFilters,
      allPossibleFilterLevels,
      setSelectedFilterLevels,
      handleApplyFiltersFromURL,
    }),
    [
      selectedFilters,
      filterFields,
      selectedFiltersBeforeApply,
      lastUpdatedInDays,
      filteredByString,
      selectedFilterLevels,
      filterLevelOptions,
      allPossibleFilterLevels,
    ]
  );

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
}

export { FilterContext, FilterContextProvider, useFilterContext };
