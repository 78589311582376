import {
  useContext,
  createContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { getEmailReportsInstance } from "src/v2/domain/entities/emailReports/EmailReportsFactory";
import { getAllBlocks, getUpdatedBlocks } from "src/v2/services/blocks";
import { getLastUpdatedString } from "src/v2/utils/date";
import { isEmpty } from "src/v2/utils/object";
import Promise from "bluebird";
import { isDevelopmentEnvironment } from "src/environmentUtils";
import { useGenericViewContext } from "../genericView/GenericViewContext";
import { getLoggedUser, isDevMode } from "src/services/sessionService";

Promise.config({
  cancellation: true,
});

const BlockContext = createContext<{
  originalItems: BlockItem[];
  flatOriginalItems: BlockItem[];
  filteredItems: BlockItem[];
  handleUpdateFilteredItems: (filteredItems) => void;
  getCopyOriginalItems: () => BlockItem[];
  isLoadingBlocks: boolean;
  lastUpdated: string;
  lastAIGenerated: string;
  isGettingUpdatedBlocks: boolean;
}>({
  originalItems: [],
  flatOriginalItems: [],
  filteredItems: [],
  getCopyOriginalItems: () => [],
  handleUpdateFilteredItems: (filteredItems) => {},
  isLoadingBlocks: true,
  lastUpdated: "",
  lastAIGenerated: "",
  isGettingUpdatedBlocks: true,
});

function useBlockContext() {
  const context = useContext(BlockContext);
  return context;
}

function BlockContextProvider({ children }: any) {
  const [filteredItems, setFilteredItems] = useState([]);
  const [originalItems, setOriginalItems] = useState([]);
  const [flatOriginalItems, setFlatOriginalItems] = useState([]);
  const [blocksAPIResponse, setBlocksAPIResponse] = useState([]);
  const [isLoadingBlocks, setIsLoadingBlocks] = useState(true);
  const [isGettingUpdatedBlocks, setIsGettingUpdatedBlocks] = useState(true);
  const [lastUpdated, _setLastUpdated] = useState("");
  const [lastAIGenerated, setLastAIGenerated] = useState("");
  const [emailReportsInstance, setEmailReportsInstance] = useState({});
  const [blockPromises, setBlockPromises] = useState<Promise[]>([]);
  const [timeouts, setTimeouts] = useState<any[]>([]);
  const { currentViewData } = useGenericViewContext();
  const [updatedBlocksLoaded, setUpdatedBlocksLoaded] = useState(false);
  const currentViewIdRef = useRef(currentViewData?.id);

  const setLastUpdated = (last_updated) => {
    const lastUpdatedString = getLastUpdatedString(last_updated);
    _setLastUpdated(lastUpdatedString);
    localStorage.setItem("lastCacheUpdatedDate", last_updated);
    console.log(`setLastUpdated called with ${lastUpdatedString}`);
  };

  const cancelPromises = () => {
    blockPromises.forEach((promise) => {
      console.warn("[Block Loader] cancelling previous request");
      promise.cancel();
    });
    timeouts.forEach((timeout) => {
      clearTimeout(timeout);
    });
    setTimeouts([]);
    setBlockPromises([]);
  };
  function isActiveView(data: any) {
    return data?.view_id == currentViewIdRef.current;
  }

  function setBlocksData(_blocks) {
    const user = getLoggedUser();
    if (isDevMode()) {
      console.debug(
        `[Block Loader] - API Response BEFORE creating original items`,
        _blocks
      );
    }
    setBlocksAPIResponse(_blocks);
    const _originalItems = _blocks.map((item) => new BlockItem(item));

    if (isDevMode()) {
      console.debug(
        `[Block Loader] - AFTER creating original items`,
        _originalItems
      );
    }
    setOriginalItems([..._originalItems]);
  }

  function handleUpdatedBlocksLoaded() {
    setUpdatedBlocksLoaded(true);
  }

  useEffect(() => {
    setFlatOriginalItems(flattenItems([...originalItems]));
  }, [originalItems]);

  function flattenItems(items: BlockItem[]): BlockItem[] {
    let flatList: BlockItem[] = [];

    items.forEach((item) => {
      flatList.push(item);
      if (item.hasChildren()) {
        flatList = flatList.concat(flattenItems(item.getChildren()));
      }
    });

    return flatList;
  }

  function fetchUpdatedBlocksData(
    thresholdInSecondsToGetFreshData,
    lastUpdatedAt,
    allBlocks
  ) {
    console.debug(
      `[Block Loader] Starting to fetch new data after: ${thresholdInSecondsToGetFreshData}`
    );
    const timeout = setTimeout(() => {
      console.debug(
        // eslint-disable-next-line max-len
        `[Block Loader] Fetching new data for view  ${currentViewData.id} - ${currentViewData.displayName}`
      );
      const _updatedBlocksPromise = new Promise((resolve) =>
        resolve(
          getUpdatedBlocks({
            viewId: currentViewData.id,
            blocksDataUpdatedAt: lastUpdatedAt,
          })
        )
      ).then(({ data, status }) => {
        if (status === 204) {
          setIsGettingUpdatedBlocks(false);
          handleUpdatedBlocksLoaded();
          setLastAIGenerated(getLastUpdatedString(data.last_ai_generated_at));
          console.debug("[Block Loader]:  won't get updated data");
        } else if (data?.blocks) {
          console.debug(
            "[Updated Blocks Loader] Fetched new data successfully"
          );

          if (isActiveView(data)) {
            console.debug("[Updated Blocks Loader] Starting Updating UI");
            console.time("Updating UI");
            setIsGettingUpdatedBlocks(false);
            handleUpdatedBlocksLoaded();
            setLastUpdated(data.last_updated);
            if (isDevelopmentEnvironment) {
              console.debug(
                `[Updated Blocks Loader] Last Updated ${getLastUpdatedString(
                  data.last_updated
                )}`
              );
            }

            setLastAIGenerated(getLastUpdatedString(data.last_ai_generated_at));

            setBlocksData(data.blocks);
            console.timeEnd("Updating UI");
            console.debug("[Updated Blocks Loader] Done Updating UI");
          } else {
            console.error(
              `[Updated Blocks Loader]: Canceling - new data is for ${currentViewData.displayName}`
            );
          }
          console.debug("[Block Loader] 🏁 END 🏁");
        } else if (status === 202) {
          console.debug("[Block Loader]: new data is still updating");
          fetchUpdatedBlocksData(
            data.update_data_threshold_in_seconds,
            data.last_updated,
            allBlocks
          );
        }
      });
      setBlockPromises((prev) => [...prev, _updatedBlocksPromise]);
    }, thresholdInSecondsToGetFreshData * 1000);
    setTimeouts((prev) => [...prev, timeout]);
  }

  async function loadUpdatedBlocksData(
    thresholdInSecondsToGetFreshData: number,
    lastUpdatedAt: string,
    allBlocks
  ) {
    const lastUpdatedDatePlusThreshold = new Date(lastUpdatedAt);
    lastUpdatedDatePlusThreshold.setSeconds(
      lastUpdatedDatePlusThreshold.getSeconds() +
        thresholdInSecondsToGetFreshData
    );

    console.debug(`[Block Loader] 🎬 START 🎬 ${currentViewData.displayName}`);
    console.group("Thresholds");
    console.debug("[Block Loader] last updated", lastUpdatedAt);
    console.debug(
      "[Block Loader] last updated + threshold",
      lastUpdatedDatePlusThreshold
    );
    console.groupEnd();

    if (blockPromises.length > 0 || timeouts.length > 0) {
      cancelPromises();
    }
    fetchUpdatedBlocksData(
      thresholdInSecondsToGetFreshData,
      lastUpdatedAt,
      allBlocks
    );
  }

  useEffect(() => {
    if (isEmpty(currentViewData)) {
      return;
    }
    cancelPromises();
    currentViewIdRef.current = currentViewData?.id;
    setIsLoadingBlocks(true);

    const _getAllBlocksPromise = new Promise((resolve) =>
      resolve(getAllBlocks({ viewId: currentViewData.id }))
    ).then(({ blocks, update_data_threshold_in_seconds, last_updated }) => {
      setIsLoadingBlocks(false);
      setIsGettingUpdatedBlocks(true);
      setBlocksData(blocks);
      /* This 👇 is to make development faster, because we won't need to get the updated data
           to take the screenshot, only that all blocks have loaded */
      if (isDevelopmentEnvironment) {
        handleUpdatedBlocksLoaded();
      }
      loadUpdatedBlocksData(
        update_data_threshold_in_seconds,
        last_updated,
        blocks
      );

      setLastUpdated(last_updated);
    });
    setBlockPromises((prev) => [...prev, _getAllBlocksPromise]);

    setEmailReportsInstance(getEmailReportsInstance());
  }, [currentViewData]);

  function getCopyOriginalItems() {
    return JSON.parse(JSON.stringify(blocksAPIResponse)).map(
      (item) => new BlockItem(item)
    );
  }

  const [updatedBlocksForEmail, setUpdatedBlocksForEmail] = useState([]);

  useEffect(() => {
    if (
      updatedBlocksLoaded &&
      updatedBlocksForEmail?.length > 0 &&
      emailReportsInstance
    ) {
      emailReportsInstance?.saveFormattedDataLocally(
        updatedBlocksForEmail,
        updatedBlocksLoaded
      );
    }
  }, [updatedBlocksForEmail, updatedBlocksLoaded]);

  function handleUpdateFilteredItems(_filteredItems) {
    setFilteredItems(_filteredItems);
    if (updatedBlocksLoaded) {
      setUpdatedBlocksForEmail(_filteredItems);
    }
  }
  const value = useMemo(() => {
    console.log(` UseMemo LastUpdated: ${lastUpdated} `);
    return {
      filteredItems,
      originalItems,
      handleUpdateFilteredItems,
      getCopyOriginalItems,
      isLoadingBlocks,
      lastUpdated,
      isGettingUpdatedBlocks,
      lastAIGenerated,
      flatOriginalItems,
    };
  }, [
    filteredItems,
    originalItems,
    isLoadingBlocks,
    lastUpdated,
    isGettingUpdatedBlocks,
  ]);

  return (
    <BlockContext.Provider value={value}>{children}</BlockContext.Provider>
  );
}

export { BlockContext, BlockContextProvider, useBlockContext };
