import styled from "styled-components";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { CloseButton } from "src/v3/theme/icons/general";

export const DialogRoot = styled(Dialog)`
  & .MuiPaper-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: ${({ theme }) => theme.spacing(2)};
    border: 1px solid ${({ theme }) => theme.palette.grey[600]};
    background: ${({ theme }) => theme.palette.background.paper};
    box-shadow: ${({ theme }) => theme.shadows[1]};
  }
`;

export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const CustomDialogTitle = styled(DialogTitle)`
  color: ${({ theme }) => theme.palette.grey[900]};
  font-family: ${({ theme }) =>
    theme.typography.dialogTitle.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.dialogTitle.fontSize} !important;
  font-weight: ${({ theme }) =>
    theme.typography.dialogTitle.fontWeight} !important;
  line-height: ${({ theme }) =>
    theme.typography.dialogTitle.lineHeight} !important;
`;

export const CloseIcon = styled(CloseButton)`
  align-self: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin: ${({ theme }) => theme.spacing(0, 4)};
`;

export const CustomDialogContent = styled(DialogContent)<{
  customStyle?: React.CSSProperties;
}>`
  width: 540px;
  min-height: 312px;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[600]};
  padding: ${({ theme }) => theme.spacing(4)};

  ${({ customStyle }) => customStyle && { ...customStyle }};
`;

export const DialogFooter = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(4)};
  justify-content: flex-end;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing(3)};
  align-self: stretch;
`;

export const DialogCloseButton = styled.div`
  display: flex;
  height: ${({ theme }) => theme.spacing(9)};
  padding: ${({ theme }) => theme.spacing(2.5, 4)};
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  border: 1px solid ${({ theme }) => theme.palette.grey[900]};
  font-family: ${({ theme }) => theme.typography.button.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.button.fontSize} !important;
  font-weight: ${({ theme }) => theme.typography.button.fontWeight} !important;
  line-height: ${({ theme }) => theme.typography.button.lineHeight} !important;
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const DialogSaveButton = styled.div<{ enableSaveButton: boolean }>`
  display: flex;
  height: ${({ theme }) => theme.spacing(9)};
  padding: ${({ theme }) => `${theme.spacing(2.5)} ${theme.spacing(4)}`};
  justify-content: center;
  align-items: center;
  cursor: ${({ enableSaveButton }) =>
    enableSaveButton ? "pointer" : "default"};
  gap: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme, enableSaveButton }) =>
    enableSaveButton ? theme.palette.primary.light : theme.palette.grey[200]};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-family: ${({ theme }) => theme.typography.button.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.button.fontSize} !important;
  font-weight: ${({ theme }) => theme.typography.button.fontWeight} !important;
  line-height: ${({ theme }) => theme.typography.button.lineHeight} !important;
  &:hover {
    background-color: ${({ theme, enableSaveButton }) =>
      enableSaveButton ? theme.palette.primary.dark : theme.palette.grey[200]};
  }
`;
