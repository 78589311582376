import styled from "styled-components";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import theme from "src/v3/theme/theme";

export const StyledFormControl = styled(FormControl)`
  min-width: 120px;
`;

export const ExpirationSelector = styled(Select)`
  height: 36px;
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const JustificationFormControl = styled(FormControl)`
  border-radius: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const StyledMenuItem = styled(MenuItem)`
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(3)};
`;

export const StyledMenuPaper = styled.div`
  border-radius: 8px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.16);
  background-color: ${({ theme }) => theme.palette.background.paper};
  margin-top: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const useStyles = () => {
  return {
    selectorMenu: {
      borderRadius: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(1),
      color: theme.palette.grey[900],
      borderColor: theme.palette.grey[900],
    },
    selector: {
      borderRadius: theme.spacing(1),
      color: theme.palette.grey[900],
      width: "110px",
    },
  };
};
