import { IconButton, Typography } from "@material-ui/core";
import styled from "styled-components";

export const DrawerHeader = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(6)};
  gap: ${({ theme }) => theme.spacing(1.3)};
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
  background: ${({ theme }) => theme.palette.background.default};
  z-index: 2;
  position: sticky;
  top: 0;
`;

export const StyledGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StatusChipAndCloseIconGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const ProgressBarRoot = styled.div`
  display: flex;
  height: 10px;
  width: 80%;
`;

export const BreadCrumb = styled.div`
  height: 100%;
`;

export const CloseIconButton = styled(IconButton)`
  padding: 0px;
  margin-right: -8px;
  margin-bottom: 12px;
`;

export const DrawerHeaderRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderContentBox = styled.div`
  display: flex;
  min-height: 50px;
  gap: ${({ theme }) => theme.spacing(4)};
  align-self: stretch;
`;

export const HeaderItemTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[900]};
  font-family: ${({ theme }) => theme.typography.h2.fontFamily} !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  overflow-wrap: break-word;
  flex: 1 0 0;
  min-width: ${({ theme }) => theme.spacing(20)};
  min-height: ${({ theme }) => theme.spacing(6)};
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const InfoGrid = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 350px;
  justify-content: flex-end;
  color: ${({ theme }) => theme.palette.grey[400]};

  font-family: ${({ theme }) => theme.typography.body2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body2.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body2.lineHeight} !important;
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight} !important;
`;

export const TaskSideInfoGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const SideInfoGrid = styled.div`
  gap: ${({ theme }) => theme.spacing(3)};
  justify-content: flex-end;
  display: flex;
`;

export const HeaderSideInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 290px;
  gap: ${({ theme }) => theme.spacing(1.3)};
`;

export const IssueTypeIconDiv = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  width: ${({ theme }) => theme.spacing(12)};
  height: ${({ theme }) => theme.spacing(12)};
`;
