import ReactQuill from "react-quill";
import CustomDialog from "../Base";
import { useSummaryOfWorkEdit } from "./useSummaryOfWorkEdit";
import { SummaryOfWorkEditProps } from "./types";
import { LastUpdatedAuthor } from "../../commomStyles/LastUpdatedAuthor";

export const SummaryOfWorkEdit = ({
  item,
  open,
  handleOpen,
}: SummaryOfWorkEditProps) => {
  const {
    extraContext,
    enableSaveButton,
    isSaving,
    handleUpdateExtraContext,
    resetState,
    onSave,
  } = useSummaryOfWorkEdit(item);

  // TODO: after deprecate the v2, remove getAISummaryField
  const summaryOfWorkField =
    item.getSummaryOfWorkField() || item.getAISummaryField();
  const override = summaryOfWorkField.getLastAddedOverride()?.value;

  const handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    resetState();
    handleOpen();
  };

  const handleSave = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    await onSave();
    handleOpen();
  };

  return (
    <CustomDialog
      open={open}
      isSaving={isSaving}
      onClose={handleClose}
      onSave={handleSave}
      title="Summary of Work - Additional Details"
      enableSaveButton={enableSaveButton}
    >
      {summaryOfWorkField.hasOverride() && (
        <LastUpdatedAuthor
          author={override?.author?.name}
          lastUpdatedDate={override.created_at}
        />
      )}
      <ReactQuill
        style={{ height: "228px" }}
        theme="snow"
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ script: "sub" }, { script: "super" }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            ["blockquote"],
            ["link"],
            ["clean"],
          ],
          clipboard: {
            matchVisual: false,
          },
        }}
        value={extraContext || ""}
        onChange={handleUpdateExtraContext}
        placeholder={"Enter additional details here..."}
      />
    </CustomDialog>
  );
};
