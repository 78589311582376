import styled from "styled-components";

export const IssueTypeIconRoot = styled.div<{
  backgroundColor: string;
  size: "small" | "medium" | "large";
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  padding: ${({ theme, size }) =>
    size === "large" ? theme.spacing(2) : theme.spacing(1)};
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme, size }) =>
    size === "large" ? theme.spacing(1.25) : theme.spacing(0.75)};
`;
