import {
  useContext,
  useCallback,
  createContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useParams } from "react-router-dom";
import { useClientConfigContext } from "src/providers/ClientConfigContext";
import { useLayout } from "src/providers/LayoutContext";
import { isLogged } from "src/services/sessionService";
import { updateExtraContext } from "src/services/v2/board";
import { EpicInfoFactory } from "src/v2/domain/entities/epicInfo/factory/EpicInfoFactory";
import {
  getGenericViews,
  getViewTeamSummary,
} from "src/v2/services/genericView";

const GenericViewContext = createContext({
  genericViews: [],
  currentViewData: { id: null, key: null, displayName: "" },
  getViewByKey: (key: string) => key,
  headerViewData: {},
  viewOptionStyle: {},
  viewExpandAllExclusions: [],
  showInsights: false,
  handleShowInsights: () => {},
  hideShowInsightsButton: false,
  handleSetTeamExtraContext: async (teamId, data) => {},
  teamSummary: {
    title: "",
    data: [],
  },
  redirectViewSlug: null,
  epicInfos: [],
  viewSchema: [],
});

function useGenericViewContext() {
  const context = useContext(GenericViewContext);
  return context;
}

function GenericViewProvider({ children, view }: any) {
  // state
  const [genericViews, setGenericViews] = useState([]);
  const [currentViewData, setCurrentViewData] = useState({});
  const [headerViewData, setHeaderViewData] = useState(null);
  const [viewOptionStyle, setViewOptionStyle] = useState({});
  const [showInsights, setShowInsightsConfig] = useState(true);
  const [redirectViewSlug, setRedirectSlug] = useState(null);
  const [hideShowInsightsButton, setHideShowInsightsButton] = useState(false);
  const [viewExpandAllExclusions, setViewExpandAllExclusions] = useState([]);
  const { setIsNavBarCollapsed } = useLayout();
  const userIsLogged = isLogged();
  const { defaultViewSchema } = useClientConfigContext();

  const [epicInfos, setEpicInfos] = useState([]);
  const [viewSchema, setViewSchema] = useState([]);

  const getViewByKey = useCallback(
    (key) => genericViews.find((v) => v.key === key),
    [genericViews]
  );

  const getHeaderViewDataFromCurrentView = useCallback(
    (currentView) => {
      const currentViewHeader = currentView?.options?.find(
        (option) => option.name === "header"
      );
      if (currentViewHeader?.value === "do_not_display") {
        return null;
      }
      if (currentViewHeader?.value) {
        return getViewByKey(currentViewHeader?.value);
      }
      return getViewByKey("roadmap_summary_custom_header");
    },
    [getViewByKey]
  );

  const getGenericOption = (currentView, optionName) => {
    const currentViewOption = currentView?.options?.find(
      (option) => option.name === optionName
    );
    return currentViewOption?.value;
  };
  // external handlers
  const getViewOptionStylesFromCurrentView = (currentView) => {
    const currentViewOptionStyle = currentView?.options?.find(
      (option) => option.name === "styles"
    );
    return currentViewOptionStyle?.value;
  };

  const getHideShowInsightsButtonConfigFromOptions = (currentView) => {
    const hideShowInsightsConfig = currentView?.options?.find(
      (option) => option.name === "hide_show_insights_button"
    );
    return !!hideShowInsightsConfig?.value;
  };
  const getShowInsightsConfigFromOptions = (currentView) => {
    const showInsightsConfig = currentView?.options?.find(
      (option) => option.name === "show_insights"
    );
    return showInsightsConfig?.value !== "false";
  };

  const getViewExpandAllExclusionsFromCurrentView = (currentView) => {
    const currentViewExpandAllExclusions = currentView?.options?.find(
      (option) => option.name === "expand_all_exclusion"
    );
    return currentViewExpandAllExclusions?.value;
  };

  const getRedirectViewSlug = (currentView) => {
    const redirect = currentView?.options?.find(
      (option) => option.name === "redirect_view"
    );
    return redirect?.value;
  };

  useEffect(() => {
    const _currentViewData = genericViews?.find(({ key }) => key === view);
    if (defaultViewSchema && _currentViewData) {
      const _headerViewData =
        getHeaderViewDataFromCurrentView(_currentViewData);
      const _viewOptionStyle =
        getViewOptionStylesFromCurrentView(_currentViewData);
      const _showInsightsConfig =
        getShowInsightsConfigFromOptions(_currentViewData);
      const _hideShowInsightsButtonConfig =
        getHideShowInsightsButtonConfigFromOptions(_currentViewData);
      const _viewExpandAllExclusions =
        getViewExpandAllExclusionsFromCurrentView(_currentViewData);
      const _redirectViewSlug = getRedirectViewSlug(_currentViewData);
      setViewOptionStyle(_viewOptionStyle);
      setHeaderViewData(_headerViewData);
      setCurrentViewData(_currentViewData);
      setViewExpandAllExclusions(_viewExpandAllExclusions);
      setShowInsightsConfig(_showInsightsConfig);
      setHideShowInsightsButton(_hideShowInsightsButtonConfig);
      setIsNavBarCollapsed(
        getGenericOption(_currentViewData, "collapse_navbar") ?? false
      );
      setRedirectSlug(_redirectViewSlug);
      setEpicInfos(getEpicInfos(_currentViewData));
      setViewSchema(_currentViewData.view_schema ?? defaultViewSchema);
    }
  }, [
    genericViews,
    view,
    defaultViewSchema,
    getHeaderViewDataFromCurrentView,
    setIsNavBarCollapsed,
  ]);

  const handleSetTeamExtraContext = async (
    teamId: string,
    extraContext: any
  ) => {
    const team = teamSummary?.data?.find((team) => team.id === teamId);
    if (team) {
      team.extra_context = extraContext;
      try {
        return updateExtraContext(teamId, extraContext).then(() => {
          setTeamSummary({ ...teamSummary });
        });
      } catch (e) {
        console.error(e);
        new Error("Failed to update team summary");
      }
    }
  };

  const getEpicInfos = (currentView) =>
    (
      currentView?.options?.find((option) => option.name === "epic_infos")
        ?.value || []
    ).map((icon) => EpicInfoFactory.getEpicInfoInstance(icon));

  const [teamSummary, setTeamSummary] = useState({
    title: "",
    data: [],
  });

  useEffect(() => {
    if (currentViewData?.id) {
      const fetchTeamAISummary = async () => {
        function processAISummary(
          data: any,
          currentViewData: { id: any; key: any }
        ) {
          const sortedByName = data.sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            return 0;
          });
          const processedAiSummary = sortedByName.map((team) => {
            const { key } = currentViewData;
            const { ai_summary } = team;
            if (!ai_summary) return team;
            let aiSummaryHtml = ai_summary[key];
            if (!aiSummaryHtml) {
              aiSummaryHtml = ai_summary["default"];
            }
            let latestExtraContext = {};

            const extraContext = team.extra_context?.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );

            if (extraContext?.length > 0) {
              latestExtraContext = extraContext[0];
            }

            return {
              ...team,
              ai_summary: aiSummaryHtml,
              extra_context: latestExtraContext,
            };
          });
          return processedAiSummary;
        }

        getViewTeamSummary(currentViewData.id).then((data) => {
          const processedAiSummary = processAISummary(data, currentViewData);
          if (processedAiSummary.length > 0) {
            let newestUpdatedAt;

            processedAiSummary.forEach((team) => {
              const { updated_at } = team;
              const updatedAt = new Date(updated_at);
              if (!newestUpdatedAt) {
                newestUpdatedAt = updatedAt;
              } else if (newestUpdatedAt < updatedAt) {
                newestUpdatedAt = updatedAt;
              }
            });
            const title = `${newestUpdatedAt.toLocaleString("default", {
              month: "long",
            })} ${newestUpdatedAt.getDate()}, ${newestUpdatedAt.getFullYear()}: Team Summary`;

            setTeamSummary({
              title,
              data: processedAiSummary,
            });
          }
        });
      };
      fetchTeamAISummary();
    }
  }, [currentViewData]);

  // effects
  useEffect(() => {
    if (userIsLogged) {
      getGenericViews().then((data) => {
        setGenericViews(data);
      });
    }
  }, []);

  function handleShowInsights() {
    setShowInsightsConfig(!showInsights);
  }

  const value = useMemo(
    () => ({
      genericViews,
      currentViewData,
      headerViewData,
      viewOptionStyle,
      getViewByKey,
      viewExpandAllExclusions,
      showInsights,
      handleShowInsights,
      hideShowInsightsButton,
      teamSummary,
      redirectViewSlug,
      epicInfos,
      viewSchema,
      handleSetTeamExtraContext,
    }),
    [
      genericViews,
      currentViewData,
      headerViewData,
      viewOptionStyle,
      getViewByKey,
      viewExpandAllExclusions,
      showInsights,
      handleShowInsights,
      hideShowInsightsButton,
      teamSummary,
      redirectViewSlug,
      epicInfos,
      viewSchema,
    ]
  );

  return (
    <GenericViewContext.Provider value={value}>
      {children}
    </GenericViewContext.Provider>
  );
}

export { GenericViewContext, GenericViewProvider, useGenericViewContext };
