import { DrawerFooterRoot, FooterCloseButton } from "./styles";

export const ItemDetailerDrawerFooter = ({
  handleClose,
}: {
  handleClose: () => void;
}) => (
  <DrawerFooterRoot>
    <FooterCloseButton onClick={handleClose}>Close</FooterCloseButton>
  </DrawerFooterRoot>
);
