import styled from "styled-components";
import { MenuItem } from "@material-ui/core";

export const ViewSelectorRoot = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  align-self: stretch;
  cursor: pointer;
  width: 300px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 250px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;

export const ViewSelectionItem = styled(MenuItem)`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-self: stretch;
  min-width: 130px;
  height: 100%;
  color: ${({ theme }) => theme.palette.grey[900]} !important;
  font-family: ${({ theme }) => theme.typography.body2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body2.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body2.lineHeight} !important;
  padding: ${({ theme }) => theme.spacing(3)};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
`;

export const ViewText = styled.div`
  color: ${({ theme }) => theme.palette.grey[900]};
  text-align: right;
  font-family: ${({ theme }) => theme.typography.body2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body2.fontSize} !important;
  font-weight: 400 !important;
  line-height: ${({ theme }) => theme.typography.body2.lineHeight} !important;
`;

export const ViewSelectorText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.grey[900]};
  font-family: ${({ theme }) => theme.typography.body2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body2.fontSize} !important;
  font-weight: 600 !important;
  line-height: ${({ theme }) => theme.typography.body2.lineHeight} !important;
`;

export const PaperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.background.paper};
  background: ${({ theme }) => theme.palette.background.default};
  width: max-content;
  box-shadow: ${({ theme }) => theme.shadows[1]};
`;
