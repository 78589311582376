import { MenuItem, Typography } from "@material-ui/core";
import { FilterContainer, FilterHeader } from "../FilterPopper/styles";
import { QuickFilterProps } from "./types";
import { DeleteFilterModal } from "../FilterPopper/DeleteFilterModal";
import React from "react";
import { useFilterContext } from "src/v2/providers/filter/FilterContext";

export const VelmaFilters: React.FC<{ filters: any[]; label: string }> = ({
  filters,
  label,
}) => {
  return (
    <QuickFilters
      label={label}
      filters={filters}
      MenuItemComponent={({ filter, onClick }) => (
        <MenuItem
          onClick={onClick}
          key={filter.id}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography>{filter.name}</Typography>
        </MenuItem>
      )}
    />
  );
};

export const UserFilters: React.FC<{ filters: any[]; label: string }> = ({
  filters,
  label,
}) => {
  return (
    <QuickFilters
      label={label}
      filters={filters}
      MenuItemComponent={({ filter, onClick }) => (
        <>
          <MenuItem
            onClick={onClick}
            key={filter.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>{filter.name}</Typography>
          </MenuItem>
          <DeleteFilterModal filter={filter} />
        </>
      )}
    />
  );
};

const QuickFilters: React.FC<QuickFilterProps> = ({
  filters,
  label,
  MenuItemComponent,
}) => {
  const { handleApplyFiltersFromURL } = useFilterContext();

  return (
    <FilterContainer>
      <FilterHeader align="right">{label}</FilterHeader>
      {filters.map((filter) => (
        <div
          key={filter.id}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px",
          }}
        >
          {MenuItemComponent({
            filter,
            onClick: () => handleApplyFiltersFromURL(filter.url),
          })}
        </div>
      ))}
    </FilterContainer>
  );
};
