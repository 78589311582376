import styled from "styled-components";

export const FilterButtonContainer = styled.div`
  text-transform: none;
  color: white;
  background-color: "transparent";
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
`;
