import theme from "src/v3/theme/theme";
import styled from "styled-components";

export const UserInitialsContainer = styled.div`
  width: 37px;
  border-radius: 100px;
  background-color: #08090c;
  border: 0.5px solid #fff;
  box-sizing: border-box;
  height: 37px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
`;

export const MenuPaperProps = {
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[100]}`,
  boxShadow: theme.shadows[1],
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minWidth: "135px",
};

export const UserInitials = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const Avatar = styled.div`
  width: 49px;
  height: 49px;
  border-radius: 100px;
  background-color: #08090c;
  border: 0.5px solid #fff;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserName = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 14px;
  color: #08090c;
  text-align: "center";
`;

export const MenuRow1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 12px 16px;
  gap: 12px;
  font-size: 18px;
  color: #fff;
  text-align: center;
`;

export const Line = styled.div`
  flex: 1;
  position: relative;
  border-top: 1px solid #d3d5d7;
  box-sizing: border-box;
  height: 1px;
`;

export const Divider = styled.div`
  align-self: stretch;
  height: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const MenuItemName = styled.div`
  flex: 1;
  position: relative;
`;

export const MenuRow11 = styled.div`
  align-self: stretch;
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 6px 8px 10px;
  box-sizing: border-box;
`;

export const MenuRow6 = styled.div`
  width: 222px;
  background-color: #fff;
  height: 42px;
  display: none;
`;
