import { CustomFieldFactory } from "src/v2/domain/entities/customField/factory";
import { FieldConfigType } from "../../enum/FieldConfigType";
import { CustomFieldBase } from "../customField/CustomFieldBase";
import { StatusField } from "../customField/StatusField";
import { BlockItemType } from "./BlockItemType";
import { BlockItemLevel } from "../../enum/BlockItemLevel";
import { ProgressField } from "../customField/ProgressField";
import { NameField } from "../customField/NameField";
import { LastJiraUpdatedDateField } from "../customField/LastJiraUpdatedDateField";
import * as Sentry from "@sentry/react";
import { ProjectStatusField } from "../customField/ProjectStatusField";
import { HTMLField } from "../customField/AISummary/HTMLField";
import { RiskComparisonDateField } from "../customField/RiskComparisonDateField";
import { ProjectedFinishField } from "../customField/ProjectedFinishField";
import { RisksField } from "../customField/Risk/RisksField";

// v3 imports
import { SummaryOfWorkField } from "../../../../v3/entities/CustomFields/SummaryOfWork";
import { StatusField as WorkflowStatusField } from "src/v3/entities/CustomFields/StatusField";
import { ProjectStatusField as ProjectStatusV3 } from "src/v3/entities/CustomFields/ProjectStatusField";
import { TargetDateField } from "src/v3/entities/CustomFields/TargetDateField";
import { CommentsField } from "src/v3/entities/CustomFields/CommentsField";
import { AssigneeField } from "../customField/AssigneeField";
import { DependencyField } from "../customField/DependencyField";
import { TeamField } from "../customField/TeamField";
import { SprintField } from "src/v3/entities/CustomFields/SprintField";
import { TextField } from "../customField/TextField";
import { AllocationField } from "src/v3/entities/CustomFields/Allocation/AllocationField";

export class BlockItem {
  id: number;

  parentId: number;

  displayName: string;

  origin: string;

  type: BlockItemType;

  customFields: CustomFieldBase[];

  children: any[];

  changelogInfo: {
    addedToParentAt: string;
    lastComment: any;
  };

  // NewUI Mocks
  isRoadmapItem: boolean;
  summary: string;

  constructor(item) {
    try {
      this.customFields = item.custom_fields
        .filter(({ field_key }) => {
          return field_key !== "project_status_insights";
        })
        .map((field) => {
          return CustomFieldFactory.getCustomFieldInstance(field);
        });

      item.user_overrides?.forEach((override) => {
        this.updateOverrideValues(override);
      });
      this.changelogInfo = this.setChangelogInfo(item);
      this.displayName = item.display_name;
      if (!this.displayName) {
        const warn = `BlockItem: displayName is empty for ${item.type.view_block_type} with id ${item.id}`;
        console.warn(warn);
        Sentry.captureException(warn);
      }
      this.origin = item.origin;
      this.id = item.id;
      this.type = new BlockItemType({ ...item.type });
      this.parentId = item.parent_id;
      const _children = item.children.map((block) => new BlockItem(block));
      this.setChildren(_children);

      this.isRoadmapItem = item.is_roadmap_item;
    } catch (error) {
      console.warn(
        `Error creating BlockItem: db view block with id ${item.id}`
      );
      console.warn("Potentially missing field in fields config");
      console.warn("Might be related with cache sending old fields config");
      console.warn(
        "Try clearing the cache for this view. However, proceed with caution because the cache invalidation occurs during the request. If the query is too slow, this may not work. As a workaround, initially disassociate some boards, invalidate the cache, then reassociate the boards and open the view to complete the cache invalidation."
      );
      console.warn(error);
    }
  }

  getChildren(): BlockItem[] {
    return this.children?.length > 0 ? this.children : [];
  }

  updateOverrideValues(override) {
    const field = this.getFieldByFieldKey(override.fieldKey);
    if (field) {
      field.setOverrideValue(override);
    }
  }

  getDisplayName(): string {
    return this.displayName ?? "";
  }

  setChangelogInfo(item) {
    if (item.changelogInfo) {
      return item.changelogInfo;
    }

    let addedDatesField = null;
    addedDatesField = this.getFieldByType(FieldConfigType.children_added_dates);

    if (!addedDatesField) {
      addedDatesField = this.getFieldByType(FieldConfigType.parent_added_date);
    }
    if (addedDatesField?.value?.length > 0) {
      addedDatesField.value = addedDatesField.value[0];
    }
    const addedAt = addedDatesField?.value?.created_at;
    return {
      addedToParentAt: addedAt || "",
      lastComment: {},
    };
  }

  setChildren(children) {
    this.children = children;
    this.updateProgressField();
  }

  getLastJiraUpdatedDateField() {
    return this.getFieldByType(
      FieldConfigType.last_jira_updated_date
    ) as LastJiraUpdatedDateField;
  }

  getCostToDateField() {
    return this.getFieldByFieldKey("cost_to_date");
  }

  getProjectedTotalCostField() {
    return this.getFieldByFieldKey("projected_total_cost");
  }

  getNameField() {
    return this.getFieldByPossibleTypes([
      FieldConfigType.name,
      FieldConfigType.condensed_name,
    ]) as NameField;
  }

  getParentLinkField() {
    return this.getFieldByType(FieldConfigType.parentLink);
  }

  getKey() {
    const nameField = this.getNameField();

    if (nameField) {
      return nameField.key;
    }

    throw new Error("Item doesn't have a name field");
  }

  isBug() {
    return this.type.displayName.toLocaleLowerCase() === "bug";
  }

  updateCosts() {
    if (this.children?.length === 0) return;
    const childrenCosts = this.getChildrenCosts();
    const costToDateField = this.getCostToDateField();
    const projectedTotalCostField = this.getProjectedTotalCostField();
    if (!costToDateField || !projectedTotalCostField) {
      console.log(
        "costToDateField or projectedTotalCostField is null for item: " +
          this.displayName +
          " with id: " +
          this.id +
          " and key: " +
          this.getKey()
      );
      return;
    }
    costToDateField.updateValue([childrenCosts.costToDate]);
    projectedTotalCostField.updateValue([childrenCosts.projectedCost]);
  }

  private getChildrenCosts() {
    const childrenCosts = {
      costToDate: null,
      projectedCost: null,
    };
    this.children.forEach((child) => {
      const costToDateField = child.getCostToDateField();
      const projectedTotalCostField = child.getProjectedTotalCostField();

      const costToDateValue = costToDateField?.getIntegerValue();
      if (costToDateValue) childrenCosts.costToDate += costToDateValue;

      const projectedCostValue = projectedTotalCostField?.getIntegerValue();
      if (projectedCostValue) childrenCosts.projectedCost += projectedCostValue;
    });

    return childrenCosts;
  }

  updateProgressField() {
    if (this.children.length > 0) {
      const progressField = this.getProgressField() as ProgressField;
      if (!progressField) return;
      progressField.updateProgress(this.children);
    }
  }

  typeIs(type: string) {
    return this.type.value.toLocaleLowerCase() === type.toLocaleLowerCase();
  }

  isProject() {
    return this.type.value.toLocaleLowerCase() === BlockItemLevel.PROJECT;
  }

  isDeliverable() {
    return this.type.value.toLocaleLowerCase() === BlockItemLevel.DELIVERABLE;
  }

  isTask() {
    return this.type?.value?.toLocaleLowerCase() === BlockItemLevel.TASK;
  }

  hasChildren() {
    return this.getChildren()?.length > 0;
  }

  getCountChildrenDependencies() {
    const childrenWithDependencies = this.children.filter(
      (child: BlockItem) => {
        const childDependencyField = child.getDependencyField();
        if (!childDependencyField) return false;
        return (
          childDependencyField.getChildrenWithDependenciesCount() > 0 ||
          childDependencyField.getBlockedBy().length > 0
        );
      }
    );
    return childrenWithDependencies.length;
  }

  getCustomFieldValue(filterKey) {
    const customField = this.getFieldByFieldKey(filterKey);
    return customField?.getValue();
  }

  isVelmaOrigin() {
    return this.origin === "velma";
  }

  getJiraItemCustomFieldValues(filterKey) {
    const velmaItemWithChildren = this.isVelmaOrigin() && this.hasChildren();
    if (velmaItemWithChildren) {
      return this.children.map((child) =>
        child.getJiraItemCustomFieldValues(filterKey)
      );
    }
    const itemCustomFieldValue = this.getCustomFieldValue(filterKey);
    if (!itemCustomFieldValue) {
      throw new Error(
        `Item doesn't have a custom field with ${filterKey} key `
      );
    }
    return itemCustomFieldValue[0];
  }

  getPossibleValueForField({ fieldKey, includeItemValue = false }) {
    const childrenValues = this.children.map((child) =>
      child.getPossibleValueForField({ fieldKey, includeItemValue: true })
    );
    const customFieldValue = this.getCustomFieldValue(fieldKey);

    const values = [...childrenValues];
    if (includeItemValue && customFieldValue) {
      values.push(customFieldValue);
    }
    const result = {};

    values
      .filter((value) => value)
      .forEach((value) => {
        if (Array.isArray(value)) {
          value
            .filter((item) => item)
            .forEach((_value) => {
              // Using _value.name because project_status_insights has a different structure
              const key = _value.name || _value;
              result[key] = key;
            });
        } else {
          const key = value.name || value;
          result[key] = key;
        }
      });

    return Object.keys(result);
  }

  getChildrenAggregationByField({
    fieldKey,
    fieldType,
    fieldProperty = "value",
  }) {
    const groupedProjectStatus = this.children.reduce((acc, child) => {
      const field = child.getField({ fieldKey, fieldType });
      const fieldValue = field.getValueForProperty(fieldProperty);
      if (fieldValue.length > 0) {
        fieldValue.forEach((value) => {
          if (!acc[value]) {
            acc[value] = [];
          }
          acc[value].push(child);
        });
      } else {
        if (!acc[""]) {
          acc[""] = [];
        }
        acc[""].push(child);
      }
      return acc;
    }, {});
    return groupedProjectStatus;
  }

  getField({ fieldKey, fieldType }) {
    if (fieldKey) {
      return this.getFieldByFieldKey(fieldKey);
    }
    if (fieldType) {
      return this.getFieldByType(fieldType);
    }
    return undefined;
  }

  getFieldByFieldKey(itemFieldKey: string) {
    return this.customFields.find(({ fieldKey }) => fieldKey === itemFieldKey);
  }
  getAllocationField() {
    return this.getFieldByFieldKey("allocation") as AllocationField;
  }
  getProgressField() {
    return this.getFieldByPossibleTypes([
      FieldConfigType.progress,
      FieldConfigType.condensed_progress,
    ]) as ProgressField;
  }

  getFieldByPossibleTypes(fieldTypes: FieldConfigType[]) {
    return fieldTypes
      .map((fieldType) => this.getFieldByType(fieldType))
      .find((field) => field);
  }

  getFieldByType(fieldType) {
    return this.customFields.find(({ type }) => type === fieldType);
  }

  getStatusField() {
    return this.getFieldByType(FieldConfigType.status) as StatusField;
  }

  isCompleted() {
    return this.getStatusField()?.category === StatusField.CATEGORY_DONE;
  }

  getPriorityField() {
    return this.getFieldByType(FieldConfigType.priority);
  }

  getRiskField() {
    return this.getFieldByType(FieldConfigType.risks) as RisksField;
  }

  getProjectStatusFieldV2() {
    return this.getFieldByPossibleTypes([
      FieldConfigType.project_status,
      FieldConfigType.project_status_insights,
      FieldConfigType.condensed_project_status_insights,
      FieldConfigType.detailed_project_status_insights,
    ]) as ProjectStatusField;
  }

  getRiskComparisonDateField() {
    return this.getField({
      fieldKey: "risk_comparison_date",
      fieldType: FieldConfigType.risk_comparison_date,
    }) as RiskComparisonDateField;
  }
  getProjectedDateField() {
    return this.getFieldByType(
      FieldConfigType.projected_finish
    ) as ProjectedFinishField;
  }

  getAISummaryField() {
    return this.getFieldByType(FieldConfigType.html) as HTMLField;
  }

  getTeamFieldValue() {
    return this.getFieldByPossibleTypes([
      FieldConfigType.team,
      FieldConfigType.team_link,
    ])?.value;
  }

  // getters used in V3

  getMappedBlockType() {
    const mapper = {
      Epic: "Project",
      Initiative: "Project",
    };
    return mapper[this.getBlockTypeName()];
  }

  getBlockType() {
    return this.type.block_type;
  }

  getBlockTypeName() {
    return this.type.displayName;
  }

  getAssigneeField() {
    return this.getFieldByType(FieldConfigType.assignee) as AssigneeField;
  }

  getDependencyField() {
    return this.getFieldByType(FieldConfigType.dependency) as DependencyField;
  }

  // getters created in V3

  getSprintField() {
    return this.getFieldByType(FieldConfigType.sprint) as SprintField;
  }

  getDaysInProgressField() {
    return this.getFieldByFieldKey("days_in_progress") as TextField;
  }

  getSummaryOfWorkField() {
    return this.getFieldByType(
      FieldConfigType.summary_of_work
    ) as SummaryOfWorkField;
  }

  getWorkflowStatusField() {
    return this.getFieldByType(
      FieldConfigType.workflow_status
    ) as WorkflowStatusField;
  }

  getProjectStatusField() {
    return this.getFieldByType(
      FieldConfigType.project_status_v3
    ) as ProjectStatusV3;
  }

  getTargetDateField() {
    return this.getFieldByType(FieldConfigType.target_date) as TargetDateField;
  }

  getCommentsField() {
    return this.getFieldByType(FieldConfigType.comments) as CommentsField;
  }

  getTeamField() {
    return this.getFieldByType(FieldConfigType.team) as TeamField;
  }
}
