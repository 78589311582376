import styled from "styled-components";

export const DrawerFooterRoot = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(6)};
  height: "100px";
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const FooterCloseButton = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(3, 6)};
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  border: 1px solid ${({ theme }) => theme.palette.grey[900]};
  font-family: ${({ theme }) => theme.typography.h2.fontFamily} !important;
  font-size: 14px !important;
  font-weight: ${({ theme }) => theme.typography.h2.fontWeight} !important;
  line-height: ${({ theme }) => theme.typography.h2.lineHeight} !important;
  color: ${({ theme }) => theme.palette.grey[900]};
  text-transform: none;
  cursor: pointer;
`;
