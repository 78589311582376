import styled from "styled-components";

export const FilteredByButton = styled.div`
  display: flex;
  height: 30px;
  width: fit-content;
  padding: 0px var(--Numeric-Spacer-8, 8px) 0px 10px;
  align-items: center;
  gap: var(--Numeric-Spacer-8, 8px);
  border-radius: var(--Numeric-Radius-buttons, 4px);
  background: var(--Surface-Surface-brand-2, #a3cae5);
  cursor: pointer;
`;
