import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { formatDate } from "src/utils/stringUtils";
import { isEmpty } from "src/v3/utils/object";
import { isDevMode } from "src/services/sessionService";
import { ItemDetailAccordion } from "src/v3/widgets/ItemDetails/commonStyles/ItemDetailAccordion";

export const HistoricalAllocationChart = ({ item }: { item: BlockItem }) => {
  if (!isDevMode()) {
    return null;
  }

  const allocationField = item.getAllocationField();
  if (!allocationField) return null;
  const allocationDetails = allocationField.getAllocationDetails();
  if (isEmpty(allocationDetails?.allocations)) return null;

  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const handleToggleAccordion = () => {
    setIsAccordionExpanded((prev) => !prev);
  };

  const chartRef = useRef(null);
  useEffect(() => {
    if (!isAccordionExpanded) return;
    const chartInstance = echarts.init(chartRef.current);
    const dates = Object.keys(allocationDetails.allocations);
    const allocations = dates.map(
      (date) =>
        Math.round(allocationDetails.allocations[date].allocation * 100) / 100
    );

    const { startDate, endDate } = allocationDetails.significantWork;

    const options = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      legend: {
        data: ["Allocation", "Moving Average"],
        bottom: 0,
        selectedMode: false,
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: dates.map((date) => formatDate(date)),
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value}",
        },
        axisPointer: {
          snap: true,
        },
      },
      series: [
        {
          name: "Allocation",
          type: "line",
          smooth: true,
          data: allocations,
          areaStyle: {
            color: "rgba(173, 216, 230, 0.6)",
          },
          lineStyle: {
            color: "#4682B4",
          },
          itemStyle: {
            color: "#4682B4",
          },
          markArea: {
            itemStyle: {
              color: "rgba(255, 0, 0, 0.2)",
            },
            data:
              startDate && endDate
                ? [
                    [
                      {
                        name: "Significant Work",
                        xAxis: formatDate(startDate),
                      },
                      {
                        xAxis: formatDate(endDate),
                      },
                    ],
                  ]
                : [],
          },
        },
      ],
    };

    chartInstance.setOption(options);

    const handleResize = () => {
      chartInstance.resize();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      chartInstance.dispose();
      window.removeEventListener("resize", handleResize);
    };
  }, [allocationDetails, isAccordionExpanded]);

  return (
    <ItemDetailAccordion
      title={"Distribution of Allocation and Contributors"}
      expanded={isAccordionExpanded}
      setExpanded={handleToggleAccordion}
      actions={[]}
    >
      <div
        ref={chartRef}
        style={{
          width: "100%",
          minHeight: "300px",
          display: "flex",
        }}
      />
    </ItemDetailAccordion>
  );
};
