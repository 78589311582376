import { useGenericViewContext } from "src/v2/providers/genericView/GenericViewContext";
import { isEmpty } from "src/v2/utils/object";

import LoadingViewWidget from "./LoadingWidget";
import { RoadmapGenericViewV2 } from "./RoadmapGenericView";
import { useUIV3 } from "src/v3/providers/useUIV3";
import { GenericViewV3 } from "src/v3/components/GenericView";
import { TopBar } from "src/v3/components/TopBar";
import { GenericViewRoot } from "./styles";
import theme from "src/v3/theme/theme";
import themeV2 from "src/theme";
import { MuiThemeProvider } from "@material-ui/core";
import { ThemeProvider } from "styled-components";
import { useBlockContext } from "src/v2/providers/block/BlockContext";
import { NavBarV2 } from "src/components/NavBar";

export const RoadmapGenericViewBuilder = () => {
  const { isV3 } = useUIV3();
  const { currentViewData } = useGenericViewContext();
  const { originalItems } = useBlockContext();
  const isViewEmpty = isEmpty(currentViewData) || originalItems?.length === 0;

  if (isViewEmpty) return <LoadingViewWidget />;
  const _theme = isV3 ? theme : themeV2;

  return (
    <MuiThemeProvider theme={_theme}>
      <ThemeProvider theme={_theme}>
        <GenericViewRoot>
          {isV3 && <TopBar />}
          {!isV3 && <NavBarV2 />}
          {isV3 ? <GenericViewV3 /> : <RoadmapGenericViewV2 />}
        </GenericViewRoot>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};
