import styled from "styled-components";
import { Breadcrumbs, Typography } from "@material-ui/core";

export const CustomBreadCrumb = styled(Breadcrumbs)`
  display: flex;
  padding: ${({ theme }) => theme.spacing(1.3, 6, 1.3, 3)};
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
  border-left: ${({ theme }) => theme.spacing(1)} solid
    ${({ theme }) => theme.palette.primary.main};
  border-top-left-radius: ${({ theme }) => theme.spacing(1.25)};
  border-bottom-left-radius: ${({ theme }) => theme.spacing(1.25)};
  overflow: hidden;
  color: ${({ theme }) => theme.palette.primary.main};
  text-overflow: ellipsis;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-bottom-right-radius: ${({ theme }) => theme.spacing(1.25)};
  border-top-right-radius: ${({ theme }) => theme.spacing(1.25)};
  ${({ customStyle }) => customStyle};
`;

export const GroupBreadcrumbRoot = styled(CustomBreadCrumb)`
  width: 30%;
  margin-bottom: ${({ theme }) => theme.spacing(2)} !important;
`;

export const BreadCrumbItem = styled.span`
  ${({ theme }) => theme.typography.h2};
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const NavigationBreadcrumbRoot = styled(Breadcrumbs)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const CurrentNavigationBreadcrumbItem = styled(Typography)`
  ${({ theme }) => theme.typography.body1};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  gap: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const NavigationBreadcrumbItem = styled(Typography)`
  ${({ theme }) => theme.typography.body2};
  color: ${({ theme }) => theme.palette.grey[900]};
  text-decoration: underline;
  cursor: pointer;
  gap: ${({ theme }) => theme.spacing(2)};
`;
