import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const MainGrid = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(3)};
  gap: ${({ theme }) => theme.spacing(3)};
  align-self: "stretch";
  width: "100%";
`;

export const MainRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
