import React from "react";
import { Box } from "@material-ui/core";
import { ChevronDown } from "src/v3/theme/icons/general";
import {
  ExpirationSelector,
  StyledFormControl,
  StyledMenuItem,
  StyledMenuPaper,
  useStyles,
} from "./styles";

export const RetainOverride = ({
  stateChanged,
  expirationDate,
  handleExpirationDateChange,
}: {
  stateChanged: boolean;
  expirationDate: string;
  handleExpirationDateChange: (
    e: React.ChangeEvent<{ value: unknown }>
  ) => void;
}) => {
  const expirationWeekOptions = ["1 week", "2 weeks", "3 weeks", "4 weeks"];

  const styles = useStyles();

  return (
    <Box
      style={{
        height: "48px",
        backgroundColor: "transparent",
      }}
    >
      <StyledFormControl variant="outlined" disabled={!stateChanged}>
        <ExpirationSelector
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={expirationDate}
          onChange={handleExpirationDateChange}
          required
          style={styles.selector}
          MenuProps={{
            PaperProps: {
              component: StyledMenuPaper,
              style: styles.selectorMenu,
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          IconComponent={ChevronDown}
        >
          {expirationWeekOptions.map((option, index) => (
            <StyledMenuItem key={index} value={option}>
              {option}
            </StyledMenuItem>
          ))}
        </ExpirationSelector>
      </StyledFormControl>
    </Box>
  );
};
