import styled from "styled-components";

export const Hierarchy = styled.div`
  position: relative;
`;

export const Select = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
`;

export const Field = styled.div`
  align-self: stretch;
  border-radius: 2px;
  background-color: #fff;
  border: ${({ theme }) => `1px solid ${theme.palette.grey[400]}`};
  box-sizing: border-box;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 8px 6px 12px;
  gap: 8px;
  width: 100%;
  border-radius: 4px;
`;

export const Top = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const Dropdown = styled.div`
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  color: #08090c;
  cursor: pointer;
`;

export const HierarchyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  text-align: right;
  color: #000a11;
`;

export const HierarchyOptionContainer = styled.div<{ selected }>`
  position: relative;
  height: auto;
  width: 170px;
  height: 160px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ selected, theme }) =>
    selected
      ? theme.palette.primary.lighter
      : theme.palette.primary.contrastText};
  border: 1px solid
    ${({ selected, theme }) => (selected ? "#197bbd" : theme.palette.grey[200])};

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const OptionName = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-family: Inter;
  color: #08090c;
  text-align: left;
  margin-bottom: 12px;
  margin-left: 0;
`;

export const LevelContainer = styled.div<{ levelIndex }>`
  display: flex;
  align-items: center;
  margin-left: ${({ levelIndex }) => levelIndex * 16}px;
  margin-top: ${({ levelIndex }) => (levelIndex > 0 ? 12 : 0)}px;
  position: relative;
`;

export const DividerLine = styled.div<{ levelIndex }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  top: 2px;
  left: -16px;
`;

export const LevelBox = styled.div`
  width: fit-content;
  position: relative;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #929497;
  padding: 4px 8px;
  text-align: left;
  font-size: 12px;
  color: #08090c;
  font-family: Inter;
  min-width: 100px;
`;

export const StructureName = styled.div`
  position: relative;
  width: 100%;
`;
