import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
} from "@material-ui/core";
import styled from "styled-components";

export const WarningGrid = styled(Grid)`
  gap: ${({ theme }) => theme.spacing(4)};
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(10)};
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const TitleTypography = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.h2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.h2.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.h2.lineHeight} !important;
  font-weight: ${({ theme }) => theme.typography.h2.fontWeight} !important;
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const JustificationTypography = styled(Typography)`
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  margin-top: 32px;
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const JustificationTextField = styled(TextField)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  border: ${({ theme }) => theme.spacing(0.5)} solid
    ${({ theme }) => theme.palette.grey[300]};
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const RetainFormControlLabel = styled(FormControlLabel)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.grey[900]};
`;

export const RetainLabelTypography = styled(Typography)`
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
`;
