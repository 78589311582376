import { truncateStringInTheEnd } from "src/utils/stringUtils";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";

export const getItemURL = (item: BlockItem) => {
  if (item.origin === "velma") return undefined;
  const itemURL = item.getNameField()?.getURL();
  if (itemURL && itemURL !== "") return itemURL;
  return undefined;
};

export const getItemName = (item: BlockItem) => {
  if (item.origin === "velma")
    return `${truncateStringInTheEnd(item.displayName, 120)}`;
  return `${truncateStringInTheEnd(item.displayName, 120)} (${item.getKey()})`;
};
