import React, { useState } from "react";
import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import { ItemRow } from "src/v3/components/ItemRow";
import { StatusChipTypes } from "src/v3/components/StatusChip/types";
import { SortContainer } from "src/v3/dataContainers/SortContainer";
import { useItemDetailNavigation } from "src/v3/providers/useItemDetailNavigation";
import { ItemDetailAccordion } from "../../commonStyles/ItemDetailAccordion";
import { MainGrid, MainRow } from "./style";
import { getSideInfos } from "./utils";
import { StatusField } from "src/v3/entities/CustomFields/StatusField";
import { ProjectStatusField } from "src/v3/entities/CustomFields/ProjectStatusField";

const RenderItemRow = ({
  item,
  statusField,
}: {
  item: BlockItem;
  statusField: ProjectStatusField | StatusField;
}) => {
  const { handleOpenItemDetails } = useItemDetailNavigation();
  const sideInfos = getSideInfos(item);

  return (
    <ItemRow
      key={item.getKey()}
      displayName={item.displayName}
      itemKey={item.getKey()}
      summaryField={item.getSummaryOfWorkField()}
      issueType={item.type}
      statusField={
        statusField instanceof StatusField
          ? item.getWorkflowStatusField()
          : item.getProjectStatusField()
      }
      statusChipType={
        statusField instanceof StatusField
          ? StatusChipTypes.WorkflowStatus
          : StatusChipTypes.ProjectStatus
      }
      sideInfos={sideInfos}
      onNameClick={() => {
        handleOpenItemDetails(item);
      }}
    />
  );
};

export const ItemsAccordion = ({
  items,
  title,
}: {
  items: BlockItem[];
  title: string;
}) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const handleToggleAccordion = React.useCallback(() => {
    setIsAccordionExpanded((prev) => !prev);
  }, []);

  const allItemsAreTasks = items?.every((item) => item.isTask());
  const statusFieldInstance = allItemsAreTasks
    ? items?.[0]?.getWorkflowStatusField()
    : items?.[0]?.getProjectStatusField();

  return (
    <ItemDetailAccordion
      title={title}
      actions={[]}
      expanded={isAccordionExpanded}
      setExpanded={handleToggleAccordion}
    >
      {items?.length > 0 && (
        <MainGrid container>
          {SortContainer({ items }).map((item: BlockItem) => (
            <MainRow>
              <RenderItemRow item={item} statusField={statusFieldInstance} />
            </MainRow>
          ))}
        </MainGrid>
      )}
    </ItemDetailAccordion>
  );
};
