import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useStyles } from "./style";
import {
  ChevronLeft,
  ChevronRight,
  DatePicker,
} from "src/v3/theme/icons/general";
import IconComponent from "../Icons/IconComponent";

const CustomDatePicker = ({
  handleDateChange,
  selectedDate,
}: {
  selectedDate: Date | null | "";
  handleDateChange: (date: Date | null) => void;
}) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        variant="inline"
        format="MM/dd/yyyy"
        margin="none"
        id="custom-date-picker"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        PopoverProps={{
          PaperProps: {
            className: classes.popover,
          },
        }}
        ToolbarComponent={() => null}
        leftArrowButtonProps={{
          style: { backgroundColor: "transparent" },
        }}
        rightArrowButtonProps={{
          style: { backgroundColor: "transparent" },
        }}
        keyboardIcon={<IconComponent Icon={DatePicker} />}
        leftArrowIcon={<IconComponent Icon={ChevronLeft} />}
        rightArrowIcon={<IconComponent Icon={ChevronRight} />}
        className={classes.datePicker}
        error={!selectedDate}
        helperText={!selectedDate && "Required"}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CustomDatePicker;
