import styled from "styled-components";
import { Typography } from "@material-ui/core";

export const UnderMaintenanceRoot = styled.div`
  background-color: rgb(248, 252, 255);
  height: 100vh;
`;

export const MainText = styled(Typography)`
  font-weight: 600;
  line-height: 1.6;
  text-align: center;
  color: ${({ theme }) => theme.palette.grey[700]};
  margin-bottom: 64px;
`;

export const TipText = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.h1.fontFamily};
  font-size: 22px;
  margin-top: 16px;
  color: ${({ theme }) => theme.palette.grey[800]};
  text-align: center;
`;

export const EndMessage = styled(TipText)`
  margin-top: 64px;
`;

export const VelmaIcon = styled.img`
  width: 128px;
  height: 128px;
`;

export const LoadingWidget = styled.div`
  zoom: 0.9;
  display: grid;
  align-content: center;
  justify-items: center;
  padding-top: 128px;
`;

export const Pulse = styled.div`
  @keyframes pulse {
    0%,
    100% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.5;
    }
  }
`;
