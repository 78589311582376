import { isValid } from "date-fns";

export const parseDateToStringDate = (date: Date) => {
  if (!date || !isValid(date)) return "";
  return date.toISOString().slice(0, 10);
};

export function isValidStringDate(date) {
  const dateFromString = new Date(date);
  return (
    !isNaN(dateFromString) &&
    dateFromString instanceof Date &&
    !isNaN(dateFromString.valueOf())
  );
}

export const calculateTimeDifference = (dateString: string) => {
  if (!isValidStringDate(dateString)) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  console.log("dateString", dateString);

  const lastUpdatedDate = new Date(dateString);
  const now = new Date();
  const diffMs = now - lastUpdatedDate;

  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(
    (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
  const diffSeconds = Math.floor((diffMs % (1000 * 60)) / 1000);

  return {
    days: diffDays,
    hours: diffHours,
    minutes: diffMinutes,
    seconds: diffSeconds,
  };
};
