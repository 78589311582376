import React from "react";
import { ChevronDown, ChevronUp } from "src/v3/theme/icons/general";
import {
  AccordionContainer,
  AccordionHeader,
  AccordionTitle,
  ExpandIconContainer,
  AccordionContent,
  AccordionActions,
} from "./styles";
import IconComponent from "src/v3/components/Icons/IconComponent";

export const ItemDetailAccordion = ({
  title,
  actions,
  children,
  expanded,
  footer,
  setExpanded,
}: {
  title: string;
  actions?: JSX.Element[];
  children: React.ReactNode;
  expanded: boolean;
  footer?: React.ReactNode;
  setExpanded: () => void;
}) => {
  return (
    <AccordionContainer>
      <AccordionHeader onClick={setExpanded}>
        <AccordionTitle>{title}</AccordionTitle>

        <AccordionActions>
          {actions?.length > 0 &&
            actions.map((action, index) => (
              <React.Fragment key={index}>{action}</React.Fragment>
            ))}
          <ExpandIconContainer>
            {!!children && (
              <IconComponent Icon={expanded ? ChevronUp : ChevronDown} />
            )}
          </ExpandIconContainer>
        </AccordionActions>
      </AccordionHeader>

      {expanded && children && <AccordionContent>{children}</AccordionContent>}

      {footer && <div>{footer}</div>}
    </AccordionContainer>
  );
};
