import { BlockItem } from "src/v2/domain/entities/block/BlockItem";
import {
  BreadCrumb,
  CloseIconButton,
  DrawerHeader,
  HeaderContentBox,
  HeaderItemTitle,
  HeaderSideInfos,
  InfoGrid,
  IssueTypeIconDiv,
  ProgressBarRoot,
  SideInfoGrid,
  StatusChipAndCloseIconGrid,
  StyledGrid,
  TaskSideInfoGrid,
} from "./styles";
import { ItemSummary } from "src/v3/components/commomStyles/ItemSummary";
import { Link } from "@material-ui/core";
import { BreadCrumbsBuilder } from "src/v3/components/Breadcrumbs/builder";
import { IssueTypeIcon } from "src/v3/components/IssueTypeIcon";
import { ProgressBar } from "src/v3/components/ProgressBar/index";
import { StatusChipBuilder } from "src/v3/components/StatusChip/builder";
import { StatusChipTypes } from "src/v3/components/StatusChip/types";
import { CloseButton } from "src/v3/theme/icons/general";
import { PredictedCompletionSideInfo } from "src/v3/components/commomStyles/ItemRowSideInfos/PredictedCompletion";
import { ProjectedDateSideInfo } from "src/v3/components/commomStyles/ItemRowSideInfos/ProjectedDate";
import { TargetDateSideInfo } from "src/v3/components/commomStyles/ItemRowSideInfos/TeamsTargetDate";
import { SprintSideInfo } from "src/v3/components/commomStyles/ItemRowSideInfos/Sprint";
import { DaysInProgressSideInfo } from "src/v3/components/commomStyles/ItemRowSideInfos/DaysInProgress";
import { IssueType } from "src/v3/components/IssueTypeIcon/types";
import IconComponent from "src/v3/components/Icons/IconComponent";
import { getItemName, getItemURL } from "./utils";

export const ItemDetailsDrawerHeader = ({
  item,
  handleClose,
  breadCrumbPaths,
  childrenStatusAggregation,
}: {
  item: BlockItem;
  handleClose: () => void;
  breadCrumbPaths: any[];
  childrenStatusAggregation: any[];
}) => {
  const itemURL = getItemURL(item);
  const itemName = getItemName(item);

  const statusField = item.isTask()
    ? item.getWorkflowStatusField()
    : item.getProjectStatusField();

  const statusChipType = item.isTask()
    ? StatusChipTypes.WorkflowStatus
    : StatusChipTypes.ProjectStatus;

  return (
    <DrawerHeader>
      <StyledGrid>
        <BreadCrumb>
          <BreadCrumbsBuilder paths={breadCrumbPaths} type={"navigation"} />
        </BreadCrumb>
        <StatusChipAndCloseIconGrid>
          <CloseIconButton onClick={handleClose} size="small">
            <IconComponent Icon={CloseButton} />
          </CloseIconButton>

          <StatusChipBuilder
            type={statusChipType}
            status={statusField}
            size={"medium"}
          />
        </StatusChipAndCloseIconGrid>
      </StyledGrid>

      <HeaderContentBox>
        <IssueTypeIconDiv>
          <IssueTypeIcon
            type={item.type.displayName as IssueType}
            size="large"
          />
        </IssueTypeIconDiv>
        <HeaderItemTitle>
          <Link
            href={itemURL || undefined}
            target={itemURL ? "_blank" : undefined}
            underline={itemURL ? "hover" : "none"}
          >
            {itemName}
          </Link>
          <ItemSummary>{item.summary}</ItemSummary>
        </HeaderItemTitle>

        <HeaderSideInfos>
          <ProgressBarRoot>
            <ProgressBar aggregation={childrenStatusAggregation} />
          </ProgressBarRoot>
          <InfoGrid>
            {!item.isTask() ? (
              <SideInfoGrid>
                <ProjectedDateSideInfo item={item} />
                <TargetDateSideInfo item={item} />
              </SideInfoGrid>
            ) : (
              <TaskSideInfoGrid>
                <PredictedCompletionSideInfo item={item} />
                <SprintSideInfo item={item} />
                <DaysInProgressSideInfo item={item} />
              </TaskSideInfoGrid>
            )}
          </InfoGrid>
        </HeaderSideInfos>
      </HeaderContentBox>
    </DrawerHeader>
  );
};
