import { IssueType } from "./types";
import { IssueTypeIconFactory } from "./factory";

export const IssueTypeIcon = ({
  size = "medium",
  type,
}: {
  size?: "small" | "medium" | "large";
  type: IssueType;
}) => <IssueTypeIconFactory issueType={type} size={size} />;
