import styled from "styled-components";

export const ItemRowRoot = styled.div`
  background-color: ${({ theme }) => theme.palette.background.paper};
  display: flex;
  padding: ${({ theme }) => theme.spacing(3)};
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(1)};
  align-self: stretch;
  border-radius: ${({ theme }) => theme.spacing(2)};
`;

export const Info = styled.div`
  font-family: ${({ theme }) => theme.typography.body2.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body2.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body2.lineHeight} !important;
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight} !important;
  height: ${({ theme }) =>
    theme.typography.body2.height || "normal"} !important;
  text-align: right;
  align-self: stretch;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const SideInfosRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing(0.5)};
  max-width: 30%;
`;

export const ItemRowHeader = styled.div`
  display: flex;
  align-self: stretch;
`;

export const ItemNameRoot = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
  flex: 1 0 0;
`;

export const StatusActionRoot = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`;

export const ItemRowMeddle = styled.div`
  display: flex;
  padding-left: ${({ theme }) => theme.spacing(10)};
  align-items: flex-start;
  align-self: stretch;
`;

export const ItemRowFooter = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 80%;
  align-self: stretch;
  gap: ${({ theme }) => theme.spacing(2)};
  font-family: ${({ theme }) => theme.typography.body1.fontFamily} !important;
  font-size: ${({ theme }) => theme.typography.body1.fontSize} !important;
  line-height: ${({ theme }) => theme.typography.body1.lineHeight} !important;
  font-weight: ${({ theme }) => theme.typography.body1.fontWeight} !important;
  height: ${({ theme }) =>
    theme.typography.body1.height || "normal"} !important;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const ItemSummaryInfosRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(1)};
  flex: 1 0 0;
`;
