import { useLayout } from "src/providers/LayoutContext";
import {
  ActionContainer,
  LastUpdatedText,
  LeftAreaContainer,
  LogoContainer,
  MiddleAreaContainer,
  RightAreaContainer,
  TopBarContainer,
} from "./styles";
import { ViewSelector } from "./ViewSelector";
import { useState, useEffect } from "react";
import { getLastUpdatedString } from "./utils";
import { Switch } from "../Switch";
import { UserMenu } from "../UserMenu";
import { ReactComponent as VelmaIcon } from "src/assets/icons/velma.svg";
import { SearchBar } from "../SearchBar";
import { HierarchyMenu } from "../HierarchyMenu";
import FilterMenuComposition from "../Filter/FilterMenuComposition";

export const TopBar = () => {
  const { topBarHeight } = useLayout();
  const [lastUpdated, setLastUpdated] = useState("");

  const lastUpdatedStringDate = localStorage.getItem("lastCacheUpdatedDate");
  const updateLastUpdatedTime = () => {
    if (lastUpdatedStringDate) {
      const timeDiff = getLastUpdatedString(lastUpdatedStringDate);
      setLastUpdated(timeDiff);
    }
  };

  useEffect(() => {
    updateLastUpdatedTime();
    const interval = setInterval(() => updateLastUpdatedTime(), 60000);
    return () => clearInterval(interval);
  }, [lastUpdatedStringDate]);

  return (
    <TopBarContainer topBarHeight={topBarHeight}>
      {/* Left Area */}
      <LeftAreaContainer>
        <UserMenu />
        {/* <LogoContainer>
          <VelmaIcon
            style={{
              width: "42px",
              height: "42px",
            }}
          />
        </LogoContainer> */}
      </LeftAreaContainer>

      {/* Middle Area */}
      <MiddleAreaContainer>
        <SearchBar />
      </MiddleAreaContainer>

      {/* Right Area */}
      <RightAreaContainer>
        {/* <ActionContainer>
          <p>Show details</p>
          <Switch
            onSwitch={() => {
              console.log("Switched");
            }}
          />
        </ActionContainer>
         */}
        <HierarchyMenu />
        <ViewSelector />
        {lastUpdated && (
          <LastUpdatedText>Last updated: {lastUpdated}</LastUpdatedText>
        )}

        <FilterMenuComposition />
      </RightAreaContainer>
    </TopBarContainer>
  );
};
